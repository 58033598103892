import React from 'react'

import { Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material'

export const ConfirmDialog = (props) => {
  return (
    <Dialog onClose={props.onCancel} open={props.open}>
      <DialogContent>
        <DialogContentText>
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onConfirm} color="secondary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
