import React from 'react'

import { Paper, Typography } from '@mui/material'

import { useDispatch } from 'react-redux'

import { Helmet } from 'react-helmet'

import { CenterContentTemplate } from '@ui/CenterContentTemplate'
import { CompanyRegisterForm } from './CompanyRegisterForm'

export const CompanyRegisterPageView = ({ history }) => {
  const dispatch = useDispatch()

  const params = new URLSearchParams(window.location.search)

  const onAfterSubmit = () => {
    dispatch({
      type: 'SET_SNACKBAR',
      message: 'Company registration was successful; notification letter was sent. Check your email.'
    })
  }

  return (
    <CenterContentTemplate>
      <Helmet title="Company Register" />
      <Paper style={{ maxWidth: 520, padding: '50px 60px', textAlign: 'center' }} elevation={5}>
        <Typography variant="h5">
          Company Registartion
        </Typography>
        <CompanyRegisterForm params={params} history={history} afterSubmit={onAfterSubmit} />
      </Paper>
    </CenterContentTemplate>
  )
}
