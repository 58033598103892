import { axios } from 'Utils/axios'

import * as types from '../types'

export const loadCategoriesList = () => {
  return dispatch => axios({ method: 'GET', url: 'categories/' })
    .then((response) => {
      dispatch({ type: types.CATEGORIES_LIST_FETCHED_ADMIN, data: response.data.categories })
      dispatch({
        type: types.CATEGORIES_LIST_FETCHED_ERROR,
        data: { data: response.data, status: response.status }
      })
    })
}

export const createCategory = (name) => {
  return dispatch => axios({ method: 'PUT', url: 'categories/add_category/', data: { name } })
    .then((response) => dispatch({ type: types.CATEGORY_ADDED_ADMIN, data: response.data.category }))
}

export const deleteCategory = (id) => {
  return dispatch => axios({ method: 'DELETE', url: 'categories/delete_category/', data: { id } })
    .then((response) => dispatch({ type: types.CATEGORY_DELETED_ADMIN, data: response.data.category }))
}

export const editCategory = (category) => {
  return dispatch => axios({ method: 'PUT', url: 'categories/edit_category/', data: category })
    .then((response) => {
      dispatch({ type: types.CATEGORY_CHANGED_ADMIN, data: response.data.category })
      dispatch({
        type: types.CATEGORY_CHANGED_ERROR,
        data: { data: response.data, status: response.status }
      })
    })
}
