import React from 'react'

import { Grid, Button } from '@mui/material'

import { withFormik } from 'formik'

import { Input } from '@ui/Input'
import { usersApi } from '../../api'

const validate = (values) => {
  const errors = {}
  if (!values.first_name)
    errors.first_name = 'This field is required'

  if (!values.last_name)
    errors.last_name = 'This field is required'

  if (!values.email)
    errors.email = 'This field is required'
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = 'invalid email address'

  return errors
}

const formik = {
  mapPropsToValues: () => ({
    first_name: '',
    last_name: '',
    email: '',
    message: ''
  }),
  validate,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const usersListResult = await usersApi.invite(values)

    if (usersListResult.status === 200) {
      props.afterSubmit()
      setSubmitting(false)
    }
  }
}

export const InviteForm = withFormik(formik)(({
  handleSubmit, isSubmitting, handleChange, handleBlur, values, errors
}) => (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" spacing={8}>
        <Grid item xs={6}>
          <Input
            name="first_name"
            label="First name"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            error={errors.first_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="last_name"
            label="Last name"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            error={errors.last_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="email"
            type="email"
            label="Email"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            multiline
            name="message"
            label="Message"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            error={errors.message}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{ minWidth: 150 }}
            type="submit"
            color="primary"
          >
            Invite
        </Button>
        </Grid>
      </Grid>
    </form>
  ))