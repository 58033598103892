import React from 'react'

import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react'

const formInitialState = { name: '', description: '' }

export const EditProjectDialog = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState(formInitialState)

  const handleSubmit = () => {
    setLoading(true)
    formData.id = props.initialValues.id
    props.onSubmit(formData)
    setLoading(false)
    setFormData(formInitialState)
  }

  const onInputChange = (event, data) => {
    const { value, name } = data
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <Modal size="mini" open={props.open} onClose={props.onClose}>
      <Modal.Header>{'Edit project'}</Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>{'Loading'}</Loader>
          </Dimmer>
        )}
        <Form>
          <Form.Input
            fluid
            name="name"
            value={props.initialValues && props.initialValues.name}
            placeholder={'Name'}
            onChange={onInputChange}
          />
          <Form.Input
            fluid
            name="description"
            value={props.initialValues && props.initialValues.description}
            placeholder={'Description'}
            onChange={onInputChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>{'Cancel'}</Button>
        <Button onClick={handleSubmit} positive>{'Submit'}</Button>
      </Modal.Actions>
    </Modal>
  )
}
