import * as types from '../types'

const initialState = {
  list: [],
  detchError: null,
  editError: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CATEGORIES_LIST_FETCHED_ADMIN:
      return { ...state, list: action.data }
    case types.CATEGORIES_LIST_FETCHED_ERROR:
      return { ...state, fetchError: action.data }
    case types.CATEGORY_ADDED_ADMIN:
      return { ...state, list: [...state.list, action.data] }
    case types.CATEGORY_DELETED_ADMIN:
      return { ...state, list: state.list.filter(item => item.id !== action.data.id) }
    case types.CATEGORY_CHANGED_ADMIN:
      return {
        ...state,
        list: state.list.map(item => (item.id === action.data.id ? action.data : item))
      }
    case types.CATEGORY_CHANGED_ERROR:
      return { ...state, editError: action.data }
    default:
      return state
  }
}
