import React from 'react'

import { FormControl, TextField, FormHelperText } from '@mui/material'

import { PasswordField } from 'Components/front/PasswordField'

export const CustomField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const errorCondition = Boolean((touched[field.name] || submitCount) && errors[field.name])

  if (field.name === 'password')
    return (
      <FormControl fullWidth>
        <PasswordField
          inputProps={{
            label: props.label,
            fullWidth: true,
            ...props,
            ...field,
            value: field.value || '',
            error: errorCondition,
            onChange: (value) => field.onChange({ target: { value, name: field.name } })
          }}
        />
        { errorCondition ? <FormHelperText error>{errors[field.name]}</FormHelperText> : null}
      </FormControl>
    )

  return (
    <FormControl fullWidth>
      <TextField
        label={props.label}
        fullWidth
        {...props}
        {...field}
        value={field.value || ''}
        error={errorCondition}
      />
      { errorCondition ? <FormHelperText error>{errors[field.name]}</FormHelperText> : null}
    </FormControl>
  )
}