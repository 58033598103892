import React from 'react'

import CryptoJS from 'crypto-js'
import RSA from 'seededrsa'
import { withFormik } from 'formik'

import { Typography, Grid, InputAdornment, IconButton, Button } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'

import { useSelector } from 'react-redux'

import { Input } from '@ui/Input'
import { createPassword } from './effects'
import { validate } from './components/validations'

const formik = {
  mapPropsToValues: () => ({
    password: '',
    confirm_password: ''
  }),
  validate,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const data = Object.assign({}, values)
    const hash = CryptoJS.SHA256(data.password).toString(CryptoJS.enc.Hex)
    const rsa = new RSA(hash)
    await rsa.generateNew(1024, '03')
    data.public_key = rsa.n.toString()
    const userId = props.user && props.user.id
    await createPassword(data, userId, { props, setSubmitting, setErrors })
  }
}

export const AssignPasswordForm = withFormik(formik)(({
  handleBlur, handleChange, handleSubmit, isSubmitting, errors, touched, values
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const user = useSelector((state) => state.app.user)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={8} justifyContent="center">
        <Grid item xs={12}>
          <Input
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="confirm_password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm password"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirm_password}
            error={touched.confirm_password && errors.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item style={{ marginTop: '16px' }}>
          <Button disabled={isSubmitting} variant="contained" style={{ minWidth: 200 }} type="submit" color="primary">
            ASSIGN NEW PASSWORD
            </Button>
          {errors.non_field_errors && (<Typography color="secondary">{errors.non_field_errors}</Typography>)}
        </Grid>
      </Grid>
    </form>
  )
}
)
