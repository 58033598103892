import { setLoading } from './app'
import { axios } from './axiosConfig'

import * as types from '../types'

export const getCompanySettings = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'company/' })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.SET_COMPANY_SETTINGS, data: response.data })
      })
  }
}

export const getLDAPSettings = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'directory/' })
      .then((response) => {
        dispatch(setLoading(false))
        const ldapSettings = response.data && response.data.length ? response.data[0] : {}
        dispatch({ type: types.SET_LDAP_SETTINGS, data: ldapSettings })
      })
  }
}
