import React from 'react'

import { CircularProgress, CssBaseline } from '@mui/material'

import { useSelector } from 'react-redux'

import { UiSnackbar } from 'Components/front/Snackbar'
import { Routes } from './routes'

const loaderOptions = {
  width: '100%',
  height: '100%',
  padding: 'auto',
  position: 'fixed',
  top: '0',
  right: '0',
  background: 'transparent',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const MainWrapper = ({ children, loading }) => {
  return (
    <div>
      <div>
        <CssBaseline />
        {children}
      </div>
      <UiSnackbar />
      {loading && (
        <div style={loaderOptions}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export const App = () => {
  const { user, appReady, loading, isDomainPublic, passPhrase } = useSelector((state) => state.app)

  return (
    <MainWrapper loading={loading}>
      <Routes user={user} appReady={appReady} isDomainPublic={isDomainPublic} hash={passPhrase} />
    </MainWrapper>
  )
}
