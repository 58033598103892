import React from 'react'

import { Grid, Button, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'
import { changeLocalSettings, getUserById, setSnackbar } from 'Actions/app'

import { Helmet } from 'react-helmet'

import { ContentWrap } from 'Layouts/front/ContentWrap'
import { InviteForm } from './components/InviteForm'
import { UsersTable } from './components/UsersTable'
import { usersApi } from '../api'

const useStyles = makeStyles({ container: { width: '100%', maxWidth: 'none' } })

export const UsersListPage = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [users, setUsers] = React.useState([])
  const [openInvite, setOpenInvite] = React.useState(false)

  const appSettings = useSelector((state) => state.app.settings)

  const openInviteDialog = () => setOpenInvite(true)
  const closeInviteDialog = () => setOpenInvite(false)

  const fetchUsers = async () => {
    const usersListResult = await usersApi.list()

    if (usersListResult.status === 200) {
      const usersListData = await usersListResult.json()
      setUsers(usersListData.users)
    }
  }

  React.useEffect(() => { fetchUsers() }, [])

  const userClickHandler = (rowData) => {
    dispatch(getUserById(rowData.id))
    setTimeout(() => history.push(`/users/${rowData.id}`), 1000)
  }

  const onChangeRowsPerPage = (count) => dispatch(changeLocalSettings({ usersPerPage: count }))

  const onAfterSubmit = () => {
    closeInviteDialog()
    dispatch(setSnackbar('Invitation sent!'))
  }

  return (
    <ContentWrap className={classes.container}>
      <Helmet title="Users list" />
      <Dialog maxWidth="xs" open={openInvite} onClose={closeInviteDialog}>
        <DialogTitle>Invite user</DialogTitle>
        <DialogContent>
          <InviteForm afterSubmit={onAfterSubmit} />
        </DialogContent>
      </Dialog>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Button variant="contained" size="small" onClick={openInviteDialog}>
            Invite new user
          </Button>
        </Grid>
        <Grid item xs={12}>
          <UsersTable
            onRowClick={userClickHandler}
            users={users}
            rowsPerPage={appSettings.usersPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </ContentWrap>
  )
}
