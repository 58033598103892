import React from 'react'

import { Provider } from 'react-redux'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { render } from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'

import { App } from './App'
import store, { history } from './store'

import SentryWrapper from './sentry'

const theme = createTheme({ typography: { useNextVariants: true } })

render(
  <ThemeProvider theme={theme}>
    <SentryWrapper>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </SentryWrapper>
  </ThemeProvider>,
  document.getElementById('root')
)
