import React from 'react'

import { FormControl, MenuItem, InputLabel, Select, FormHelperText } from '@mui/material'

export const SelectField = ({ field, form: { touched, errors, submitCount }, ...props }) => {

  const errorCondition = Boolean((touched[field.name] || submitCount) && errors[field.name])

  return (
    <FormControl fullWidth>
      <InputLabel shrink={Boolean(field.value)} htmlFor={`${props.label}-select`}>{props.label}</InputLabel>
      <Select
        MenuProps={{ style: { zIndex: 9999 } }}
        fullWidth
        {...props}
        {...field}
        value={field.value || ''}
        error={errorCondition}
        inputProps={{
          name: field.name,
          id: `${props.label}-select`
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {props.items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      { errorCondition ? <FormHelperText error>{errors[field.name]}</FormHelperText> : null}
    </FormControl>
  )
}