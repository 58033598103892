import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@mui/styles'
import {
  SpeedDial, SpeedDialAction, SpeedDialIcon, Grid, DialogTitle, Dialog, DialogContent, DialogActions, Button,
} from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { Helmet } from 'react-helmet'

import { loadProjectList, loadProject } from 'Actions/projects'
import { uploadFile, getLink } from 'Actions/attachments'
import { loadUserProjects } from 'Actions/users'
import { CreateFileDialog } from 'Components/front/CreateFileDialog'
import { loadPasswordsList, addPassword, loadCategoriesList, addFile } from 'Actions/passwords'
import { setLoading } from 'Actions/app'
import { CreatePasswordDialog } from 'Components/front/CreatePasswordDialog'
import { PasswordsList } from 'Components/front/PasswordsList'
import { PreviewDialog } from 'Components/front/PreviewDialog'
import CryptoHelper from 'Utils/CryptoHelper'
import { ContentWrap } from 'Layouts/front/ContentWrap'

const useStyles = makeStyles({
  container: { width: '100%', maxWidth: 'none' },
  fab: { position: 'absolute', bottom: 16, right: 16 },
  dialog: { zIndex: 9999 }
})

let cryptoHelper = null

export const Home = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const categories = useSelector((state) => state.passwords.categoriesList)
  const passwordsList = useSelector((state) => state.passwords.passwordsList)
  const { projectList, currentProject, currentUserProjects } = useSelector((state) => state.projects)
  const hash = useSelector((state) => state.app.passPhrase)
  const { user } = useSelector((state) => state.app)

  const [rawPasswords, setRawPasswords] = React.useState([])
  const [showSpeedDial, setShowSpeedDial] = React.useState(false)
  const [showCreateDialog, setShowCreateDialog] = React.useState(false)
  const [showPreviewDialog, setShowPreviewDialog] = React.useState(false)
  const [previewData, setPreviewData] = React.useState(null)
  const [dialogType, setDialogType] = React.useState(1)
  const [passwordData, setPasswordData] = React.useState(null)
  const [fileData, setFileData] = React.useState(null)

  const loadAllPasswords = async () => {
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', hash)
    const passwords = passwordsList.map(password => { return { ...password } })
    setRawPasswords([].concat(...passwords))
  }

  React.useEffect(() => {
    dispatch(loadUserProjects(user.id))
  }, [])

  React.useEffect(() => {
    if (projectList.length === 0 && currentUserProjects.length !== 0)
      dispatch(loadProjectList())
  }, [projectList, currentUserProjects])

  React.useEffect(() => {
    dispatch(loadCategoriesList())
    if (projectList.length !== 0)
      if (passwordsList.length === 0) {
        projectList.map((project) => dispatch(loadPasswordsList(project.id)))
      }
      else {
        const passwordsIds = passwordsList.map((password) => password.project)
        projectList.map((project) => !passwordsIds.includes(project.id) && dispatch(loadPasswordsList(project.id)))
      }
  }, [projectList])

  React.useEffect(() => {
    if (passwordsList.length !== 0)
      loadAllPasswords()
  }, [passwordsList])

  const encryptFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const encrypted = cryptoHelper.encryptFile(e.target.result)
        const blob = new Blob(
          [encrypted],
          { type: 'application/octet-stream' }
        )
        resolve(blob)
      }
      reader.readAsDataURL(file)
    })
  }

  const onCreatePassword = async (data) => {
    dispatch(setLoading(true))
    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      data.tags = tags.join(',')
    }
    setPasswordData(data)
    dispatch(loadProject(data.project))
  }
  const onCreateFile = async (data) => {
    dispatch(setLoading(true))
    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      data.tags = tags.join(',')
    }
    setFileData(data)
    dispatch(loadProject(data.project))
  }

  React.useEffect(async () => {
    if (currentProject.encrypted_secret_key !== undefined && (passwordData !== null || fileData !== null)) {
      const projectSecretKey = currentProject.encrypted_secret_key.secret_key
      await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', hash)
        .then(() => props.cryptoWorker.dispatch('DECRYPT_KEY', projectSecretKey))
        .then((decryptedKey) => {
          cryptoHelper = new CryptoHelper(decryptedKey)
        })

      if (dialogType === 1) {
        passwordData.encrypted_password = cryptoHelper.encryptPassword(passwordData.password)
        passwordData.attachments = []
      }
      else { fileData.attachments = [] }

      let queue = Promise.resolve()

      if (dialogType === 1 ?
        (passwordData.attachments && passwordData.attachments.length) :
        (fileData.attachments && fileData.attachments.length)
      )
        for (let i = 0;
          i < (dialogType === 1 ? passwordData.attachments.length : fileData.attachments.length);
          i += 1) {
          const file = dialogType === 1 ? passwordData.attachments[i] : fileData.attachments[i]
          queue = queue
            .then(() => encryptFile(file))
            .then(blob => dispatch(uploadFile(blob, file.name)))
            .then((uploadedFile) => dialogType === 1 ?
              passwordData.attachments.push(uploadedFile.file.id) :
              fileData.attachments.push(uploadedFile.file.id)
            )
        }

      dialogType === 1 ? dispatch(addPassword(passwordData)) : dispatch(addFile(fileData))
      dispatch(setLoading(false))
      setShowCreateDialog(false)
    }
  }, [currentProject])

  const loadAndDecryptFile = async (id, password) => {
    const encryptedKey = password.project_detail.encrypted_secret_key.secret_key
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', hash)
    const decryptedKey = await props.cryptoWorker.dispatch('DECRYPT_KEY', encryptedKey)
    cryptoHelper = new CryptoHelper(decryptedKey)
    const urlData = await getLink(id)

    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', urlData.url)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        const blob = xhr.response
        const reader = new FileReader()
        reader.onload = (e) => {
          const decrypted = cryptoHelper.decryptFile(e.target.result)
          resolve(decrypted)
        }
        reader.readAsText(blob)
      }
      xhr.send()
    })
  }

  const downloadFile = (url, name) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const fileOpen = async (id, name, password) => {
    const data = await loadAndDecryptFile(id, password)
    downloadFile(data, name)
  }

  const clickSpeedDial = () => setShowSpeedDial(!showSpeedDial)
  const closeSpeedDial = () => setShowSpeedDial(false)
  const closePreviewDialog = () => setShowPreviewDialog(false)

  const openCreatePasswordDialog = () => {
    setShowCreateDialog(true)
    setDialogType(1)
  }

  const openCreateFileDialog = () => {
    setShowCreateDialog(true)
    setDialogType(2)
  }

  const closeCreateDialog = () => {
    setShowCreateDialog(false)
    setDialogType(dialogType)
  }

  const performAction = async (key, password) => {
    switch (key) {
      case 'open':
        props.history.push(`/projects/${password.project}`)
        break
      default:
        break
    }
  }

  const openPreviewDialog = (data) => {
    setShowPreviewDialog(true)
    setPreviewData(data)
  }

  const previewOpen = async (id, name, password) => {
    const data = await loadAndDecryptFile(id, password)
    openPreviewDialog(data)
  }


  const reversePasswordsList = (projects) => {
    return projects.reverse()
  }

  const rawPasswordsList = React.useMemo(() => reversePasswordsList(rawPasswords), [rawPasswords])

  return (
    <ContentWrap className={classes.container}>
      <Helmet title="Home" />
      <PreviewDialog
        open={showPreviewDialog}
        data={previewData}
        onClose={closePreviewDialog}
      />
      <Dialog onClose={closeCreateDialog} className={classes.dialog} maxWidth="xs" open={showCreateDialog}>
        <DialogTitle>
          Create
          {' '}
          {dialogType === 1 ? 'password' : 'file'}
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          {dialogType === 1 ? (
            <CreatePasswordDialog
              formSubmit={onCreatePassword}
              withProjectSelect={true}
              projects={projectList}
              categories={categories}
            />
          ) : (
            <CreateFileDialog
              formSubmit={onCreateFile}
              withProjectSelect={true}
              projects={projectList}
              categories={categories}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateDialog} variant="contained" size="small">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <PasswordsList
            cryptoWorker={props.cryptoWorker}
            rawPasswords={rawPasswordsList}
            performAction={performAction}
            getLink={fileOpen}
            preview={previewOpen}
            hash={hash}
            actions={[{ label: 'Open in project', key: 'open' }]}
          />
        </Grid>
      </Grid>
      <SpeedDial
        ariaLabel="Create object"
        className={classes.fab}
        icon={<SpeedDialIcon />}
        onClick={clickSpeedDial}
        onClose={closeSpeedDial}
        open={showSpeedDial}
      >
        <SpeedDialAction icon={<VpnKeyIcon />} tooltipTitle="password" onClick={openCreatePasswordDialog} />
        <SpeedDialAction icon={<InsertDriveFileIcon />} tooltipTitle="file" onClick={openCreateFileDialog} />
      </SpeedDial>
    </ContentWrap>
  )
}
