import React from 'react'

import { Paper, Typography } from '@mui/material'

import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { CenterContentTemplate } from '@ui/CenterContentTemplate'
import { LoginForm } from '../forms/LoginForm'

export const LoginPageView = ({ history }) => {
  const dispatch = useDispatch()

  const onAfterSubmit = (loginData) => {
    dispatch({ type: 'GET_ACCOUNT_SUCCESS', data: loginData })

    if (loginData.user && !loginData.user.passPhrase)
      history.push('/assign-password')
    else
      history.push('/home')
  }

  return (
    <CenterContentTemplate>
      <Helmet title="LogIn" />
      <Paper style={{ maxWidth: 480, padding: '50px 60px', textAlign: 'center' }} elevation={5}>
        <Typography variant="h5" style={{ marginBottom: '30px' }}>
          Login to your account
        </Typography>
        <LoginForm history={history} afterSubmit={onAfterSubmit} />
        <Typography style={{ marginTop: '1rem', textAlign: 'center' }}>
          <Link to="/register">Create a new account</Link>
        </Typography>
      </Paper>
    </CenterContentTemplate>
  )
}
