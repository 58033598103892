import { FRequest } from '@lib/frequest'

// eslint-disable-next-line max-len
const baseUrl = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT.toString().replace(/"/g, '')}${process.env.REACT_APP_API_PREFIX.toString().replace(/"/g, '')}`

const request = new FRequest({
  baseUrl,
  reqParams: { credentials: 'include' }
})

export const usersApi = {
  list: () => request.get('users/simplelist/'),
  invite: inviteData => request.put('users/invite_user/', inviteData),
  update: userData => request.put(`users/${userData.id}/update_user/`, userData),
  deleteSelf: () => request.delete('users/delete_self/')
}
