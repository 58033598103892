/* eslint-disable array-element-newline */
import React from 'react'

import { Route } from 'react-router-dom'

import { LoginPageView } from './pages/LoginPageView'
import { RegisterPageView } from './pages/RegisterPageView'

export const authRoutes = () => [
  <Route key="login" path="/login" component={LoginPageView} />,
  <Route key="register" path="/register" component={RegisterPageView} />
]
