export const getAllCategories = (categories) => {
  return categories.map(category => ({
    value: category.id,
    label: category.name
  }))
}

export const getAllProjects = (projects) => {
  return projects.map(project => ({
    value: project.id,
    label: project.name
  }))
}