import * as types from '../types'

export const showMessage = (message) => {
  return { type: types.SHOW_MESSAGE_ADMIN, data: message }
}

export const hideMessage = () => {
  return { type: types.HIDE_MESSAGE_ADMIN }
}

export const resetUser = () => {
  return (dispatch) => {
    dispatch({ type: types.USER_RESETED_ADMIN })
  }
}
