import React from 'react'

import { Typography, Grid, InputAdornment, IconButton, Button } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'

import CryptoJS from 'crypto-js'
import RSA from 'seededrsa'
import { withFormik } from 'formik'

import { Input } from '@ui/Input'
import { userRegister } from '../effects'
import { validate } from './components/validatations'

const formik = {
  mapPropsToValues: ({ params }) => ({
    first_name: params.get('first_name') || '',
    last_name: params.get('last_name') || '',
    email: params.get('email') || '',
    password: '',
    confirm_password: ''
  }),
  validate,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const data = Object.assign({}, values)
    const hash = CryptoJS.SHA256(data.password).toString(CryptoJS.enc.Hex)
    const rsa = new RSA(hash)
    await rsa.generateNew(1024, '03')
    data.public_key = rsa.n.toString()
    await userRegister(data, { props, setSubmitting, setErrors })
  }
}

export const RegisterForm = withFormik(formik)(({
  handleBlur, handleChange, handleSubmit, isSubmitting, errors, touched, values
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={6}>
          <Input
            name="first_name"
            type="text"
            label="First name"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && errors.first_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name="last_name"
            type="text"
            label="Last name"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            error={touched.last_name && errors.last_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="email"
            type="email"
            label="E-mail"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="confirm_password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm password"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirm_password}
            error={touched.confirm_password && errors.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button disabled={isSubmitting} variant="contained" style={{ minWidth: 200 }} type="submit" color="primary">
            SIGN UP
            </Button>
          {errors.non_field_errors && (<Typography color="secondary">{errors.non_field_errors}</Typography>)}
        </Grid>
      </Grid>
    </form>
  )
})
