import React from 'react'

import { Sidebar, Menu, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const menuItems = [
  { label: 'Home', icon: 'home', link: '/admin-home' },
  { label: 'Projects', icon: 'briefcase', link: '/admin-projects' },
  { label: 'Users', icon: 'user', link: '/admin-users' },
  { label: 'Groups', icon: 'group', link: '/admin-groups' },
  { label: 'Other', icon: 'th', link: '/admin-other' },
  { label: 'Settings', icon: 'settings', link: '/admin-settings' },
  { label: 'Go back', icon: 'log out', link: '/home' }
]

export const SideBar = () => {
  return (
    <Sidebar as={Menu} animation="push" width="thin" inverted vertical visible>
      {menuItems.map(item => (
        <Menu.Item as={Link} to={item.link || ''} key={item.label} onClick={item.onClick && item.onClick}>
          { item.icon && <Icon name={item.icon} />}
          { item.label || ''}
        </Menu.Item>
      ))}
    </Sidebar>
  )
}
