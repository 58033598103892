import React from 'react'

import {
  FormHelperText, Grid, Button, List, ListItem, ListItemText,
} from '@mui/material'

import { Formik, Field, Form, FieldArray } from 'formik'

import { SelectField } from '../components/SelectField'
import { CustomField } from '../components/CustomField'
import { validation } from './components/validation'

import { onFileSelect } from 'Utils/onFileSelect'
import { getAllCategories, getAllProjects } from 'Utils/get'

const fields = [
  { label: 'Name', name: 'name' },
  { label: 'Description', name: 'description' },
  { label: 'Login', name: 'login' },
  { label: 'Password', name: 'password' },
  { label: 'Url', name: 'url' },
  { label: 'Coma separated tags', name: 'tags' }
]

export const CreatePasswordDialog = (props) => {
  return (
    <Formik initialValues={{}} onSubmit={props.formSubmit}>
      {({ values, setFieldValue, setFieldError, errors }) => (
        <Form>
          <Grid container spacing={2}>
            {props.withProjectSelect && (
              <Grid item xs={6}>
                <Field
                  name="project"
                  label="Project"
                  items={getAllProjects(props.projects)}
                  onChange={props.selectProject}
                  validate={validation.project}
                  component={SelectField}
                />
              </Grid>
            )}
            <Grid item xs={props.withProjectSelect ? 6 : 12}>
              <Field
                name="category"
                label="Category"
                items={getAllCategories(props.categories)}
                onChange={props.selectCategory}
                validate={validation.category}
                component={SelectField}
              />
            </Grid>
            {fields.map(field => (
              <Grid item xs={12} key={field.name}>
                <Field
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  validate={validation[field.name]}
                  component={CustomField}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <FieldArray name="attachments" render={() => (
                <React.Fragment>
                  <input
                    style={{ display: "none" }}
                    onChange={e => onFileSelect(e, setFieldValue, setFieldError, values)}
                    id="file_select"
                    multiple
                    type="file"
                  />
                  <label htmlFor="file_select">
                    <Button variant="contained" fullWidth size="small" component="div">
                      Attachments
                    </Button>
                  </label>
                  {errors.attachments
                    ? <FormHelperText error>{errors.attachments}</FormHelperText>
                    : null}
                  <List>
                    {values && values.attachments && values.attachments.map(file => (
                      <ListItem disableGutters key={file.name}>
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={file.name} />
                      </ListItem>
                    ))}
                  </List>
                </React.Fragment>
              )} />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" size="small" fullWidth >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}
