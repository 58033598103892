/* eslint-disable no-nested-ternary */
import React from 'react'

import {
  Chip, AccordionSummary, Typography, AccordionDetails, Accordion, Button, AccordionActions, CircularProgress,
} from '@mui/material'
import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { ChangePermissionsForm } from 'Components/front/ChangePermissionsForm'

const useStyles = makeStyles({
  progress: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    flex: '33.33%',
    width: 1
  },
  listItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  }
})

export const InfoList = (props) => {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState(false)
  const [expandedItem, setExpandedItem] = React.useState(-1)

  const permissionChange = (item, disabled) => {
    return (
      <ChangePermissionsForm
        group={props.group}
        changePermissions={props.changePermissions}
        item={item}
        disabled={disabled}
      />
    )
  }

  const handleChange = (id) => setExpandedItem(expandedItem === id ? -1 : id)

  const labelSwitcher = (permission) => {
    const { binary_value: binaryValue } = permission
    let label = ''
    switch (binaryValue) {
      case 1:
        label = 'read'
        break
      case 2:
        label = 'write'
        break
      case 3:
        label = 'admin'
        break
      default:
    }
    return <Chip key={label} label={label} color="primary" variant="outlined" />
  }

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          TransitionProps={{ unmountOnExit: true }}
          expandIcon={!props.addButtonClick ? (<div />) : (!props.addUserToGroupPending ?
            <AddIcon onClick={!props.addUserToGroupPending && props.addButtonClick} />
            : <CircularProgress color="primary" className={classes.progress} />
          )}
        >
          <Typography variant="h5" color="primary" className={classes.column}>
            {props.headerTitle}
          </Typography>
        </AccordionSummary>
      </Accordion>
      {props.items && props.items.map(item => (
        <Accordion
          expanded={expandedItem === item.id}
          onChange={() => handleChange(item.id)}
          key={item.id}
        >
          <AccordionSummary
            TransitionProps={{ unmountOnExit: true }}
            classes={{ 'content': classes.listItem }}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.column}>
              <Typography noWrap>{item[props.nameKey]}</Typography>
            </div>
            <div className={classes.column}>
              <Typography noWrap>{item[props.descriptionKey]}</Typography>
            </div>
            <div className={classes.column}>
              {item[props.chipKey]
                ? item[props.chipKey].map((permission) => labelSwitcher(permission)) : ''}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {props.permissions
              ? (permissionChange(item, props.disableDeleteKey && item[props.disableDeleteKey]))
              : <div />}
          </AccordionDetails>
          {
            props.permissions ? (
              <AccordionActions>
                <Button
                  onClick={() => props.deleteItem(item)}
                  disabled={props.disableDeleteKey && item[props.disableDeleteKey]}
                  color="secondary"
                >
                  Delete
                </Button>
              </AccordionActions>
            ) : <div />
          }
        </Accordion>
      ))
      }
    </div >
  )
}
