import React from 'react'

import { FormControl, TextField } from '@mui/material'

export const Input = (props) => (
  <FormControl fullWidth={props.fullWidth}>
    <TextField
      label={props.label}
      error={Boolean(props.error)}
      name={props.name}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      onChange={props.onChange}
      InputProps={props.InputProps}
      InputLabelProps={props.InputLabelProps}
      type={props.type}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      multiline={props.multiline}
      variant={props.variant}
      value={props.value}
    />
  </FormControl>
)