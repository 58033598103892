import { EventEmitter } from 'eventemitter3'

export default class WebWorker extends EventEmitter {
  constructor(worker) {
    super()
    this.worker = worker()

    this.worker.addEventListener('message', (e) => {
      if (!e.data && !e.data.action) return
      this.emit(e.data.action, e.data.payload)
    })
  }

  postMessage(...args) {
    return this.worker.postMessage(...args)
  }

  static makeId(length) {
    let text = ''
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i += 1)
      text += letters.charAt(Math.floor(Math.random() * letters.length))

    return text
  }

  dispatch(eventName, data) {
    return new Promise((resolve, _) => {
      const uid = WebWorker.makeId(10)
      const self = this

      this.worker.addEventListener('message', function _listener(e) {
        if (!e.data || e.data.uid !== uid) return
        self.worker.removeEventListener('message', _listener, true)
        resolve(e.data.payload)
      }, true)

      this.worker.postMessage({
        type: eventName,
        payload: data,
        uid
      })
    })
  }
}
