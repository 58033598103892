/* eslint-disable no-underscore-dangle */
import * as types from '../types'
import deleteItem from './commonFunctions'

const initialState = {
  groupList: [],
  currentGroup: {
    name: 'Test',
    description: 'Test',
    owner: { email: 'test', last_name: 'test', first_name: 'test' }
  },
  currentGroupUsers: [],
  availableUsersForGroup: [],
  userPermissions: [{ name: 'perm' }]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GROUP_FETCHED:
      return { ...state, currentGroup: action.groups.group, userPermissions: action.permissions }
    case types.GROUP_ADDED:
      return { ...state, currentGroup: action.data, groupList: [...state.groupList, action.data] }
    case types.GROUPS_FETCHED:
      return { ...state, groupList: action.data }
    case types.GROUP_UPDATED: {
      const updatedClientsList = state.groupList.slice()
      for (let i = 0; i < updatedClientsList.length; i += 1)
        if (updatedClientsList[i]._id === action.data._id) {
          updatedClientsList[i] = action.data
          break
        }
      return { ...state, currentGroup: action.data, groupList: updatedClientsList }
    }
    case types.GROUP_DELETED: {
      const newClientsList = state.groupList.slice()
      for (let i = 0; i < newClientsList.length; i += 1)
        if (newClientsList[i]._id === action.data._id) {
          newClientsList.splice(i, 1)
          break
        }
      return { ...state, groupList: newClientsList, currentGroup: state.currentGroup }
    }
    case types.GROUP_USERS_FETCHED:
      return { ...state, currentGroupUsers: action.data }
    case types.USER_ADDED_TO_GROUP:
      return {
        ...state,
        currentGroupUsers: [...state.currentGroupUsers, action.data],
        availableUsersForGroup: deleteItem(action.data, state.availableUsersForGroup)
      }
    case types.GROUP_USER_DELETED: {
      const newClientsList = state.currentGroupUsers.slice()
      for (let i = 0; i < newClientsList.length; i += 1)
        if (newClientsList[i].id === action.data.id) {
          newClientsList.splice(i, 1)
          break
        }
      return { ...state, currentGroupUsers: newClientsList }
    }
    case types.PROJECTS_OF_GROUP_FETCHED:
      return { ...state, currentGroupProjects: action.data }
    case types.AVAILABLE_USERS_FETCHED_FOR_GROUP:
      return { ...state, availableUsersForGroup: action.data }
    default:
      return state
  }
}
