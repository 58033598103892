import React from 'react'

import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react'

const formInitialState = { name: '', description: '' }

export const EditGroupDialog = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState(formInitialState)

  const handleSubmit = () => {
    setLoading(true)
    formData.id = props.initialValues.id
    props.onSubmit(formData)
    setLoading(false)
    setFormData(formInitialState)
  }

  React.useEffect(() => {
    if (props.initialValues)
      setFormData(props.initialValues)
  }, [props.initialValues])

  const onInputChange = (event, data) => {
    setFormData((prevState) => ({ ...prevState, [data.name]: data.value }))
  }

  return (
    <Modal size="mini" open={props.open} onClose={props.onClose}>
      <Modal.Header>{'Edit group'}</Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>{'loading'}</Loader>
          </Dimmer>
        )}
        <Form>
          <Form.Input
            fluid
            name="name"
            value={formData.name}
            placeholder='Name'
            onChange={onInputChange}
          />
          <Form.Input
            fluid
            name="description"
            value={formData.description}
            placeholder='Description'
            onChange={onInputChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>{'Cancel'}</Button>
        <Button onClick={handleSubmit} positive>{'Submit'}</Button>
      </Modal.Actions>
    </Modal>
  )
}
