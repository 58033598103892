import React from 'react'

import { Grid, CircularProgress } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import { changeMasterPassword } from 'Actions/passwords'
import { getUserById } from 'Actions/app'
import { loadGroupList } from 'Actions/groups'

import { Helmet } from 'react-helmet'

import { UserInfoView } from './components/UserInfoView'
import { UserSettingsView } from './components/UserSettingsView'
import { ContentWrap } from 'Layouts/front/ContentWrap'
import { TabBar } from './components/TabBar'

export const UserDetailPage = ({ match, history, cryptoWorker }) => {
  const dispatch = useDispatch()

  const { user, userById, passPhrase, userProjectsById } = useSelector((state) => state.app)
  const passwords = useSelector((state) => state.passwords.passwordsList)
  const groupList = useSelector((state) => state.groups.groupList)

  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    dispatch(getUserById(history.location.pathname.match(/(\d+)/)[0]))
    dispatch(loadGroupList())
  }, [])

  const filterUserGroups = (groups) => {
    const filtredGroups = []
    groups.map((group) => group.users.map((userInGroup) => {
      if (userInGroup.id === Number(userById.id))
        filtredGroups.push(group)
    }))
    return filtredGroups
  }

  const isProfile = +match.params.id === user.id

  return (
    <ContentWrap style={{ padding: '0px 8px' }}>
      <Helmet title='user profile' />
      {loading && (
        <Grid item xs={12} style={{
          textAlign: 'center',
          height: '100%',
          width: '100%',
          zIndex: '999',
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        }}
        >
          <CircularProgress />
        </Grid>
      )}
      {userById !== null ? (
        <TabBar hideBar={!isProfile} tabs={['Information', 'Settings']}>
          <UserInfoView
            user={user}
            userById={userById}
            history={history}
            groups={filterUserGroups(groupList)}
            cryptoWorker={cryptoWorker}
            hash={passPhrase}
            setLoading={setLoading}
            projects={userProjectsById}
          />
          <UserSettingsView
            user={userById}
            history={history}
            dispatch={dispatch}
            cryptoWorker={cryptoWorker}
            passwords={passwords}
            hash={passPhrase}
            changeMasterPassword={changeMasterPassword}
          />
        </TabBar>
      ) : (
        <Grid item xs={12} style={{
          textAlign: 'center',
          height: '100%',
          width: '100%',
          zIndex: '999',
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        }}
        >
          <CircularProgress />
        </Grid>
      )}
    </ContentWrap>
  )
}
