import React from 'react'

import {
  FormHelperText, List, ListItem, ListItemText, Button, Grid,
} from '@mui/material'

import { Formik, Form, Field, FieldArray } from 'formik'

import { SelectField } from '../components/SelectField'
import { CustomField } from '../components/CustomField'

import { validation } from './components/validation'

import { onFileSelect } from 'Utils/onFileSelect'
import { getAllCategories, getAllProjects } from 'Utils/get'

const fields = [
  { label: 'Name', name: 'name' }, { label: 'Description', name: 'description' }, { label: 'Tags', name: 'tags' }
]

export const CreateFileDialog = (props) => {
  return (
    <Formik initialValues={{}} onSubmit={props.formSubmit} component={
      ({ values, setFieldValue, setFieldError, errors }) => (
        <Form>
          <Grid container spacing={2}>
            {props.withProjectSelect && (
              <Grid item xs={6}>
                <Field
                  name="project"
                  label="Project"
                  validate={validation.project}
                  items={getAllProjects(props.projects)}
                  onChange={props.selectProject}
                  component={SelectField}
                />
              </Grid>
            )}
            <Grid item xs={props.withProjectSelect ? 6 : 12}>
              <Field
                name="category"
                label="Category"
                validate={validation.category}
                items={getAllCategories(props.categories)}
                onChange={props.selectCategory}
                component={SelectField}
              />
            </Grid>
            {fields.map(field => (
              <Grid item xs={12} key={field.name}>
                <Field label={field.label} name={field.name} component={CustomField} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <FieldArray
                name="attachments"
                render={() => (
                  <React.Fragment>
                    <input
                      style={{ display: "none" }}
                      onChange={e => onFileSelect(e, setFieldValue, setFieldError, values)}
                      id="file_select"
                      multiple
                      type="file"
                    />
                    <label htmlFor="file_select">
                      <Button variant="contained" fullWidth size="small" component="div">
                        Attachments
                      </Button>
                    </label>
                    {errors.attachments
                      ? <FormHelperText error>{errors.attachments}</FormHelperText>
                      : null}
                    <List>
                      {values && values.attachments && values.attachments.map(file => (
                        <ListItem disableGutters key={file.name}>
                          <ListItemText primaryTypographyProps={{ noWrap: true }} primary={file.name} />
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" size="small" fullWidth>
            Save
          </Button>
        </Form>
      )}
    />
  )
}
