import * as types from '../types'

const initialState = {
  user: null,
  userById: null,
  userProjectsById: [],
  appReady: false,
  loading: false,
  snackbar: { message: '', open: false },
  activateFlag: 0,
  companyActivateFlag: 0,
  addUserToGroupPending: false,
  copying: false,
  errors: {
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null
  },
  passPhrase: '',
  settings: {},
  isDomainPublic: false,
  responseInfo: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.RESPONSE_INFO:
      return { ...state, responseInfo: action.data }
    case types.UPDATE_ACCOUNT_INFO_SUCCESS:
      return { ...state, user: { ...state.user, ...action.data } }
    case types.USER_PROJECTS_BY_ID:
      return { ...state, userProjectsById: action.projects }
    case types.GET_USER_BY_ID:
      return { ...state, userById: action.data }
    case types.USER_RESETED:
      return { ...state, user: null }
    case types.SET_LOADING:
      return { ...state, loading: action.data }
    case types.SET_BLOCK_COPYING: {
      return { ...state, copying: action.data }
    }
    case types.SET_USER_TO_GROUP_PENDING:
      return { ...state, addUserToGroupPending: action.data }
    case types.ACCOUNT_ACTIVATED:
      return { ...state, activateFlag: 1 }
    case types.VALIDATION_ERROR:
      return { ...state, errors: action.data }
    case types.ACCOUNT_NOT_ACTIVATED:
      return { ...state, activateFlag: 2 }
    case types.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: action.data.user.info,
        passPhrase: action.data.user.passPhrase
      }
    case types.ACCOUNT_LOGOUT:
      return { ...initialState, appReady: true }
    case types.APP_READY:
      return { ...state, appReady: true }
    case types.SET_SNACKBAR:
      return { ...state, snackbar: { message: action.message, open: true } }
    case types.SWITCH_AUTH_ERROR:
      return { ...state, authError: action.data }
    case types.SWITCH_REG_ERROR:
      return { ...state, regError: action.data }
    case types.CLEAR_SNACKBAR:
      return { ...state, snackbar: { message: '', open: false } }
    case types.LOCAL_SETTINGS_LOADED:
      return { ...state, settings: action.data }
    case types.COMPANY_ACCOUNT_ACTIVATED:
      return { ...state, companyActivateFlag: 1 }
    case types.COMPANY_ACCOUNT_NOT_ACTIVATED:
      return { ...state, companyActivateFlag: 2 }
    case types.IS_DOMAIN_PUBLIC:
      return { ...state, isDomainPublic: action.data }
    case types.MASTER_PASSWORD_CHANGED:
      return {
        ...state,
        passPhrase: action.data.passPhrase,
        user: { ...state.user, public_key: action.data.public_key }
      }
    default:
      return state
  }
}
