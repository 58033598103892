/* eslint-disable array-element-newline */
import React from 'react'

import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'
import {
	addUserToProject, loadUsersList, changeUserPermissionsProject, loadAvailableUsersForProject,
	loadAvailableGroupsForProject, addProject, loadProject, deleteProject, addGroupToProject,
	changeGroupPermissionsProject, loadGroupsProject, stateReset, deleteUser, deleteGroup,
} from 'Actions/projects'

import { Helmet } from 'react-helmet'

import { AddVaultForm } from '../GroupSettings/components/AddVaultForm'
import { LeftColumn } from './components/LeftColumn'
import { RightColumn } from './components/RightColumn'

import { TwoColumnSettings } from 'Layouts/front/TwoColumnSettings'
import { ContentWrap } from 'Layouts/front/ContentWrap'

import { getPermissionsNameByValue } from 'Utils/PermissionsHelper'
import CryptoHelper from 'Utils/CryptoHelper'

let cryptoHelper = null

const useStyles = makeStyles({ root: { padding: 16 } })

export const ProjectSettings = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		availableUsersForProject, usersProjectList, userPermissions, groupsProjectList,
		currentProject, availableGroupsForProject
	} = useSelector((state) => state.projects)
	const hash = useSelector((state) => state.app.passPhrase)

	const [appReady, setAppReady] = React.useState(false)
	const [userAddFormVisibility, setUserAddFormVisibility] = React.useState(false)
	const [groupAddFormVisibility, setGroupAddFormVisibility] = React.useState(false)

	const projectId = parseInt(props.match.params.id, 10)

	const initCryptoHelper = () => {
		props.cryptoWorker.postMessage({ type: 'GENERATE_RSA_KEY', payload: hash })
		props.cryptoWorker.once('initialized', () => {
			props.cryptoWorker.postMessage({
				type: 'DECRYPT_KEY',
				payload: currentProject.encrypted_secret_key.secret_key
			})
			props.cryptoWorker.once('decrypted_key', (secretKey) => {
				cryptoHelper = new CryptoHelper(secretKey)
				setAppReady(true)
			})
		})
	}

	React.useEffect(async () => {
		await dispatch(stateReset())
		await dispatch(loadProject(projectId))
		await dispatch(loadUsersList(projectId))
		await dispatch(loadAvailableUsersForProject(projectId))
		await dispatch(loadGroupsProject(projectId))
		await dispatch(loadAvailableGroupsForProject(projectId))
	}, [])

	React.useEffect(() => {
		if (usersProjectList.length !== 0)
			initCryptoHelper()
	}, [usersProjectList])

	const onAddUsers = async (userIds) => {
		const serverPublicKey = window.localStorage.getItem('server_public_key') || ''
		const encryptedServerKey = cryptoHelper.ecryptedSecretkey(serverPublicKey)
		await Promise.all([userIds.map(id => dispatch(addUserToProject(id, encryptedServerKey)))])
		setUserAddFormVisibility(false)
	}

	const onAddGroups = async (groupIds) => {
		const serverPublicKey = window.localStorage.getItem('server_public_key') || ''
		const encryptedServerKey = cryptoHelper.ecryptedSecretkey(serverPublicKey)
		await Promise.all([groupIds.map(id => dispatch(addGroupToProject(id, encryptedServerKey)))])
		setGroupAddFormVisibility(false)
	}

	const getPermissions = (arrayPermissions) => {
		const userPermissionsNames = getPermissionsNameByValue(userPermissions)
		const flag = userPermissionsNames.some(userPerm => arrayPermissions.some(perm => userPerm.indexOf(perm) !== -1))
		return flag
	}

	const openUserAddForm = () => setUserAddFormVisibility(true)
	const openGroupAddForm = () => setGroupAddFormVisibility(true)

	const deleteGroupFuntion = async (idGroup) => {
		await dispatch(deleteGroup(idGroup))
		await dispatch(loadAvailableGroupsForProject(projectId))
	}

	const deleteUserFunction = async (idUser) => {
		await dispatch(deleteUser(idUser))
		await dispatch(loadAvailableUsersForProject(projectId))
	}

	return (
		<ContentWrap className={classes.root}>
			<Helmet title={`${currentProject.name} project settings`} />
			<AddVaultForm
				items={availableUsersForProject}
				title="Add existing user"
				primaryKey="full_name"
				secondaryKey="email"
				open={userAddFormVisibility}
				onClose={() => setUserAddFormVisibility(false)}
				onSave={onAddUsers}
			/>
			<AddVaultForm
				items={availableGroupsForProject}
				title="Add existing groups"
				primaryKey="name"
				secondaryKey="description"
				open={groupAddFormVisibility}
				onClose={() => setGroupAddFormVisibility(false)}
				onSave={onAddGroups}
			/>
			{appReady ? (
				<TwoColumnSettings
					title="Project settings"
					leftColumn={(
						<LeftColumn
							project={currentProject}
							addProject={addProject}
							deleteProject={deleteProject}
							getPermissions={getPermissions}
							history={props.history}
						/>
					)}
					rightColumn={(
						<RightColumn
							project={currentProject}
							users={usersProjectList}
							groups={groupsProjectList}
							getPermissions={getPermissions}
							changeGroupPermissionsProject={changeGroupPermissionsProject}
							changeUserPermissionsProject={changeUserPermissionsProject}
							openUserAddForm={openUserAddForm}
							openGroupAddForm={openGroupAddForm}
							deleteUser={deleteUserFunction}
							deleteGroup={deleteGroupFuntion}
						/>
					)}
				/>
			) : (
				<CircularProgress color="primary" className={classes.progress} />
			)}
		</ContentWrap>
	)
}
