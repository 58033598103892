import React from 'react'

import { makeStyles } from '@mui/styles'
import { MenuItem, Typography, Menu, IconButton } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'

const useStyles = makeStyles({ titleButton: { marginRight: 10 } })

export const Title = (props) => {
  const classes = useStyles()

  const ref = React.useRef()

  return (
    <React.Fragment>
      <Typography variant="h4" color="textSecondary">
        {props.currentProject.name.length > 20 ?
          `${props.currentProject.name.substring(0, 20)}...` : props.currentProject.name}
        <IconButton ref={ref} className={classes.titleButton} size="small" onClick={props.handleOpen}>
          <ExpandMoreIcon />
        </IconButton>
        <Menu anchorEl={ref.current} open={props.showProjectsMenu} onClose={props.handleClose}>
          {props.projectsList.map(
            project => (
              <MenuItem key={project.id} value={project.id} onClick={props.handleProjectsChange}>
                {project.name}
              </MenuItem>
            )
          )}
        </Menu>
        <IconButton onClick={props.openProjectSettings} className={classes.titleButton} size="small">
          <SettingsIcon />
        </IconButton>
      </Typography>
      <Typography variant="subtitle1" color="primary" gutterBottom>
        {props.getPermissions(['admin', 'write']) ? 'modification mode' : 'read only mode'}
      </Typography>
    </React.Fragment>
  )
}
