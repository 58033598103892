import React from 'react'

import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react'

const formInitialState = { name: '', description: '', is_confirm: false, is_superuser: false }

export const EditUserDialog = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState(formInitialState)

  const handleSubmit = async () => {
    setLoading(true)
    formData.id = props.initialValues.id
    props.onSubmit(formData)
    setLoading(false)
    setFormData(formInitialState)
  }

  React.useEffect(() => {
    if (props.initialValues)
      setFormData(props.initialValues)
  }, [props.initialValues])

  const onInputChange = (event, data) => {
    const fieldValue = data.value || data.checked
    setFormData((prevState) => ({ ...prevState, [data.name]: fieldValue }))
  }

  return (
    <Modal size="mini" open={props.open} onClose={props.onClose}>
      <Modal.Header>Edit user</Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="first_name"
              value={formData.first_name}
              placeholder='First name'
              onChange={onInputChange}
            />
            <Form.Input
              fluid
              name="last_name"
              value={formData.last_name}
              placeholder='Last name'
              onChange={onInputChange}
            />
          </Form.Group>
          <Form.Checkbox
            checked={formData.is_confirm}
            onChange={onInputChange}
            name="is_confirm"
            label="It's confirm"
          />
          <Form.Checkbox
            checked={formData.is_superuser}
            onChange={onInputChange}
            name="is_superuser"
            label="It's superuser"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>Cancel</Button>
        <Button onClick={handleSubmit} positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}
