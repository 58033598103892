import React from 'react'

import {
  List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Switch,
} from '@mui/material'

import { useDispatch } from 'react-redux'

export const ChangePermissionsForm = (props) => {
  const dispatch = useDispatch()

  const { permissions = [] } = props.item
  const mass = permissions.map((permission) => {
    if (permission.binary_value !== 1)
      return permission.binary_value
    return true
  })

  const [checked, setChecked] = React.useState([...mass])

  const handleToggle = (value, idItem) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = []
    if (currentIndex === -1)
      newChecked.push(value)

    dispatch(props.changePermissions(newChecked[0] ? newChecked[0] : '', idItem))
    setChecked(newChecked)
  }

  return (
    <List
      subheader={<ListSubheader>Permissions settings</ListSubheader>}
      style={{ width: '33.3%' }}
    >
      <ListItem>
        <ListItemText primary="Admin" />
        <ListItemSecondaryAction>
          <Switch
            disabled={props.disabled}
            onClick={handleToggle(3, props.item.id)}
            checked={checked.indexOf(3) !== -1}
          />
        </ListItemSecondaryAction>
      </ListItem>
      {!props.group
        ? (
          <ListItem>
            <ListItemText primary="Write" />
            <ListItemSecondaryAction>
              <Switch
                disabled={props.disabled}
                onClick={handleToggle(2, props.item.id)}
                checked={checked.indexOf(2) !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ) : <div />
      }
    </List>
  )
}
