import { authApi } from './api'

export const getImmutableDomain = (domainList = []) => {
  let mainDomalin = ''
  domainList.forEach((item) => {
    if (item.is_primary)
      mainDomalin = item.domain
  })
  return mainDomalin
}

export const getMutableDomains = (domains = null, immutableDomain = '') => {
  if (!domains || !domains.length)
    return [{ domain: '' }]

  const newDomains = domains.map((domainItem) => {
    const { domain: fullDomain, ...domainParams } = domainItem
    const indexOfMutablDomain = fullDomain ? fullDomain.indexOf(immutableDomain) : -1
    const domain = indexOfMutablDomain > 0 ? fullDomain.slice(0, indexOfMutablDomain - 1) : ''
    return { domain, ...domainParams }
  })
  return newDomains
}

const setFullDomains = (domains, immutableDomain = '') => {
  if (!domains || !domains.length)
    return [{ domain: '' }]

  const newDomains = domains.map((domainItem) => {
    const { domain, ...domainParams } = domainItem
    const match = domain && domain.match(/^\s*?([\w,\.]*?)\.*\s*?$/)
    const partialDomain = match ? match[1] : null
    if (partialDomain && partialDomain.length > 0 && partialDomain !== '.') {
      const fullDomain = `${partialDomain}.${immutableDomain}`
      return { domain: fullDomain, ...domainParams }
    }
    return { domain: immutableDomain, ...domainParams }
  })
  return newDomains
}

const setDomainsParams = (domains) => {
  const newDomains = domains.map((domainItem, index) => {
    const { id = null, is_primary: isPrimary = false, domain } = domainItem
    return id ? { id, is_primary: isPrimary, domain } : { is_primary: isPrimary, domain }
  })
  return newDomains
}

export const getDomainsForSubmit = (domains, immutableDomain = '') => {
  const fullDomains = setFullDomains(domains, immutableDomain)
  return setDomainsParams(fullDomains)
}

export const getDomainsToDelete = (companyDomains, domainsForSubmit) => {
  const domainsToDelete = companyDomains.filter((domain) => {
    const find = domainsForSubmit.find(domainForSubmit => (
      domainForSubmit.id && domainForSubmit.id === domain.id))
    return !find
  }).map(domain => ({ id: domain.id, is_primary: domain.is_primary }))
  return domainsToDelete
}

export const updateDomains = async (domainsToUpdate, domainsToDelete, name, companyId,
  { props, setErrors, setSubmitting }) => {
  try {
    await authApi.deleteDomains({ subdomains: domainsToDelete }, companyId)
    const result = await authApi.updateDomains({ domains: domainsToUpdate, name }, companyId)
    setSubmitting(false)
    props.afterSubmit(result)
  }
  catch (error) {
    const msg = error.response && error.response.statusText
    setErrors({ non_field_errors: msg })
    setSubmitting(false)
  }
}

export const checkForDuplicates = array => new Set(array).size !== array.length

export const eliminateDuplicates = (array) => {
  const duplicates = []
  array.forEach((item, index) => {
    const currentDuplicates = [index]
    for (let i = index + 1; i < array.length; i += 1)
      if (item === array[i])
        currentDuplicates.push(i)
    if (currentDuplicates.length > 1)
      duplicates.push(currentDuplicates)
  })
  return duplicates
}

export const geDomainLabel = (index, isPrimary) => (
  isPrimary ? `Company subdomain №${index + 1}(primary)` : `Company subdomain №${index + 1}`
)
