import React from 'react'

import { Sidebar, Container } from 'semantic-ui-react'
import { makeStyles } from '@mui/styles'

import { GlobalMessage } from 'Components/admin/GlobalMessage'
import { SideBar } from './components/SideBar'

import WebWorker from 'Utils/WebWorker'
import CryptoWorker from 'Utils/crypto.worker'

const useStyles = makeStyles({
  contentRoot: {
    marginLeft: 150,
    maxHeight: '100vh',
    overflow: 'auto'
  },
  contentContainer: { padding: 14 }
})

let worker = null

export const AdminMain = (props) => {
  const classes = useStyles()

  React.useEffect(() => {
    worker = new WebWorker(CryptoWorker)
  }, [])

  return (
    <Sidebar.Pushable>
      <SideBar />
      <div className={classes.contentRoot}>
        <Container fluid className={classes.contentContainer}>
          <GlobalMessage />
          {props.children(worker)}
        </Container>
      </div>
    </Sidebar.Pushable>
  )
}
