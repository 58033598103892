import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { UsersListPage } from './pages/List'
import { UserDetailPage } from './pages/Detail'

const PrivateRoute = ({ data, dataOfUserById, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!data.user)
        return <Redirect to={{ pathname: '/login' }} />
      return <Component data={data} dataOfUserById={dataOfUserById} {...props} {...rest} key={rest.path} />
    }}
  />
)

export const usersRoutes = (data, CryptoWorker) => ([
  <PrivateRoute
    exact
    data={data}
    key="users_list"
    path="/users"
    component={UsersListPage}
  />,
  <PrivateRoute
    data={data}
    key="user_details"
    path="/users/:id"
    component={UserDetailPage}
    cryptoWorker={CryptoWorker}
  />
])
