/* eslint-disable no-underscore-dangle */
import { USER_FETCHED, USERS_FETCHED, USER_UPDATED, USER_GROUPS_FETCHED, SET_IS_SUPERUSER } from '../types'

const initialState = {
  userList: [],
  currentUser: {},
  currentUserGroups: [],
  userIsSuperuser: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_SUPERUSER:
      return { ...state, userIsSuperuser: action.data }
    case USER_FETCHED:
      return { ...state, currentUser: action.data }
    case USERS_FETCHED:
      return { ...state, userList: action.data }
    case USER_UPDATED: {
      const updatedClientsList = state.userList.slice()
      for (let i = 0; i < updatedClientsList.length; i += 1)
        if (updatedClientsList[i]._id === action.data._id) {
          updatedClientsList[i] = action.data
          break
        }
      return { ...state, currentUser: action.data, userList: updatedClientsList }
    }
    case USER_GROUPS_FETCHED:
      return { ...state, currentUserGroups: action.data }
    default:
      return state
  }
}
