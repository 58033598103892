/* eslint-disable array-element-newline */
import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { loadGroupList, addGroup } from 'Actions/groups'
import { changeLocalSettings } from 'Actions/app'

import {
  Grid, Dialog, DialogContent, DialogTitle, Button, DialogActions,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DataGrid } from '@mui/x-data-grid'

import { Helmet } from 'react-helmet'

import { CreateItemComponent } from 'Components/front/CreateItemComponent'
import { ItemAddForm } from 'Components/front/ItemAddForm'
import { ContentWrap } from 'Layouts/front/ContentWrap'

const useStyles = makeStyles({ container: { width: '100%', maxWidth: 'none' } })

const tableColumns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'description', headerName: 'Description', width: 300 }
]

export const GroupsList = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [groupCreateDialog, setGroupCreateDialog] = React.useState(false)

  const groupList = useSelector((state) => state.groups.groupList)

  React.useEffect(() => {
    dispatch(loadGroupList())
  }, [])

  const onChangeRowsPerPage = (count) => dispatch(changeLocalSettings({ groupPerPage: count }))

  const onGroupAdd = async (data) => {
    await dispatch(addGroup(data))
    setGroupCreateDialog(false)
  }

  const getRows = (list) => {
    const data = []
    list.map(item => data.push({ id: item.id, name: item.name, description: item.description }))
    return data
  }

  const hideGroupCreateDialog = () => setGroupCreateDialog(false)
  const showGroupCreateDialog = () => setGroupCreateDialog(true)

  return (
    <ContentWrap className={classes.container}>
      <Helmet title="Groups" />
      <Dialog onClose={hideGroupCreateDialog} aria-labelledby="form-dialog-title" open={groupCreateDialog}>
        <DialogTitle id="scroll-dialog-title">Add group</DialogTitle>
        <DialogContent>
          <ItemAddForm onSubmit={onGroupAdd} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" form="my-form">
            CREATE
          </Button>
          <Button variant="contained" onClick={hideGroupCreateDialog}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      {getRows(groupList).length !== 0
        ? (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Button variant="contained" size="small" onClick={showGroupCreateDialog}>
                New group
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                  rows={getRows(groupList)}
                  columns={tableColumns}
                  autoHeight={false}
                  loading={getRows(groupList).length === 0 ? true : false}
                  disableColumnFilter
                  onRowClick={data => props.history.push(`/groups/${data.id}`)}
                  onPageSizeChange={onChangeRowsPerPage}
                  disableSelectionOnClick={true}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <CreateItemComponent
            text="You have no group yet."
            object="group"
            onCreate={showGroupCreateDialog}
          />
        )}
    </ContentWrap>
  )
}
