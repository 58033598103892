import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  loadGroupList, loadGroup, loadGroupProjects, updateGroup, deleteUser, deleteGroup,
} from 'Actions/groups'

import { Grid, Dimmer, Loader, Button, Icon } from 'semantic-ui-react'

import { Helmet } from 'react-helmet'

import { DataTable } from 'Components/admin/DataTable'
import { GroupDetailsDialog } from './GroupDetailsDialog'
import { EditGroupDialog } from './EditGroupDialog'

const tableHeader = [
  {
    name: 'id',
    label: 'ID',
    collapsing: true,
    singleLine: true
  },
  { name: 'name', label: 'Name' },
  { name: 'description', label: 'Description' },
  {
    name: 'actions',
    label: 'Actions',
    collapsing: true,
    singleLine: true
  }
]

export const AdminGroups = () => {
  const dispatch = useDispatch()

  const { groupList, currentGroup } = useSelector((state) => state.groups)

  const [loading, setLoading] = React.useState(false)
  const [showGroupDetailsDialog, setShowGroupDetailsDialog] = React.useState(false)
  const [showGroupEditDialog, setShowGroupEditDialog] = React.useState(false)
  const [workedGroup, setWorkedGroup] = React.useState(null)

  React.useEffect(async () => {
    if (groupList.length === 0) {
      setLoading(true)
      await dispatch(loadGroupList())
      setLoading(false)
    }
  }, [])

  const onEditGroup = (group) => {
    dispatch(updateGroup(group))
    setShowGroupEditDialog(false)
  }

  const onDeleteUserFromGroup = (user) => dispatch(deleteUser(user))

  const showDetailsDialog = async (data) => {
    setLoading(true)
    await dispatch(loadGroup(data[0]), false)
    await dispatch(loadGroupProjects(data[0]))
    setShowGroupDetailsDialog(true)
    setLoading(false)
  }

  const showEditDialog = (group, event) => {
    event.stopPropagation()
    setShowGroupEditDialog(true)
    setWorkedGroup(group)
  }

  const handleDeleteGroup = async (group, event) => {
    event.stopPropagation()
    setLoading(true)
    await deleteGroup(group.id)
    setLoading(false)
  }

  const data = groupList && groupList.map(item => [
    item.id,
    item.name,
    item.description,
    (
      <React.Fragment key={item.id}>
        <Button onClick={event => showEditDialog(item, event)} size="mini" default icon>
          <Icon name="edit" />
        </Button>
        <Button onClick={event => handleDeleteGroup(item, event)} size="mini" color="red" icon>
          <Icon name="delete" />
        </Button>
      </React.Fragment>
    )
  ])

  return (
    <React.Fragment>
      <Helmet title="Admin Groups" />
      <Grid container>
        <GroupDetailsDialog
          open={showGroupDetailsDialog}
          group={currentGroup}
          onClose={() => setShowGroupDetailsDialog(false)}
          onDeleteUser={onDeleteUserFromGroup}
        />
        <EditGroupDialog
          open={showGroupEditDialog}
          onClose={() => setShowGroupEditDialog(false)}
          onSubmit={onEditGroup}
          initialValues={workedGroup}
        />
        <Grid.Row>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>{'common.loading'}</Loader>
            </Dimmer>
          )}
          <DataTable onRowClick={showDetailsDialog} header={tableHeader} data={data} />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}
