import React from 'react'

import {
  AppBar, Toolbar, Typography, IconButton, Button, Menu,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { AccountCircleRounded } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'Actions/app'

import { NavLink } from 'react-router-dom'


import { icons } from '../../../../constants/icons'

const useStyles = makeStyles({
  appBar: { zIndex: 9997 },
  grow: { flexGrow: 1 },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  menu: { zIndex: 9998 }
})

export const Topbar = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const userInfo = useSelector((state) => state.app.user)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const searchIcon = icons.find(icon => icon.id === userInfo.icon_id)
  const Icon = (searchIcon && searchIcon.Icon) || AccountCircleRounded
  const open = Boolean(anchorEl)

  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar}>
      <Toolbar>
        <IconButton className={classes.menuButton} onClick={props.onDrawerClick} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          Corppass
          </Typography>
        <Typography variant="h6" color="error" className={classes.grow}>
          {!userInfo.is_confirm ? ('Account not activated') : <div />}
        </Typography>
        {userInfo.is_superuser
          && (
            <Button color="inherit">
              <NavLink
                style={{ color: 'inherit', textDecoration: 'none' }}
                to="/admin-home"
              >
                Admin
                </NavLink>
            </Button>
          )}
        <Button
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <Icon />
          {` ${userInfo.first_name || 'Anonymous'} ${userInfo.last_name || ''}`}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.menu}
          open={open}
          onClose={handleClose}
        >
          <MenuItem>
            <NavLink
              style={{ color: 'inherit', textDecoration: 'none' }}
              to={`/users/${userInfo.id}`}
            >
              Profile
              </NavLink>
          </MenuItem>
          <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
