import React from 'react'

import { Modal, Table, List, Button } from 'semantic-ui-react'

export const ProjectDetailsDialog = (props) => {
  return (
    <Modal size="tiny" open={props.open} onClose={props.onClose}>
      <Modal.Header>Project details</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{props.project.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{props.project.description}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Owner</Table.Cell>
              <Table.Cell>{`${props.project.owner.first_name} ${props.project.owner.last_name}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Users</Table.Cell>
              <Table.Cell>
                <List>
                  {props.project.users.length !== 0 &&
                    props.project.users.map((id) => props.usersList.map((user) => {
                      if (user.id === id)
                        return (
                          <List.Item key={user.id}>
                            <List.Content>
                              <Button onClick={() => props.onDeleteUser(user)} icon="delete" size="mini" />
                              {`${user.full_name} (${user.email})`}
                            </List.Content>
                          </List.Item>
                        )
                    }))}
                </List>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Groups</Table.Cell>
              <Table.Cell>
                <List>
                  {props.project.groups.map(item => (
                    <List.Item key={item.id}>
                      <List.Content>
                        <Button onClick={() => props.onDeleteGroup(item)} icon="delete" size="mini" />
                        {`${item.name} ${item.description}`}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )
}
