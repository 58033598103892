import { authApi } from './api'

export const createPassword = async (data, userId, { props, setErrors, setSubmitting }) => {
  authApi.createPassword(data, userId)
    .then((result) => {
      props.afterSubmit()
    })
    .catch((error) => {
      const msg = error.response && error.response.statusText
      setErrors({ non_field_errors: msg })
      setSubmitting(false)
    })
}
