import * as types from '../types'

import { push } from 'connected-react-router'
import RSA from 'seededrsa'
import CryptoJS from 'crypto-js'
import { axios } from './axiosConfig'
import constants from '../../../constants'

export const checkUrl = (response) => {
  return (dispatch) => {
    dispatch({ type: types.RESPONSE_INFO, data: response })
    return response
  }
}

export const getUserById = (id) => {
  return (dispatch) => {
    axios({ method: 'get', url: `/users/${id}/info/` })
      .then((response) => {
        dispatch({ type: types.GET_USER_BY_ID, data: { ...response.data.user_info, id: id } })
        dispatch({ type: types.SET_IS_SUPERUSER, data: response.data.user_info.is_superuser })
      })
      .catch((err) => console.error(err))
    axios({ method: 'get', url: `users/${id}/projects/` })
      .then((response) => {
        dispatch({ type: types.USER_PROJECTS_BY_ID, projects: response.data.projects })
      })
      .catch((err) => console.error(err))
    axios({ method: 'get', url: `users/${id}/groups/` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.USER_GROUPS_FETCHED, data: response.data.groups })
      })
  }
}

export const loadLocalSettings = () => {
  return (dispatch) => {
    const settings = localStorage.getItem('local_settings')
    dispatch({
      type: types.LOCAL_SETTINGS_LOADED,
      data: settings ? JSON.parse(settings) : {}
    })
  }
}

export const changeLocalSettings = (settings) => {
  return (dispatch, setState) => {
    const { app } = setState()
    const newSettings = { ...app.settings, ...settings }
    localStorage.setItem('local_settings', JSON.stringify(newSettings))
    dispatch({ type: types.LOCAL_SETTINGS_LOADED, data: newSettings })
  }
}

export const setLoading = (value) => {
  return (dispatch) => {
    dispatch({ type: types.SET_LOADING, data: value })
  }
}

export const setSnackbar = (message) => {
  return { type: types.SET_SNACKBAR, message }
}

export const clearSnackbar = () => {
  return { type: types.CLEAR_SNACKBAR }
}

export const errorCatcher = (err, dispatch, msg) => {
  console.log(err)
  let msgStr = ''
  for (const key in msg)
    if (key)
      msgStr = `${msgStr + msg[key]}\n`

  dispatch(setSnackbar(msgStr.length > 20 ? 'Error.' : msgStr))
  dispatch(setLoading(false))
  throw (msg)
}

export const signInErrorCatcher = (err, dispatch) => {
  let msg = 'Login went wrong'
  if (err.response)
    switch (err.response.statusText) {
      case constants.errors.INVALID_EMAIL:
        msg = 'Invalid email'
        dispatch({
          type: types.SWITCH_AUTH_ERROR,
          data: { error: constants.errors.INVALID_EMAIL }
        })
        break
      case constants.errors.BAD_CREDENTIALS:
        msg = 'Invalid email or password'
        dispatch({
          type: types.SWITCH_AUTH_ERROR,
          data: { error: constants.errors.BAD_CREDENTIALS }
        })
        break
      default:
        msg = 'Unknown error'
    }
  errorCatcher(err, dispatch, msg)
}

export const signUpErrorCatcher = (err, dispatch) => {
  let msg = 'Registration went wrong'
  switch (err.response.statusText) {
    case constants.errors.EMAIL_EXISTS:
      msg = 'Email already use'
      dispatch({
        type: types.SWITCH_REG_ERROR,
        data: { error: constants.errors.EMAIL_EXISTS }
      })
      break
    case constants.errors.NICKNAME_EXISTS:
      msg = 'Nickname already use'
      dispatch({
        type: types.SWITCH_REG_ERROR,
        data: { error: constants.errors.NICKNAME_EXISTS }
      })
      break
    default:
      msg = 'Unknown error'
  }
  errorCatcher(err, dispatch, msg)
}

export const whoami = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'account/get_account/' })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.GET_ACCOUNT_SUCCESS, data: response.data })
        dispatch({ type: types.APP_READY })
      })
      .catch((err) => {
        dispatch(setLoading(false))
        if (`${window.location.pathname}`.indexOf('activate') === -1
          && `${window.location.pathname}`.indexOf('register') === -1
          && `${window.location.pathname}`.indexOf('login') === -1)
          dispatch(push('/login'))
        dispatch({ type: types.APP_READY })
      })
  }
}

export const checkDomain = (redirectOnFail = () => null) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'domaininfo/' })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.IS_DOMAIN_PUBLIC, data: response.data.is_public })
        if (!response.data.is_public)
          dispatch(whoami())
        else
          dispatch({ type: types.APP_READY })
      })
      .catch((err) => {
        dispatch(setLoading(false))
        dispatch(push('/something-went-wrong'))
        redirectOnFail()
      })
  }
}

export const signIn = (params) => {
  const hash = CryptoJS.SHA256(params.password).toString(CryptoJS.enc.Hex)
  params.passPhrase = hash
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({
      method: 'POST',
      url: 'auth/obtain_token/',
      data: params,
      passPhrase: hash
    })
      .then((response) => {
        if (response.status !== 205) {
          localStorage.setItem('server_public_key', response.data.user.server_public_key)
          dispatch({
            type: types.GET_ACCOUNT_SUCCESS,
            data: response.data
          })
          dispatch(push('/projects'))
          dispatch(setLoading(false))
        }
        else {
          dispatch(push(`/activate?email=${params.username}`))
          dispatch(setLoading(false))
        }
      })
      .catch((err) => {
        dispatch(setSnackbar('Invalid email or password!'))
        dispatch(setLoading(false))
      })
  }
}

export const signUp = (params) => {
  const hash = CryptoJS.SHA256(params.password).toString(CryptoJS.enc.Hex)
  return async (dispatch) => {
    dispatch(setLoading(true))
    const rsa = new RSA(hash)
    await rsa.generateNew(1024, '03')
    const data = { ...params, public_key: rsa.n.toString() }
    try {
      const response = await axios({ method: 'POST', url: 'register/registration/', data })
      dispatch(setLoading(false))
      if (response.data.success === false) {
        dispatch({ type: types.VALIDATION_ERROR, data: response.data.error })
      }
      else {
        dispatch(push('/login'))
        dispatch(setSnackbar(
          'Registration was successful; notification letter was sent. Check your email. We have sent an email to your address.'
          + '            account.'))
        dispatch(setLoading(false))
      }
    }
    catch (err) {
      signUpErrorCatcher(err, dispatch)
    }
  }
}

export const activate = (token) => {
  const data = { token }
  return dispatch => axios({ method: 'PUT', url: 'register/activate/', data })
    .then((response) => {
      switch (response.status) {
        case 205:
          dispatch(push('/login'))
          dispatch({ type: types.ACCOUNT_NOT_ACTIVATED })
          break
        case 200:
          dispatch(push('/login'))
          break
        default:
          break
      }
    })
}

export const companyActivate = (token) => {
  const data = { token }
  return dispatch => axios({ method: 'PUT', url: 'company_register/activate/', data })
    .then((response) => {
      switch (response.status) {
        case 205:
          dispatch({ type: types.ACCOUNT_NOT_ACTIVATED })
          break
        case 200:
          dispatch({ type: types.ACCOUNT_ACTIVATED })
          break
        default:
          break
      }
    })
    .catch((err) => dispatch({ type: types.ACCOUNT_NOT_ACTIVATED }))
}

export const sendConfirmEmailAgain = (email) => {
  return dispatch => axios({ method: 'GET', url: `register/send_activation_email/?email=${email}` })
    .then(() => {
      dispatch(setSnackbar('Check your email. We have sent an email to your address.'))
    })
}

export const resetUser = () => {
  return dispatch => dispatch({ type: 'USER_RESETED' })
}

export const logout = () => {
  return async (dispatch) => {
    const response = await axios({ method: 'DELETE', url: 'account/logout/' })
    if (response.status === 204 || response.status === 403) {
      dispatch({ type: types.ACCOUNT_LOGOUT })
      dispatch(setSnackbar('Logged out'))
      dispatch(push('/login'))
      window.location.reload()
    }
  }
}

export const updateMasterPassword = (newPassPhrase, newPublicKey) => {
  return {
    type: types.MASTER_PASSWORD_CHANGED,
    data: { passPhrase: newPassPhrase, public_key: newPublicKey }
  }
}
