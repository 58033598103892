/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { axios } from './front/actions/axiosConfig'
import {
  appReducer, usersReducer, projectsReducer, passwordsReducer, groupsReducer, attachmentsReducer, settingsReducer,
  categoriesReducerAdmin,
} from './front/reducers/index'
import { logout, resetUser, checkDomain, checkUrl } from './front/actions/app'

import { appReducerAdmin } from './admin/reducers/index'

export const history = createBrowserHistory()
const reducer = combineReducers({
  router: connectRouter(history),
  app: appReducer,
  users: usersReducer,
  projects: projectsReducer,
  passwords: passwordsReducer,
  groups: groupsReducer,
  attachments: attachmentsReducer,
  settings: settingsReducer,
  appAdmin: appReducerAdmin,
  categories: categoriesReducerAdmin
})

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const middleware = [thunk, routerMiddleware(history)]
const store = createStore(reducer, composeEnhancers(
  applyMiddleware(...middleware)
))

store.dispatch(checkDomain(() => history.push('/something-went-wrong')))

//! Delete this in future!!!
axios.defaults.timeout = 180000

axios.interceptors.response.use(response => store.dispatch(checkUrl(response)), async (error) => {
  if (error.response && error.response.status === 403) {
    store.dispatch(resetUser())
    if (`${window.location.pathname}`.indexOf('login') === -1)
      store.dispatch(logout())
  }
  if (error.response && error.response.status === 401) {
    store.dispatch(resetUser())
    if (`${window.location.pathname}`.indexOf('login') === -1)
      history.push('/login')
  }
  if (error.response && error.response.status === 404)
    history.push('/not-found')
  return Promise.reject(error)
})

export default store
