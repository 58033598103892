import React from 'react'

import { Grid } from '@mui/material'

export const CenterContentTemplate = ({ children }) => (
  <Grid
    container
    style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}
  >
    <Grid item>
      {children}
    </Grid>
  </Grid>
)