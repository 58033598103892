/* eslint-disable object-curly-newline */
/* eslint-disable array-element-newline */
import React from 'react'

import { makeStyles } from '@mui/styles'
import {
  SpeedDial, SpeedDialAction, SpeedDialIcon, DialogTitle, Dialog,
  DialogContent, DialogActions, Button, Grid, CircularProgress,
} from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { useDispatch, useSelector } from 'react-redux'
import {
  copyPassword, movePassword, loadHistory, loadPasswordsList, addPassword,
  updatePassword, loadCategoriesList, loadUsersList, inBasket, loadTags,
  blockCopying, addFile, updateFile,
} from 'Actions/passwords'

import { Helmet } from 'react-helmet'

import { setSnackbar, setLoading } from 'Actions/app'
import { uploadFile, getLink } from 'Actions/attachments'
import { loadProject, loadProjectList, getWritableProjects } from 'Actions/projects'
import { CreatePasswordDialog } from 'Components/front/CreatePasswordDialog'
import { CreateItemComponent } from 'Components/front/CreateItemComponent'
import { CreateFileDialog } from 'Components/front/CreateFileDialog'
import { PreviewDialog } from 'Components/front/PreviewDialog'
import { getPermissionsNameByValue } from 'Utils/PermissionsHelper'
import { PasswordFilterPanel } from './components/PasswordFilterPanel'
import { Title } from './components/Title'
import { PasswordCopyForm } from './components/PasswordCopyForm'
import { HistoryForm } from './components/HistoryForm'
import { PasswordListItem } from './components/PasswordListItem'

import CryptoHelper from 'Utils/CryptoHelper'
import { ContentWrap } from 'Layouts/front/ContentWrap'

let cryptoHelper = null

const useStyles = makeStyles({
  root: { padding: 20 },
  dialog: { zIndex: 9999 },
  tool: {
    position: 'absolute',
    bottom: 16,
    right: 16
  },
  progress: { margin: 16 * 2 },
  containerHeight: {
    maxHeight: '55vh',
    overflow: 'auto'
  }
})

export const ProjectDetail = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    passwordsListByIds, categoriesList, tagsList, historyForPassword, passwordLoading
  } = useSelector((state) => state.passwords)
  const {
    writableProjects, currentProject, userPermissions, projectList
  } = useSelector((state) => state.projects)
  const { copying, passPhrase } = useSelector((state) => state.app)

  const [appReady, setAppReady] = React.useState(false)
  const [copyFormVisibility, setCopyFormVisibility] = React.useState(false)
  const [historyVisibility, setHistoryVisibility] = React.useState(false)
  const [selectedCategory, setSelectedCategory] = React.useState(0)
  const [selectedTags, setSelectedTags] = React.useState([])
  const [workingPassword, setWorkingPassword] = React.useState({})
  const [showCreateDialog, setShowCreateDialog] = React.useState(false)
  const [showProjectsMenu, setShowProjectsMenu] = React.useState(false)
  const [expanded, setExpanded] = React.useState(-1)
  const [showSpeedDial, setShowSpeedDial] = React.useState(false)
  const [showPreviewDialog, setShowPreviewDialog] = React.useState(false)
  const [previewData, setPreviewData] = React.useState(null)
  const [dialogType, setDialogType] = React.useState(1)

  const projectId = parseInt(props.match.params.id, 10)

  const initCryptoHelper = () => {
    const projectSecretKey = currentProject.encrypted_secret_key.secret_key
    props.cryptoWorker.dispatch('GENERATE_RSA_KEY', passPhrase)
      .then(() => props.cryptoWorker.dispatch('DECRYPT_KEY', projectSecretKey))
      .then((decryptedKey) => {
        cryptoHelper = new CryptoHelper(decryptedKey)
        setAppReady(true)
      })
  }

  React.useEffect(() => {
    dispatch(loadProject(projectId))
    dispatch(loadCategoriesList())
    dispatch(loadPasswordsList(projectId, true))
    dispatch(loadUsersList(projectId))
    dispatch(loadTags(projectId))
    dispatch(loadProjectList())
  }, [])

  React.useEffect(() => {
    if (!showCreateDialog)
      dispatch(loadPasswordsList(projectId, true))
  }, [showCreateDialog])

  React.useEffect(() => {
    if (currentProject.encrypted_secret_key !== undefined)
      initCryptoHelper()
  }, [currentProject])

  const getPermissions = (arrayPermissions) => {
    const userPermissionsNames = getPermissionsNameByValue(userPermissions)
    const flag = userPermissionsNames.some(userPerm => (
      arrayPermissions.some(perm => userPerm.indexOf(perm) !== -1)
    ))
    return flag
  }

  const decryptPassword = (password) => {
    const result = { ...password }
    const encryptedInfo = { password: password.encrypted_password }
    const decryptedPassword = cryptoHelper.decryptPassword(encryptedInfo)
    for (const field in decryptedPassword)
      if (field)
        result[field] = decryptedPassword[field]
    return result
  }

  const copyPasswordFunction = async (password, project) => {
    dispatch(blockCopying(true))
    setCopyFormVisibility(false)
    dispatch(setSnackbar('Password wil be copied. This operation may take some amount of time. '
      + 'Please do not close or reload the page.'))
    const newProjectSecretKey = project.encrypted_secret_key.secret_key
    const secretKey = await props.cryptoWorker.dispatch('DECRYPT_KEY', newProjectSecretKey)
    const decryptedPassword = decryptPassword(password)
    dispatch(copyPassword(project.id, password.id, cryptoHelper.encryptPassword(
      decryptedPassword.password, secretKey
    )))
    dispatch(blockCopying(false))
  }

  const movePasswordFunction = async (password, project) => {
    dispatch(blockCopying(true))
    setCopyFormVisibility(false)
    dispatch(setSnackbar('Password wil be moved. This operation may take some amount of time. '
      + 'Please do not close or reload the page.'))
    const newProjectSecretKey = project.encrypted_secret_key.secret_key
    const secretKey = await props.cryptoWorker.dispatch('DECRYPT_KEY', newProjectSecretKey)
    const decryptedPassword = decryptPassword(password)
    dispatch(movePassword(project.id, password.id, cryptoHelper.encryptPassword(
      decryptedPassword.password, secretKey)))
    dispatch(blockCopying(false))
  }

  const showCopyForm = async (pass) => {
    await dispatch(getWritableProjects())
    setCopyFormVisibility(true)
    setWorkingPassword(pass)
  }

  const showHistory = async (password) => {
    await dispatch(loadHistory(password.id))
    setHistoryVisibility(true)
  }

  const handleChangeCategory = (event) => setSelectedCategory(+event.target.value)
  const handleTagsChange = (event) => setSelectedTags(event.target.value)

  const handleProjectsChange = async (event) => {
    const id = event.currentTarget.value
    props.history.push(`/projects/${id}`)
    setShowProjectsMenu(false)
    setAppReady(false)
    setExpanded(-1)
    await dispatch(loadProject(id))
    await Promise.all([
      dispatch(loadPasswordsList(id, true)),
      dispatch(loadUsersList(id)),
      dispatch(loadTags(id)),
      dispatch(loadProjectList())
    ])
    initCryptoHelper()
  }

  const encryptFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const encrypted = cryptoHelper.encryptFile(e.target.result)
        const blob = new Blob(
          [encrypted],
          { type: 'application/octet-stream' }
        )
        resolve(blob)
      }
      reader.readAsDataURL(file)
    })
  }

  const addPasswordFunction = async (data) => {
    const password = { ...data }
    dispatch(setLoading(true))

    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      password.tags = tags.join(',')
    }

    password.encrypted_password = cryptoHelper.encryptPassword(data.password)
    password.attachments = []

    let queue = Promise.resolve()

    if (data.attachments && data.attachments.length)
      for (let i = 0; i < data.attachments.length; i += 1) {
        const file = data.attachments[i]
        queue = queue.then(() => encryptFile(file))
          .then(blob => dispatch(uploadFile(blob, file.name)))
          .then((uploadedFile) => password.attachments.push(uploadedFile.file.id))
      }

    return queue.then(() => dispatch(addPassword(password)))
      .then(() => {
        dispatch(setLoading(false))
        setShowCreateDialog(false)
      })
  }

  const updatePasswordFunction = async (data) => {
    const password = { ...data }
    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      password.tags = tags.join(',')
    }
    password.encrypted_password = cryptoHelper.encryptPassword(data.password)
    password.attachments = []

    let queue = Promise.resolve()

    if (data.attachments && data.attachments.length)
      for (let i = 0; i < data.attachments.length; i += 1) {
        const attachment = data.attachments[i]
        if (attachment instanceof File)
          queue = queue
            .then(() => encryptFile(attachment))
            .then(blob => dispatch(uploadFile(blob, attachment.name)))
            .then((uploadedFile) => password.attachments.push(uploadedFile.file.id))
        else
          password.attachments.push(attachment.id)
      }

    return queue.then(() => dispatch(updatePassword(password)))
      .then((code) => {
        dispatch(setLoading(false))
        if (code === 5) showHistory(password)
        setShowCreateDialog(false)
      })
  }

  const addFileFunction = async (data) => {
    const password = { ...data }
    dispatch(setLoading(true))
    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      password.tags = tags.join(',')
      password.tags = tags.join(',')
    }

    password.attachments = []

    let queue = Promise.resolve()

    if (data.attachments && data.attachments.length)
      for (let i = 0; i < data.attachments.length; i += 1) {
        const file = data.attachments[i]
        queue = queue
          .then(() => encryptFile(file))
          .then(blob => dispatch(uploadFile(blob, file.name)))
          .then((uploadedFile) => password.attachments.push(uploadedFile.file.id))
      }

    return queue.then(() => dispatch(addFile(password)))
      .then(() => {
        dispatch(setLoading(false))
        setShowCreateDialog(false)
      })
  }

  const updateFileFunction = async (data) => {
    const file = { ...data }
    dispatch(setLoading(true))
    if (data.tags && data.tags.length) {
      const tags = data.tags.match(/([а-яА-Яa-zA-z\d\s])+/g)
      file.tags = tags.join(',')
    }
    file.attachments = []

    let queue = Promise.resolve()

    if (data.attachments && data.attachments.length)
      for (let i = 0; i < data.attachments.length; i += 1) {
        const attachment = data.attachments[i]
        if (attachment instanceof File)
          queue = queue
            .then(() => encryptFile(attachment))
            .then(blob => dispatch(uploadFile(blob, attachment.name)))
            .then((uploadedFile) => file.attachments.push(uploadedFile.file.id))
        else
          file.attachments.push(attachment.id)
      }

    return queue.then(() => dispatch(updateFile(file)))
      .then((code) => {
        setLoading(false)
        if (code === 5) showHistory(file)
        setShowCreateDialog(false)
      })
  }

  const editHandler = (password) => {
    const decryptedPassword = password.type === 1 ? decryptPassword(password) : password
    decryptedPassword.tags = decryptedPassword.tags.map(el => el.name).join(', ')
    setShowCreateDialog(true)
    setWorkingPassword(decryptedPassword)
    setDialogType(password.type)
    setExpanded(-1)
  }

  const openProjectSettings = () => props.history.push(`/project_settings/${currentProject.id}`)
  const handleExpand = (id) => setExpanded((prevState) => prevState !== id ? id : -1)
  const clickSpeedDial = () => setShowSpeedDial(!showSpeedDial)
  const closeSpeedDial = () => setShowSpeedDial(false)
  const closeCreateDialog = () => setShowCreateDialog(false)
  const closeCopyForm = () => setCopyFormVisibility(false)
  const closeHistoryForm = () => setHistoryVisibility(false)
  const handleOpenClose = () => setShowProjectsMenu(!showProjectsMenu)

  const openCreatePasswordDialog = () => {
    setShowCreateDialog(true)
    setDialogType(1)
    setWorkingPassword({})
  }

  const openCreateFileDialog = () => {
    setShowCreateDialog(true)
    setDialogType(2)
    setWorkingPassword({})
  }

  const loadAndDecryptFile = async (id) => {
    const urlData = await getLink(id)
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', urlData.url)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        const blob = xhr.response
        const reader = new FileReader()
        reader.onload = (e) => {
          const decrypted = cryptoHelper.decryptFile(e.target.result)
          resolve(decrypted)
        }
        reader.readAsText(blob)
      }
      xhr.send()
    })
  }

  const downloadFile = (url, name) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const fileOpen = async (id, name) => {
    const data = await loadAndDecryptFile(id)
    downloadFile(data, name)
  }

  const closePreviewDialog = () => setShowPreviewDialog(false)

  const openPreviewDialog = (data) => {
    setShowPreviewDialog(true)
    setPreviewData(date)
  }

  const previewOpen = async (id) => {
    const data = await loadAndDecryptFile(id)
    openPreviewDialog(data)
  }

  return (
    <ContentWrap className={classes.root}>
      <Helmet title={`${currentProject.name} project`} />
      <PreviewDialog open={showPreviewDialog} data={previewData} onClose={closePreviewDialog} />
      <Dialog onClose={closeCreateDialog} className={classes.dialog} maxWidth="xs" open={showCreateDialog}>
        <DialogTitle>
          {`${workingPassword.id ? 'Edit' : 'Create'} ${dialogType === 1 ? 'password' : 'file'}`}
        </DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          {dialogType === 1 ? (
            <CreatePasswordDialog
              categories={categoriesList}
              withProjectSelect={false}
              edit={workingPassword.id}
              formSubmit={Object.keys(workingPassword).length === 0 ? addPasswordFunction : updatePasswordFunction}
            />
          ) : (
              <CreateFileDialog
                categories={categoriesList}
                withProjectSelect={false}
                formSubmit={Object.keys(workingPassword).length === 0 ? addFileFunction : updateFileFunction}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateDialog} variant="contained" size="small">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Title
        currentProject={currentProject}
        projectsList={projectList}
        showProjectsMenu={showProjectsMenu}
        openProjectSettings={openProjectSettings}
        handleOpen={handleOpenClose}
        handleClose={handleOpenClose}
        handleProjectsChange={handleProjectsChange}
        getPermissions={getPermissions}
      />
      {appReady ? (
        <React.Fragment>
          {passwordsListByIds.length ? (
            <React.Fragment>
              <PasswordCopyForm
                password={workingPassword || {}}
                projects={writableProjects}
                copyPassword={copyPasswordFunction}
                movePassword={movePasswordFunction}
                closeForm={closeCopyForm}
                open={copyFormVisibility}
                decryptPassword={decryptPassword}
              />
              <HistoryForm
                history={historyForPassword}
                decryptPassword={decryptPassword}
                closeForm={closeHistoryForm}
                open={historyVisibility}
              />
              <Grid container>
                <Grid item xs={12}>
                  <PasswordFilterPanel
                    categories={categoriesList}
                    tags={tagsList}
                    selectedCategory={selectedCategory}
                    selectedTags={selectedTags}
                    handleChangeCategory={handleChangeCategory}
                    handleTagsChange={handleTagsChange}
                  />
                </Grid>
                <Grid item xs={12} className={classes.containerHeight}>
                  {passwordsListByIds.length !== 0 && passwordsListByIds.map(password => (
                    <PasswordListItem
                      expanded={expanded === password.id}
                      onExpand={handleExpand}
                      key={password.header}
                      deleteHandler={inBasket}
                      decryptPassword={decryptPassword}
                      editHandler={editHandler}
                      password={password}
                      showHistory={showHistory}
                      copyHandler={showCopyForm}
                      getPermissions={getPermissions}
                      passwordLoading={passwordLoading}
                      copying={copying}
                      getLink={fileOpen}
                      preview={previewOpen}
                    />
                  ))}
                </Grid>
              </Grid>
              {(getPermissions(['admin', 'write'])
                ? (
                  <div className={classes.tool}>
                    <SpeedDial
                      ariaLabel="Create object"
                      icon={<SpeedDialIcon />}
                      onClick={clickSpeedDial}
                      onClose={closeSpeedDial}
                      open={showSpeedDial}
                    >
                      <SpeedDialAction
                        icon={<VpnKeyIcon />}
                        tooltipTitle="password"
                        onClick={openCreatePasswordDialog}
                      />
                      <SpeedDialAction
                        icon={<InsertDriveFileIcon />}
                        tooltipTitle="file"
                        onClick={openCreateFileDialog}
                      />
                    </SpeedDial>
                  </div>
                ) : <div />
              )}
            </React.Fragment>
          ) : (
              <CreateItemComponent
                text="You have no password in this project yet."
                object="password"
                onCreate={(getPermissions(['admin', 'write']) ? () => openCreatePasswordDialog() : null)}
              />
            )}
        </React.Fragment>
      ) : (
          <CircularProgress color="primary" className={classes.progress} />)}
    </ContentWrap>
  )
}
