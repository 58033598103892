export const validate = (values) => {
  const errors = {}
  if (!values.email)
    errors.email = 'This field is required'
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = 'Invalid email address'

  if (!values.password)
    errors.password = 'This field is required'
  else if (values.password.length < 8)
    errors.paswword = 'Invalid password'

  if (!values.confirm_password)
    errors.confirm_password = 'This field is required'

  if (values.password !== values.confirm_password)
    errors.confirm_password = 'Password not equal'

  if (!values.name)
    errors.name = 'This field is required'

  return errors
}