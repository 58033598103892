import React from 'react'

import { Grid, Typography, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'

import { CompanySettingsForm } from './CompanySettingsForm'
import { getCompanySettings } from 'Actions/settings'

export const useStyles = makeStyles(theme => ({
  form_wrap_settings: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: { maxWidth: '380px' }
  }
}))

export const CompanySettings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const company = useSelector((state) => state.settings.company_settings)

  React.useEffect(() => {
    dispatch(getCompanySettings())
  }, [])

  const afterCompanyUpdate = (response) => {
    dispatch({ type: 'SET_COMPANY_SETTINGS', data: { ...response.data, id: company.id } })
    dispatch({ type: 'SET_SNACKBAR', message: 'Company settings were updated.' })
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item align="center" xs={12} sm={10} md={6}>
        <Paper className={classes.form_wrap_settings}>
          <Typography variant="h5" style={{ paddingBottom: '15px' }}>
            Company settings
          </Typography>
          <CompanySettingsForm company={company} afterSubmit={afterCompanyUpdate} />
        </Paper>
      </Grid>
    </Grid>
  )
}