import { TextField, FormHelperText, FormControl } from '@mui/material'

export const FieldForm = ({ input, multiline, label, rowsMax, meta: { touched, error } }) => {
  return (
    <FormControl fullWidth>
      <TextField
        label={label}
        multiline={multiline}
        maxRows={rowsMax}
        error={error && touched}
        margin="dense"
        {...input}
      />
      {(error && touched ? <FormHelperText error id="my-helper-text">{error}</FormHelperText>
        : <div />)}
    </FormControl>
  )
}