/* eslint-disable object-curly-newline */
/* eslint-disable array-element-newline */
import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  loadGroup, loadGroupUsers, deleteGroup, changeUserPermissionsGroups, addUserToGroup,
  loadGroupProjects, loadAvailableUsersForGroup, deleteUser, addGroup, setPending,
} from 'Actions/groups'

import { Helmet } from 'react-helmet'

import { AddVaultForm } from './components/AddVaultForm'
import { LeftColumn } from './components/LeftColumn'
import { RightColumn } from './components/RightColumn'

import { TwoColumnSettings } from 'Layouts/front/TwoColumnSettings'
import { ContentWrap } from 'Layouts/front/ContentWrap'

import { getPermissionsNameByValue } from 'Utils/PermissionsHelper'
import CryptoHelper from 'Utils/CryptoHelper'

let cryptoHelper = null

export const GroupSettings = (props) => {
  const dispatch = useDispatch()

  const {
    availableUsersForGroup, currentGroup, currentGroupUsers, currentGroupProjects, userPermissions
  } = useSelector((state) => state.groups)
  const { addUserToGroupPending, passPhrase } = useSelector((state) => state.app)

  const [appReady, setAppReady] = React.useState(true)
  const [userAddFormVisible, setUserAddFormVisible] = React.useState(false)

  const initCryptoHelper = async () => {
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', passPhrase)
    cryptoHelper = new CryptoHelper()
  }

  React.useEffect(() => {
    if (props.cryptoWorker)
      initCryptoHelper()
  }, [props.cryptoWorker])

  React.useEffect(async () => {
    try {
      await dispatch(loadGroup(props.match.params.id))
      await Promise.all([
        dispatch(loadAvailableUsersForGroup(props.match.params.id)),
        dispatch(loadGroupUsers(props.match.params.id)),
        dispatch(loadGroupProjects(props.match.params.id))
      ])
    }
    catch (error) {
      props.history.push('/not-found')
    }
  }, [])

  const getPermissions = (arrayPermissions) => {
    const userPermissionsNames = getPermissionsNameByValue(userPermissions)
    const flag = userPermissionsNames.some(userPerm => (
      arrayPermissions.some(perm => userPerm.indexOf(perm) !== -1)
    ))
    return flag
  }

  const getProjectSecretKey = async (projects, cryptoWorker) => {
    const encryptedSecretKeys = []
    const serverPublicKey = window.localStorage.getItem('server_public_key') || ''
    for await (const project of projects) {
      const decryptedSecretKey = await cryptoWorker.dispatch('DECRYPT_KEY', project.encrypted_secret_key.secret_key)
      encryptedSecretKeys.push({
        id_project: project.id,
        encrypted_secret_key: cryptoHelper.ecryptedSecretkey(serverPublicKey, decryptedSecretKey)
      })
    }
    return encryptedSecretKeys
  }

  const onAddUsers = async (userIds) => {
    dispatch(setPending(true))
    setUserAddFormVisible(false)
    const projects = await dispatch(loadGroupProjects(props.match.params.id))
    const encryptedSecretKeys = await getProjectSecretKey(projects, props.cryptoWorker)
    await Promise.all(userIds.map(id => dispatch(addUserToGroup(id, encryptedSecretKeys))))
    dispatch(setPending(false))
  }

  const openUserAddForm = () => setUserAddFormVisible(true)

  const handleDeleteUser = async (userId) => {
    await dispatch(deleteUser(userId))
    await dispatch(loadAvailableUsersForGroup(props.match.params.id))
  }

  return (
    <ContentWrap>
      <Helmet title={`${currentGroup.name} group settings`} />
      <AddVaultForm
        items={availableUsersForGroup}
        title="Add existing user"
        primaryKey="full_name"
        secondaryKey="email"
        open={userAddFormVisible}
        onClose={() => setUserAddFormVisible(false)}
        onSave={onAddUsers}
        appReady={appReady}
      />
      <TwoColumnSettings
        title="Group settings"
        leftColumn={(
          <LeftColumn
            group={currentGroup}
            addGroup={addGroup}
            deleteGroup={deleteGroup}
            getPermissions={getPermissions}
            history={props.history}
          />
        )}
        rightColumn={(
          <RightColumn
            addUserToGroupPending={addUserToGroupPending}
            group={currentGroup}
            users={currentGroupUsers}
            projects={currentGroupProjects}
            getPermissions={getPermissions}
            changeUserPermissionsProject={changeUserPermissionsGroups}
            openUserAddForm={openUserAddForm}
            deleteUser={handleDeleteUser}
          />
        )}
      />
    </ContentWrap>
  )
}
