import React from 'react'

import {
  InputLabel, MenuItem, FormControl, Select, Input, ListItemText, Checkbox,
} from '@mui/material'

export const PasswordFilterPanel = (props) => {
  return (
    <React.Fragment>
      <FormControl style={{ marginRight: 8, width: 120 }}>
        <InputLabel htmlFor="category-filter">Category</InputLabel>
        <Select
          value={props.selectedCategory === 0 ? '' : props.selectedCategory}
          onChange={props.handleChangeCategory}
          inputProps={{ id: 'category-filter' }}
        >
          <MenuItem value=""><i>None</i></MenuItem>
          {props.categories.map(
            category => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormControl style={{ margin: 8, width: 120 }}>
        <InputLabel htmlFor="tags-filter">Tags</InputLabel>
        <Select
          multiple
          value={props.selectedTags}
          onChange={props.handleTagsChange}
          input={<Input id="tags-filter" />}
          renderValue={selected => selected.join(', ')}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
                width: 250
              }
            }
          }}
        >
          {props.tags.map(tag => (
            <MenuItem key={tag.id} value={tag.name}>
              <Checkbox checked={props.selectedTags.indexOf(tag.name) > -1} />
              <ListItemText primary={tag.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  )
}
