import React from 'react'

import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import NavigationIcon from '@mui/icons-material/Navigation'

import { useDispatch } from 'react-redux'

import { EditInfoCard } from 'Components/front/EditInfoCard'
import { OwnerCard } from 'Components/front/OwnerCard'
import { ConfirmDialog } from 'Components/front/ConfirmDialog'

const useStyles = makeStyles({
  simple_button2: {
    width: '100%',
    margin_bottom: '10px'
  }
})

export const LeftColumn = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [projectEdit, setProjectEdit] = React.useState(false)
  const [confirmDeleting, setConfirmDeleting] = React.useState(false)

  const updateProject = (data) => {
    dispatch(props.addProject({ ...data, id: props.project.id }))
    setProjectEdit(false)
  }

  const openCloseEditInfo = () => setProjectEdit(!projectEdit)
  const deleteCloseProject = () => setConfirmDeleting(!confirmDeleting)

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <EditInfoCard
          info={props.project}
          changeInfo={projectEdit}
          onClickEdit={openCloseEditInfo}
          onClickCancel={openCloseEditInfo}
          onSubmit={updateProject}
          getPermissions={props.getPermissions}
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerCard owner={props.project.owner} />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.simple_button2}
          variant="contained"
          color="primary"
          onClick={() => props.history.push(`/projects/${props.project.id}`)}
        >
          <NavigationIcon />
            Open project
          </Button>
        <Button
          className={classes.simple_button2}
          style={{ marginTop: 8 }}
          variant="contained"
          color="inherit"
          onClick={() => props.history.push(`/log?object_model=project&object_id=${props.project.id}`)}
        >
          View log
          </Button>
        {(props.getPermissions(['admin'])
          ? (
            <React.Fragment>
              <ConfirmDialog
                text="The project will be moved to the basket, but if project is empty, it wil be deleted. Are you sure?"
                open={confirmDeleting}
                onConfirm={() => dispatch(props.deleteProject(props.project.id))}
                onCancel={deleteCloseProject}
              />
              <Button
                className={classes.simple_button2}
                style={{ marginTop: 8 }}
                variant="contained"
                color="secondary"
                onClick={deleteCloseProject}
              >
                <DeleteIcon className={classes.rightIcon} />
                  Delete Project
                </Button>
            </React.Fragment>
          ) : <div />
        )}
      </Grid>
    </Grid>
  )
}
