import React from 'react'

import { useDispatch } from 'react-redux'
import { activate, sendConfirmEmailAgain } from 'Actions/app'

import { Typography, Button } from '@mui/material'

import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

import { StartPage } from 'Layouts/front/StartPage'

export const Activate = (props) => {
  const dispatch = useDispatch()

  const params = queryString.parse(props.location.search)

  React.useEffect(() => {
    if (params.token)
      dispatch(activate(params.token))
  }, [params])

  return (
    <StartPage>
      <Helmet title="Activate" />
      <div style={{ display: 'flex', 'flex-direction': 'column', 'text-align': 'center' }}>
        <Typography variant="h5" component="h2">
          Your account is not activated. Check your email. We have sent an email to your address.
        </Typography>
        <Link to="/login">Login</Link>
        {(params.email
          ? (<Button onClick={() => dispatch(sendConfirmEmailAgain(params.email))}>
            Send the message
            again
          </Button>) : <div />)}
      </div>
    </StartPage>
  )
}
