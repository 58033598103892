import React from 'react'

import queryString from 'query-string'
import { Helmet } from 'react-helmet'

import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'

import { companyActivate } from 'Actions/app'
import { StartPage } from 'Layouts/front/StartPage'

export const CompanyActivate = (props) => {
  const dispatch = useDispatch()

  const companyActivateFlag = useSelector((state) => state.app.companyActivateFlag)

  const params = queryString.parse(props.location.search)

  React.useEffect(() => {
    if (params.token)
      dispatch(companyActivate(params.token))
  }, [params])

  let message = ''
  switch (companyActivateFlag) {
    case 1:
      message = 'Company account has been successfully activated!'
      break
    default:
      message = 'Company account is not activated. Check your email. We have sent an email to your address.'
      break
  }

  return (
    <StartPage>
      <Helmet title="CompanyActivate" />
      <div>
        <Typography variant="h5" component="h2">
          {message}
        </Typography>
      </div>
    </StartPage>
  )
}
