export const onFileSelect = (event, setFieldValue, setFieldError, values) => {
  setFieldError('attachments', null)
  const files = Array.from(event.target.files)
  const formFiles = values.attachments ? values.attachments : []

  if (files.length > 0)
    for (const file of files)
      if (file.size > 1024 * 1024 * 2) {
        setFieldError('attachments', 'Maximum size of file - 2 MB')
        return
      }
  setFieldValue('attachments', formFiles.concat(files))
}