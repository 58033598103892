/* eslint-disable array-element-newline */
import React from 'react'

import { Grid, Dimmer, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { loadPasswordsList } from 'Actions/passwords'
import { loadProjectList } from 'Actions/projects'

import { Helmet } from 'react-helmet'

import { DataTable } from 'Components/admin/DataTable'

const tableHeader = [
  {
    name: 'id',
    label: 'ID',
    collapsing: true,
    singleLine: true
  },
  { name: 'name', label: 'Name' },
  { name: 'project_name', label: 'Project Name' },
  { name: 'project_owner', label: 'Project Owner' }
]

export const AdminHome = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)

  const projectsList = useSelector((state) => state.projects.projectList)
  const passwordsList = useSelector((state) => state.passwords.passwordsList)

  React.useEffect(async () => {
    setLoading(true)
    const res = await dispatch(loadProjectList())
    res.map(async (project) => {
      if (passwordsList.length === 0) {
        dispatch(loadPasswordsList(project.id))
      }
      else {
        const passwordsIds = passwordsList.map((password) => password.project)
        !passwordsIds.includes(project.id) && dispatch(loadPasswordsList(project.id))
      }
    })
    setLoading(false)
  }, [])

  const collectInfoAboutPasswords = () => {
    passwordsList.map((password) =>
      projectsList.map((project) => {
        if (password.project_detail.id === project.id)
          password.project_detail = project
      }))

    return passwordsList.map(item => [
      item.project_detail.id,
      item.name,
      item.project_detail ? item.project_detail.name : 'null',
      item.project ? `${item.project_detail.owner.first_name} ${item.project_detail.owner.last_name}` : null
    ])
  }

  const handleRowClick = (data) => history.push(`/projects/${data[0]}`)

  return (
    <React.Fragment>
      <Helmet title="Admin Home" />
      <Grid container>
        <Grid.Row>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          )}
          <DataTable
            // after all add modal window (but it's not exactly :) )
            onRowClick={handleRowClick}
            header={tableHeader}
            data={collectInfoAboutPasswords()}
          />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}
