import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { loadProjectList, addProject, loadProject } from 'Actions/projects'

import {
  Card, CardActions, CardContent, Button, Typography, Grid, Dialog, DialogTitle, DialogContent,
  DialogActions,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Helmet } from 'react-helmet'

import { CreateItemComponent } from 'Components/front/CreateItemComponent'
import { ItemAddForm } from 'Components/front/ItemAddForm'
import { ContentWrap } from 'Layouts/front/ContentWrap'

const useStyles = makeStyles({
  root: { width: '100%', maxWidth: 'none' },
  containerHeight: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  createNewProjectButton: { marginBottom: '35px !important' }
})

export const ProjectsList = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const projects = useSelector((state) => state.projects.projectList)

  const [projectCreateDialog, setProjectCreateDialog] = React.useState(false)
  const [projectsListLength, setProjectsListLength] = React.useState(0)

  React.useEffect(() => {
    dispatch(loadProjectList())
  }, [])

  const onNewProject = async (data) => {
    await dispatch(addProject(data))
    setProjectCreateDialog(false)
  }

  React.useEffect(() => {
    if (projects.length !== 0)
      setProjectsListLength(projects.length)
    if (projectsListLength !== 0 && projectsListLength !== projects.length)
      dispatch(loadProjectList())
  }, [projects])

  const reverseProjectsList = (projects) => {
    return projects.reverse()
  }

  const handleHideCreateDialog = () => setProjectCreateDialog(false)

  const projectsList = React.useMemo(() => reverseProjectsList(projects), [projects])

  return (
    <ContentWrap className={classes.root}>
      <Helmet title="Projects" />
      <Dialog onClose={handleHideCreateDialog} aria-labelledby="form-dialog-title" open={projectCreateDialog}>
        <DialogTitle id="scroll-dialog-title">Add project</DialogTitle>
        <DialogContent>
          <ItemAddForm onSubmit={onNewProject} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" color="primary" form="my-form">
            CREATE
          </Button>
          <Button variant="contained" onClick={handleHideCreateDialog}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12} lg={12} className={classes.createNewProjectButton}>
        <Button
          size="small"
          variant="contained"
          onClick={() => setProjectCreateDialog(true)}
        >
          Create new project
        </Button>
      </Grid>
      {projects.length !== 0 ? (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" className={classes.containerHeight}>
          {projectsList.map(project => (
            <Grid key={project.id} item md={6} xs={12} sm={12} lg={3}>
              <Card className="projects__card">
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    {project.name}
                  </Typography>
                  {project.description !== '' ?
                    <Typography color="textSecondary">
                      {project.description}
                    </Typography> : <div style={{ padding: 10 }} />}
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => props.history.push(`/projects/${project.id}/`)}
                  >
                    Open
                    </Button>
                  <Button
                    size="small"
                    onClick={() => props.history.push(`/project_settings/${project.id}/`)}
                  >
                    Settings
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
          <CreateItemComponent
            text="You have no projects yet."
            object="project"
            onCreate={() => setProjectCreateDialog(true)}
          />
        )}
    </ContentWrap>
  )
}
