export class FRequest {
  constructor(config) {
    Object.assign(this, config)
  }

  get(url, params) {
    const reqUrl = new URL(url, this.baseUrl)
    reqUrl.search = new URLSearchParams(params)

    return fetch(reqUrl, {
      ...this.reqParams,
      method: 'GET'
    })
  }

  post(url, data) {
    const reqUrl = new URL(url, this.baseUrl)

    return fetch(reqUrl, {
      ...this.reqParams,
      headers: {
        ...this.reqParams.headers,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(data)
    })
  }

  put(url, data) {
    const reqUrl = new URL(url, this.baseUrl)

    return fetch(reqUrl, {
      ...this.reqParams,
      headers: {
        ...this.reqParams.headers,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(data)
    })
  }

  delete(url, params) {
    const reqUrl = new URL(url, this.baseUrl)
    reqUrl.search = new URLSearchParams(params)

    return fetch(reqUrl, {
      ...this.reqParams,
      method: 'DELETE'
    })
  }
}
