import React from 'react'

import {
  Grid, InputAdornment, Button, Tooltip, Typography, CircularProgress, IconButton,
} from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'

import CryptoJS from 'crypto-js'
import { withFormik } from 'formik'

import { Input } from '@ui/Input'
import { userLogin } from '../effects'

const formik = {
  mapPropsToValues: () => ({
    username: '',
    password: ''
  }),
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const data = Object.assign({}, values)
    const hash = CryptoJS.SHA256(data.password).toString(CryptoJS.enc.Hex)
    data.passPhrase = hash
    await userLogin(data, { props, setSubmitting, setErrors })
  }
}

export const LoginForm = withFormik(formik)(({
  handleBlur, handleChange, handleSubmit, isSubmitting, errors, values
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          <Input
            name="username"
            type="email"
            label="E-mail"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            error={errors.username}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Tooltip
            disableFocusListener
            placement="top"
            title={(<Typography color="inherit">Currently password recovery is not available.</Typography>)}
          >
            <Typography style={{ width: '40%' }} color="primary">Forgot a password?</Typography>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button disabled={isSubmitting} variant="contained" style={{ minWidth: 200 }} type="submit" color="primary">
            {isSubmitting ? <CircularProgress size={30} /> : 'SIGN IN'}
          </Button>
          {errors.non_field_errors && (<Typography color="secondary">{errors.non_field_errors}</Typography>)}
        </Grid>
      </Grid>
    </form>
  )
})
