import React from 'react'

import { DialogTitle, Dialog, Button, DialogContent, DialogActions } from '@mui/material'

import { PasswordDetail } from 'Components/front/PasswordDetail'

export const PasswordDetailDialog = (props) => {
  return (
    <Dialog onClose={props.onClose} maxWidth="xs" open={props.open} aria-labelledby="create-password-title">
      <DialogTitle id="create-password-title">Password details</DialogTitle>
      <DialogContent>
        <PasswordDetail
          password={props.password}
          decryptPassword={props.decryptPassword}
          getLink={props.getLink}
          preview={props.preview}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained" size="small">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
