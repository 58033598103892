import React from 'react'

import { useDispatch } from 'react-redux'

import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'

import { EditInfoCard } from 'Components/front/EditInfoCard'
import { OwnerCard } from 'Components/front/OwnerCard'
import { ConfirmDialog } from 'Components/front/ConfirmDialog'

const useStyles = makeStyles({
  simple_button2: {
    width: '100%',
    margin_bottom: '10px'
  }
})

export const LeftColumn = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [groupEdit, setGroupEdit] = React.useState(false)
  const [confirmDeleting, setConfirmDeleting] = React.useState(false)

  const openEditInfo = () => setGroupEdit(true)
  const closeEditInfo = () => setGroupEdit(false)
  const handleDeleteGroup = () => setConfirmDeleting(true)
  const cancelDeletion = () => setConfirmDeleting(false)

  const updateGroup = async (data) => {
    await dispatch(props.addGroup({ ...data, id: props.group.id }))
    closeEditInfo()
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <EditInfoCard
          info={props.group}
          changeInfo={groupEdit}
          onClickEdit={openEditInfo}
          onClickCancel={closeEditInfo}
          onSubmit={updateGroup}
          getPermissions={props.getPermissions}
        />
      </Grid>
      <Grid item xs={12}>
        <OwnerCard owner={props.group.owner} />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.simple_button2}
          style={{ marginTop: 8 }}
          variant="contained"
          color="inherit"
          onClick={() => props.history.push(`/log?object_model=group&object_id=${props.group.id}`)}
        >
          View log
        </Button>
        {(props.getPermissions(['admin'])
          ? (
            <React.Fragment>
              <ConfirmDialog
                open={confirmDeleting}
                onConfirm={() => dispatch(props.deleteGroup(props.group.id))}
                onCancel={() => cancelDeletion()}
              />
              <Button
                className={classes.simple_button2}
                style={{ marginTop: 8 }}
                variant="contained"
                color="secondary"
                onClick={handleDeleteGroup}
              >
                <DeleteIcon />
                Delete Group
              </Button>
            </React.Fragment>
          )
          : <div />)}
      </Grid>
    </Grid>
  )
}
