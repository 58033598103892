import { push } from 'connected-react-router'
import { setLoading, setSnackbar } from './app'
import { axios } from './axiosConfig'

import * as types from '../types'

export const filterLogs = (data, params) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'POST', url: 'logs/filter/', data, params })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.LOGS_FETCHED, data: response.data })
      })
  }
}


export const addProject = (project) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({
      method: project.id ? 'put' : 'post',
      url: project.id ? `projects/${project.id}/` : '/projects/',
      data: project
    })
      .then((response) => {
        if (project.id)
          dispatch({ type: types.PROJECT_UPDATED, data: response.data.project })
        else
          dispatch({ type: types.PROJECT_ADDED, data: response.data.project })
        dispatch(setLoading(false))
        dispatch(setSnackbar(project.id ? 'Project was updated' : 'Project was added'))
        return true
      })
  }
}

export const deleteProject = (id, admin) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'delete', url: `projects/${id}/move_to_brash/` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch(setSnackbar(response.status === 200 ? 'Project is moved to the basket' : 'Project is deleted'))
        !admin && dispatch(push('/projects'))
        dispatch({ type: types.PROJECT_DELETED, data: { id } })
      })
  }
}

export const loadProjectList = (params) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: '/projects/', params })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PROJECTS_FETCHED, data: response.data.projects })
        return response.data.projects
      })
  }
}

export const loadProject = (projectId, list) => {
  return async (dispatch) => {
    const response = await axios({ method: 'get', url: `projects/${projectId}/` })
    !list ? dispatch({ type: types.PROJECT_FETCHED, data: response.data })
      : dispatch({ type: types.GET_PROJECTS_WITH_KEYS, data: response.data })
    return response.data
  }
}

export const getWritableProjects = () => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({ method: 'get', url: `/projects/${projects.currentProject.id}/writable/` })
      .then((response) => {
        dispatch({ type: types.WRITABLE_PROJECTS_FETCHED, data: response.data.projects })
      })
  }
}

export const loadUsersList = (idProject) => {
  return dispatch => axios({ method: 'get', url: `/projects/${idProject}/users` })
    .then((response) => {
      dispatch({ type: types.USERS_OF_PROJECT_FETCHED, data: response.data.users })
    })
}

export const addSuperuserToProject = (id, projectId, encryptedServerKey) => {
  return (dispatch) => {
    return axios({
      method: 'put',
      url: `/projects/${projectId}/add_user/`,
      data: { id, encrypted_secret_key: encryptedServerKey }
    })
      .then((response) => {
        response.data && dispatch({ type: types.USER_ADDED_TO_PROJECT, data: response.data.user })
      })
  }
}

export const addUserToProject = (id, encryptedServerKey) => {
  return (dispatch, setState) => {
    const { projects } = setState()
    return axios({
      method: 'put',
      url: `/projects/${projects.currentProject.id}/add_user/`,
      data: { id, encrypted_secret_key: encryptedServerKey }
    })
      .then((response) => dispatch({ type: types.USER_ADDED_TO_PROJECT, data: response.data.user }))
  }
}

export const addGroupToProject = (id, encryptedServerKey, projectId) => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({
      method: 'put',
      url: `/projects/${projectId || projects.currentProject.id}/add_group/`,
      data: { id, encrypted_secret_key: encryptedServerKey }
    })
      .then((response) => {
        dispatch({
          type: projectId ? types.PROJECT_ADDED_TO_GROUP : types.GROUP_ADDED_TO_PROJECT,
          data: projectId
            ? projects.projectList.find(el => el.id === projectId)
            : response.data.group
        })
      })
  }
}

export const changeUserPermissionsProject = (permission, idUser) => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({
      method: 'put',
      url: `/projects/${projects.currentProject.id}/set_user_permissions/`,
      data: { permissions: permission, user: idUser }
    })
      .then((response) => {
        dispatch({ type: types.USER_DELETED_FROM_PROJECT, data: { id: idUser } })
        dispatch({ type: types.USER_ADDED_TO_PROJECT, data: response.data.user })
      })
  }
}

export const loadAvailableUsersForProject = (idProject) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `projects/${idProject}/available_users` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.AVAILABLE_USERS_FETCHED_FOR_PROJECT, data: response.data.users })
      })
  }
}

export const loadAvailableGroupsForProject = (idProject) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `projects/${idProject}/available_groups` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.AVAILABLE_GROUPS_FETCHED_FOR_PROJECT, data: response.data.groups })
      })
  }
}

export const loadGroupsProject = (idProject) => {
  return dispatch => axios({ method: 'get', url: `/projects/${idProject}/groups/` })
    .then((response) => {
      dispatch({ type: types.GROUPS_OF_PROJECT_FETCHED, data: response.data.groups })
    })
}

export const changeGroupPermissionsProject = (permission, idGroup) => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({
      method: 'put',
      url: `/projects/${projects.currentProject.id}/set_group_permissions/`,
      data: { permissions: permission, group: idGroup }
    })
      .then((response) => {
        dispatch({ type: types.GROUP_DELETED_FROM_PROJECT, data: { id: idGroup } })
        dispatch({ type: types.GROUP_ADDED_TO_PROJECT, data: response.data.group })
      })
  }
}

export const stateReset = () => {
  return (dispatch) => dispatch({ type: types.STATE_IS_RESET })
}

export const deleteUser = (user) => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({
      method: 'delete',
      url: `/projects/${projects.currentProject.id}/delete_user/`,
      data: { id: user.id }
    })
      .then(() => dispatch({ type: types.USER_DELETED_FROM_PROJECT, data: user }))
  }
}

export const deleteGroup = (group) => {
  return (dispatch, getState) => {
    const { projects } = getState()
    return axios({
      method: 'delete',
      url: `/projects/${projects.currentProject.id}/delete_group/`,
      data: { id: group.id }
    })
      .then(() => dispatch({ type: types.GROUP_DELETED_FROM_PROJECT, data: group }))
  }
}
