import React from 'react'

import {
  Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography, Button, Divider, CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { PasswordDetail } from 'Components/front/PasswordDetail'
import { ConfirmDialog } from 'Components/front/ConfirmDialog'

export const PasswordListItem = (props) => {
  const [confirmDeleting, setConfirmDeleting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const dialogHandler = () => setConfirmDeleting(!confirmDeleting)

  React.useEffect(() => {
    if (props.password)
      setLoading(false)
  }, [props.password])

  const handleExpand = React.useCallback(() => {
    props.onExpand(props.password.id)
  }, [])

  return (
    <Accordion expanded={props.expanded} onChange={handleExpand} disabled={props.passwordLoading}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {props.password.name}
          {' '}
        </Typography>
        {loading && <CircularProgress size={24} />}
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {(props.password ? (
          <PasswordDetail
            decryptPassword={props.decryptPassword}
            password={props.password}
            getLink={props.getLink}
            preview={props.preview}
            responsive
          />
        ) : <div />
        )}
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        {props.copying && <CircularProgress size={24} />}
        <Button onClick={() => props.showHistory(props.password)} size="small" color="inherit">
          History
          </Button>
        {
          (props.getPermissions(['admin', 'write'])
            ? (
              <div>
                <ConfirmDialog
                  text="The password will be moved to the basket. Are you sure?"
                  open={confirmDeleting}
                  onConfirm={() => props.deleteHandler(props.password.id)}
                  onCancel={dialogHandler}
                />
                <Button onClick={() => props.copyHandler(props.password)} disabled={props.copying} size="small" color="inherit">
                  Copy
                  </Button>
                <Button onClick={dialogHandler} size="small" color="secondary">
                  Delete
                  </Button>
                <Button onClick={() => props.editHandler(props.password)} size="small" color="primary">
                  Edit
                  </Button>
              </div>
            ) : <div />)
        }
      </AccordionActions>
    </Accordion>
  )
}
