import React from 'react'

import { Typography, Button, Grid } from '@mui/material'

export const CreateItemComponent = (props) => {
  return (
    <Grid container>
      <Grid
        container
        style={{ height: 240 }}
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            {props.text}
          </Typography>
        </Grid>
        <Grid item>
          {(props.onCreate
            ? (
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                onClick={props.onCreate}
              >
                Create new
                {' '}
                {props.object}
              </Button>
            )
            : <div />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
