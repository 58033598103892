import { authApi } from './api'

export const companyRegister = async (data, { props, setErrors, setSubmitting }) => {
  try {
    const registerResult = await authApi.companyRegister(data)
    if (registerResult.status === 200 || registerResult.status === 201) {
      props.afterSubmit()
    }
    else {
      const msg = registerResult.statusText
      setErrors({ non_field_errors: msg })
    }
  }
  catch (error) {
    const msg = error.response && error.response.statusText
    setErrors({ non_field_errors: msg })
  }
  setSubmitting(false)
}
