import React from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { Grid, Button, Dimmer, Loader, Icon } from 'semantic-ui-react'

import { Helmet } from 'react-helmet'

import {
  loadProjectList, addProject, deleteUser, deleteGroup, loadProject, deleteProject,
} from 'Actions/projects'
import { loadUserList } from 'Actions/users'

import { DataTable } from 'Components/admin/DataTable'
import { EditProjectDialog } from './EditProjectDialog'
import { ProjectDetailsDialog } from './ProjectDetailsDialog'

const tableHeader = [
  {
    name: 'id',
    label: 'ID',
    collapsing: true,
    singleLine: true
  },
  { name: 'name', label: 'Name' },
  { name: 'description', label: 'Description' },
  {
    name: 'actions',
    label: 'Actions',
    collapsing: true,
    singleLine: true
  }
]

export const AdminProjects = () => {
  const dispatch = useDispatch()

  const { projectList, currentProject } = useSelector((state) => state.projects)
  const usersList = useSelector((state) => state.users.userList)

  const [loading, setLoading] = React.useState(false)
  const [showProjectEditDialog, setShowProjectEditDialog] = React.useState(false)
  const [showProjectDetailsDialog, setShowProjectDetailsDialog] = React.useState(false)
  const [workedProject, setWorkedProject] = React.useState(null)

  React.useEffect(async () => {
    if (projectList.length === 0) {
      setLoading(true)
      await dispatch(loadProjectList())
      setLoading(false)
    }
    dispatch(loadUserList())
  }, [])

  const onEditProject = async (project) => {
    await dispatch(addProject(project))
    setShowProjectEditDialog(false)
  }

  const onDeleteUserFromProject = (user) => dispatch(deleteUser(user))
  const onDeleteGroupFromProject = (group) => dispatch(deleteGroup(group))

  const showDetailsDialog = async (data) => {
    setLoading(true)
    await dispatch(loadProject(data[0]))
    setLoading(false)
    setShowProjectDetailsDialog(true)
  }

  const showEditDialog = (project, event) => {
    event.stopPropagation()
    setShowProjectEditDialog(true)
    setWorkedProject(project)
  }

  const handleDeleteProject = async (project, event) => {
    event.stopPropagation()
    setLoading(true)
    await deleteProject(project.id, true)
    setLoading(false)
  }

  const data = projectList && projectList.map(item => [
    item.id,
    item.name,
    item.description,
    (
      <React.Fragment key={item.id}>
        <Button onClick={event => showEditDialog(item, event)} size="mini" default icon>
          <Icon name="edit" />
        </Button>
        <Button onClick={event => handleDeleteProject(item, event)} size="mini" color="red" icon>
          <Icon name="delete" />
        </Button>
      </React.Fragment>
    )
  ])

  return (
    <React.Fragment>
      <Helmet title="Admin Projects" />
      <ProjectDetailsDialog
        open={showProjectDetailsDialog}
        project={currentProject}
        usersList={usersList}
        onClose={() => setShowProjectDetailsDialog(false)}
        onDeleteUser={onDeleteUserFromProject}
        onDeleteGroup={onDeleteGroupFromProject}
      />
      <EditProjectDialog
        open={showProjectEditDialog}
        onClose={() => setShowProjectEditDialog(false)}
        onSubmit={onEditProject}
        initialValues={workedProject}
      />
      <Grid container>
        <Grid.Row>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>{'common.loading'}</Loader>
            </Dimmer>
          )}
          <DataTable onRowClick={showDetailsDialog} header={tableHeader} data={data} />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}

