import React from 'react'

import {
  Grid, Typography, Button, Avatar, Checkbox, FormControlLabel, CircularProgress,
} from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import { setIsSuperuser } from 'Actions/users'
import { loadProject, addSuperuserToProject, loadProjectList } from 'Actions/projects'
import { addSuperuserToGroup } from 'Actions/groups'

import md5 from 'md5'

import { InfoList } from './InfoList'

import CryptoHelper from 'Utils/CryptoHelper'

export const UserInfoView = (props) => {
  const dispatch = useDispatch()

  const userIsSuperuser = useSelector((state) => state.users.userIsSuperuser)
  const { projectList, projectsListWithKeys, currentUserProjects } = useSelector((state) => state.projects)
  const { groupList } = useSelector((state) => state.groups)
  const { user, responseInfo } = useSelector((state) => state.app)

  const [reloadTrigger, setReloadTrigger] = React.useState(false)

  const shouldFetchProjectListOrNot = user.is_superuser ? true : currentUserProjects.length !== 0

  React.useEffect(() => {
    if (projectList.length === 0 && shouldFetchProjectListOrNot) {
      dispatch(loadProjectList())
      props.setLoading(false)
    }
    else { props.setLoading(false) }

    if (projectList.length !== 0 && projectsListWithKeys.length === 0)
      projectList.map((project) => dispatch(loadProject(project.id, true)))
  }, [projectList])

  const superuserHandler = async (event) => {
    const serverPublicKey = window.localStorage.getItem('server_public_key') || ''

    if (projectsListWithKeys.length > 0 && projectsListWithKeys.length === projectList.length) {
      props.cryptoWorker.postMessage({ type: 'GENERATE_RSA_KEY', payload: props.hash })
      await Promise.all(projectsListWithKeys.map((projectWithKey) => {
        props.cryptoWorker.once('initialized', () => {
          props.cryptoWorker.postMessage({
            type: 'DECRYPT_KEY',
            payload: projectWithKey.project.encrypted_secret_key.secret_key
          })

          // Im not shure about this. Leave it for future:
          // projectWithKey.project.encrypted_secret_key.secret_key
          groupList.map(async (group) => await dispatch(addSuperuserToGroup(Number(props.userById.id), group.id, [])))

          props.cryptoWorker.once('decrypted_key', async (secretKey) => {
            const cryptoHelper = new CryptoHelper(secretKey)
            const encryptedServerKey = cryptoHelper.ecryptedSecretkey(serverPublicKey)
            await dispatch(addSuperuserToProject(Number(props.userById.id), projectWithKey.project.id, encryptedServerKey))
          })
        })
      }))
    }

    dispatch(setIsSuperuser(event.target.checked, props.userById.id))
    setReloadTrigger(true)
  }

  React.useEffect(() => {
    if (reloadTrigger) {
      const maxIdOfCurrentProjects = projectsListWithKeys[projectsListWithKeys.length - 1].project.id
      const idInUrl = responseInfo.config.url.match(/(\d+)/) !== null ? Number(responseInfo.config.url.match(/(\d+)/)[0]) : 0
      if (maxIdOfCurrentProjects === idInUrl && responseInfo.status === 200)
        window.location.reload(false)
    }
  }, [responseInfo, reloadTrigger])

  const onLogClick = () => props.history.push({
    pathname: '/log',
    search: `?actor=${props.userById.id}`
  })

  let avatarUrl
  let lastLoginTime
  if (props.userById && props.userById.email) {
    avatarUrl = `https://www.gravatar.com/avatar/${md5(props.userById.email)}`
    lastLoginTime = props.userById.last_login ? new Date(Date.parse(props.userById.last_login)).toLocaleString() : ''
  }

  return (
    <React.Fragment>
      {reloadTrigger ?
        <div style={{
          width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <CircularProgress />
        </div> :
        <Grid container spacing={8}>
          <Grid item xs={12} md={4} lg={3}>
            <Grid alignItems="center" container>
              <Grid item xs={6} md={12}>
                <Grid container style={{ width: '100%', justifyContent: 'space-between' }}>
                  <Grid item>
                    <Avatar alt="avatar" src={avatarUrl} style={{ width: 60, height: 60 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      {props.userById.first_name}
                    </Typography>
                    <Typography variant="subtitle1">
                      {props.userById.last_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={12} style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                {user.is_superuser ? <FormControlLabel
                  labelPlacement="start"
                  style={{ marginRight: '0' }}
                  control={
                    <Checkbox
                      checked={userIsSuperuser}
                      onChange={superuserHandler}
                      disabled={userIsSuperuser}
                      style={{ padding: '0 0 0 9px' }}
                    />}
                  label="Superuser"
                /> : null}
              </Grid>
              <Grid item xs={6} md={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button className="left-column-btn" disabled fullWidth title="last login">
                      {lastLoginTime}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={onLogClick}
                      className="left-column-btn"
                      variant="outlined"
                      title="view log"
                    >
                      View Log
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <InfoList headerTitle="Groups" items={props.groups} nameKey="name" />
              </Grid>
              <Grid item>
                <InfoList headerTitle="Projects" items={props.projects} nameKey="name" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}