import React from 'react'

import { Field, Form } from 'react-final-form'

import { FieldForm } from './components/FieldForm'

const validate = (values) => {
  const errors = {}
  if (!values.name)
    errors.name = 'This field is required'
  return errors
}

export const ItemAddForm = (props) => {
  return (
    <Form validate={values => validate(values)} onSubmit={props.onSubmit} render=
      {({ handleSubmit }) => (<form id="my-form" onSubmit={handleSubmit}>
        <Field
          fullWidth
          name="name"
          component={FieldForm}
          type="text"
          label="Name"
          placeholder="name"
        />
        <Field
          fullWidth
          name="description"
          multiline
          rowsMax={4}
          component={FieldForm}
          type="text"
          label="Description"
          placeholder="description"
        />
      </form>)} />
  )
}
