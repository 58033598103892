import React from 'react'

import { Grid } from '@mui/material'

import { InfoList } from 'Components/front/InfoList'

export const RightColumn = (props) => {
  const isAdmin = !props.getPermissions(['admin'])

  return (
    <Grid direction="column" spacing={8} container>
      <Grid item>
        <InfoList
          group
          addUserToGroupPending={props.addUserToGroupPending}
          headerTitle="Users"
          items={props.users}
          nameKey="email"
          descriptionKey="full_name"
          permissions={isAdmin}
          changePermissions={props.changeProject}
          addButtonClick={isAdmin ? props.openUserAddForm : null}
          chipKey="permissions"
          deleteItem={props.deleteUser}
        />
      </Grid>
      <Grid item>
        <InfoList
          headerTitle="Projects"
          items={props.projects}
          nameKey="name"
          descriptionKey="description"
        />
      </Grid>
    </Grid>
  )
}
