/* eslint-disable no-param-reassign */
import CryptoJS from 'crypto-js'
import RSA from 'seededrsa'
import { setLoading, setSnackbar, updateMasterPassword } from './app'
import { axios } from './axiosConfig'

import * as types from '../types'

export const loadPasswordsList = (idProject, forProject) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    dispatch({ type: types.CURRENT_PASSWORD_RESET })
    return axios({ method: 'get', url: `/projects/${idProject}/passwords/` })
      .then((response) => {
        dispatch(setLoading(false))
        if (response.data.passwords.length !== 0)
          forProject ? dispatch({ type: types.PASSWORDS_FETCHED_FOR_PROJECT_DETAILS, data: response.data.passwords })
            : dispatch({ type: types.PASSWORDS_FETCHED, data: response.data.passwords })
      })
  }
}

export const loadDeletedPasswordsList = () => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const response = await axios({ method: 'get', url: 'passwords/get_deleted_passwords/' })
    dispatch(setLoading(false))
    dispatch({ type: types.DELETED_PASSWORDS_LIST_FETCHED, data: response.data })
  }
}

export const loadUsersList = (projectId) => {
  return dispatch => axios({ method: 'get', url: `/projects/${projectId}/users` })
    .then((response) => {
      dispatch({ type: types.USERS_FETCHED, data: response.data.users })
    })
}

export const addFile = (file) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    const { projects } = getState()
    const projectId = file.project || projects.currentProject.id
    const data = {
      ...file,
      type: 2,
      project: projectId,
      encrypted_passwords: { password: '' }
    }
    return axios({ method: 'post', url: 'passwords/', data })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PASSWORD_ADDED, data: response.data.password })
        dispatch(setSnackbar('File was added'))
      })
  }
}

export const updateFile = (file) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    const { projects } = getState()
    const projectId = file.project || projects.currentProject.id
    const data = {
      ...file,
      type: 2,
      project: projectId,
      encrypted_passwords: { password: '' }
    }
    return axios({ method: 'put', url: `passwords/${data.id}/`, data })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PASSWORD_DELETED, data: { id: data.id } })
        dispatch({ type: types.PASSWORD_ADDED, data: response.data.password })
        dispatch(setSnackbar(response.data.error === 5 ? 'File was updated but instance not header' : 'File was updated'))
        return response.data.error ? response.data.error : 0
      })
  }
}

export const addAttachment = (projectId, attachmentId) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'put', url: `passwords/${projectId}/add_attachment/`, data: { id: attachmentId } })
      .then((response) => {
        dispatch(setLoading(true))
        return response
      })
  }
}

export const addPassword = (password) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    const { projects } = getState()
    const projectId = password.project || projects.currentProject.id
    const data = { ...password, project: projectId }
    return axios({ method: 'post', url: 'passwords/', data })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PASSWORD_ADDED, data: response.data.password })
        dispatch(setSnackbar('Password was added'))
      })
  }
}

export const updatePassword = (password) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    const { projects } = getState()
    const projectId = password.project || projects.currentProject.id
    const data = {
      ...password,
      project: projectId,
      encrypted_passwords: password
    }
    return axios({ method: 'put', url: `passwords/${data.id}/`, data })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PASSWORD_DELETED, data: { id: data.id } })
        dispatch({ type: types.PASSWORD_ADDED, data: response.data.password })
        dispatch(setSnackbar(response.data.error === 5 ? 'Password was updated but instance not header' : 'Password was updated'))
        return response.data.error ? response.data.error : 0
      })
  }
}

export const loadCategoriesList = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'categories/' })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.CATEGORIES_FETCHED, data: response.data.categories })
      })
  }
}

export const restorePassword = (idPassword) => {
  return async (dispatch) => {
    await axios({ method: 'put', url: `passwords/${idPassword}/restore/` })
    dispatch({ type: types.PASSWORD_RESTORED, data: { id: idPassword } })
    dispatch(setSnackbar('Password restored successfully!'))
  }
}

export const deletePassword = (idPassword) => {
  return dispatch => axios({ method: 'delete', url: `passwords/${idPassword}/` })
    .then(() => {
      dispatch({ type: types.PASSWORD_RESTORED, data: { id: idPassword } })
      dispatch(setSnackbar('Password deleted successfully!'))
    })
}

export const inBasket = (idPassword) => {
  return dispatch => axios({ method: 'delete', url: `passwords/${idPassword}/put_in_basket/` })
    .then(() => {
      dispatch({ type: types.PASSWORD_DELETED, data: { id: idPassword } })
      dispatch(setSnackbar('Password moved to basket!'))
    })
}

export const loadTags = (idProject) => {
  return dispatch => axios({ method: 'get', url: `/projects/${idProject}/tags/` })
    .then((response) => dispatch({ type: types.TAGS_FETCHED, data: response.data.tags }))
}

export const loadHistory = (idProject) => {
  return dispatch => axios({ method: 'get', url: `/passwords/${idProject}/history/` })
    .then((response) => dispatch({ type: types.HISTORY_FETCHED, data: response.data.history }))
}

export const blockCopying = (data) => {
  return (dispatch) => dispatch({ type: types.SET_BLOCK_COPYING, data })
}

export const copyPassword = (idProject, idPassword, encryptedPassword) => {
  return dispatch => axios({
    method: 'put',
    url: `/passwords/${idPassword}/copy_password/`,
    data: { project: idProject, encrypted_password: encryptedPassword }
  })
    .then(() => dispatch(setSnackbar('Password copied!')))
}


export const movePassword = (idProject, idPassword, encryptedPassword) => {
  return dispatch => axios({
    method: 'put',
    url: `/passwords/${idPassword}/move_password/`,
    data: { project: idProject, encrypted_password: encryptedPassword }
  })
    .then(() => {
      dispatch({ type: types.PASSWORD_DELETED, data: { id: idPassword } })
      dispatch(setSnackbar('Password moved!'))
    })
}

export const changeMasterPassword = (
  newMasterPassword, cryptoWorker, cryptoHelper, reserveMasterHash, setSubmitting
) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const newMasterHash = CryptoJS.SHA256(newMasterPassword).toString(CryptoJS.enc.Hex)
    const rsa = new RSA(newMasterHash)
    await rsa.generateNew(1024, '03')
    const newPublicKey = rsa.n.toString()
    const preChangeResponse = await axios({
      method: 'post',
      url: '/passwords/update_master_password/',
      data: { public_key: newPublicKey, password: newMasterPassword }
    })
    if (preChangeResponse.status !== 200) {
      setSubmitting(false)
      return
    }
    const isPasswordsEmpty = preChangeResponse.data.old_passwords.length === 0
    const isProjectSecretKeysEmpty = preChangeResponse.data.project_secret_keys.length === 0
    let encryptedPasswords = []
    let newProjectSecretKeys = []
    if (!isPasswordsEmpty && !isProjectSecretKeysEmpty) {
      const decryptedPasswords = await Promise.all(preChangeResponse.data.old_passwords
        .map(async (passwordInfo) => {
          const encryptedInfo = { password: passwordInfo.encrypted_password }
          const decryptedProjectKey = await cryptoWorker.dispatch('DECRYPT_KEY', passwordInfo.project_secret_key)
          const decryptedPasswordObject = cryptoHelper.decryptPassword(encryptedInfo, decryptedProjectKey)
          return {
            password: decryptedPasswordObject.password,
            projectId: passwordInfo.project_id,
            id: passwordInfo.id
          }
        }))
      if (!decryptedPasswords) {
        setSubmitting(false)
        return
      }
      await cryptoWorker.dispatch('CHANGE_KEY', newMasterHash)
      // Adding encrypted with server public key new secret project keys
      const newDecryptedProjectKeys = new Map()
      const serverPublicKey = window.localStorage.getItem('server_public_key') || ''
      newProjectSecretKeys = await Promise.all(preChangeResponse.data.project_secret_keys
        .map(async (secretKeyObject, index) => {
          const { secret_key: newEncryptedProjectKey, project_id: projectId } = secretKeyObject
          const newDecryptedProjectKey = await cryptoWorker.dispatch('DECRYPT_KEY', newEncryptedProjectKey)
          newDecryptedProjectKeys.set(projectId, newDecryptedProjectKey)
          const encryptedServerKey = cryptoHelper.ecryptedSecretkey(serverPublicKey, newDecryptedProjectKey)
          return { ...secretKeyObject, encrypted_secret_key: encryptedServerKey }
        }))
      encryptedPasswords = decryptedPasswords.map((passwordInfo, index) => {
        const { password, projectId } = passwordInfo
        const newDecryptedProjectKey = newDecryptedProjectKeys.get(projectId)
        return {
          encrypted_password: cryptoHelper.encryptPassword(password, newDecryptedProjectKey),
          id: passwordInfo.id
        }
      })
      if (!encryptedPasswords) {
        cryptoWorker.dispatch('CHANGE_KEY', reserveMasterHash)
        setSubmitting(false)
        return
      }
    }
    const resultResponse = await axios({
      method: 'patch',
      url: '/passwords/patch_passwords/',
      data: {
        encrypted_passwords: encryptedPasswords,
        public_key: newPublicKey,
        project_secret_keys: newProjectSecretKeys,
        password: preChangeResponse.data.password, // new encrypted master password
        passPhrase: newMasterHash
      }
    })
    dispatch(setLoading(false))
    if (resultResponse.status === 200) {
      dispatch(updateMasterPassword(newMasterHash, newPublicKey))
      dispatch(setSnackbar('Master password was successfully changed'))
      setSubmitting(false)
    }
    else {
      cryptoWorker.dispatch('CHANGE_KEY', reserveMasterHash)
      dispatch(setSnackbar('Something goes wrong :('))
      setSubmitting(false)
    }
  }
}
