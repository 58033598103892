import React from 'react'

import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'
import { loadDeletedPasswordsList, restorePassword, deletePassword } from 'Actions/passwords'

import { Helmet } from 'react-helmet'

import { PasswordsList } from 'Components/front/PasswordsList'
import { CreateItemComponent } from 'Components/front/CreateItemComponent'
import { ContentWrap } from 'Layouts/front/ContentWrap'

const useStyles = makeStyles({ container: { width: '100%', maxWidth: 'none' } })

export const Basket = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const deletedPasswordsList = useSelector((state) => state.passwords.deletedPasswordsList)
  const hash = useSelector((state) => state.app.passPhrase)

  const [appReady, setAppReady] = React.useState(false)

  React.useEffect(async () => {
    await dispatch(loadDeletedPasswordsList())
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', hash)
    setAppReady(true)
  }, [])

  const performAction = async (key, password) => {
    switch (key) {
      case 'restore':
        await dispatch(restorePassword(password.id))
        break
      case 'delete':
        await dispatch(deletePassword(password.id))
        break
      default:
        break
    }
  }

  return (
    <ContentWrap className={classes.container}>
      <Helmet title="Basket" />
      {!appReady && <CircularProgress />}
      {appReady && deletedPasswordsList.passwords.length !== 0
        ? (
          <PasswordsList
            group
            cryptoWorker={props.cryptoWorker}
            rawPassword={deletedPasswordsList}
            performAction={performAction}
            actions={[
              // eslint-disable-next-line array-element-newline
              { label: 'Restore', key: 'restore' },
              { confirm: true, label: 'Delete from basket', key: 'delete', color: 'secondary' }
            ]}
          />
        ) : (
          <CreateItemComponent text="Basket is empty" object="password" />
        )}
    </ContentWrap>
  )
}
