import mime from 'mime-types'

export default (fileName, short) => {
  const mimeType = mime.lookup(fileName)

  switch (mimeType) {
    case 'audio/basic':
    case 'audio/x-midi':
    case 'audio/mpeg':
    case 'audio/vorbis':
    case 'application/ogg':
    case 'audio/wav':
    case 'audio/x-wav':
    case 'audio/x-pn-realaudio':
    case 'audio/vnd.rn-realaudio':
      return short ? 'audio' : 'Audio file'

    case 'video/msvideo':
    case 'video/avi':
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/quicktime':
      return short ? 'video' : 'Video file'

    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/tiff':
    case 'image/svg+xml':
      return short ? 'image' : 'Image file'

    case 'text/plain':
      return short ? 'text' : 'Plain text file'

    case 'text/css':
      return short ? 'css' : 'Style sheets file'

    case 'text/html':
      return short ? 'html' : 'HTML file'

    case 'application/java-archive':
      return short ? 'java' : 'Java file'

    case 'application/x-javascript':
      return short ? 'js' : 'JavaScript file'

    case 'application/msword':
      return short ? 'msword' : 'MS Word document'

    case 'application/octet-stream':
      return short ? 'bin' : 'Binary file'

    case 'application/pdf':
      return short ? 'pdf' : 'PDF document'

    case 'application/x-perl':
      return short ? 'perl' : 'Perl file'

    case 'application/x-bzip2':
    case 'application/x-gzip':
    case 'application/x-tar':
    case 'application/zip':
    case 'application/x-compressed-zip':
      return short ? 'archive' : 'Archive file'

    default:
      return short ? 'unknown' : 'Unknown file type'
  }
}
