import React from 'react'

import {
  Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemSecondaryAction,
  ListItemText, Checkbox, DialogTitle,
} from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  dialogTitle: { borderBottom: '1px solid' },
  text: { textOverflow: 'clip' }
})

export const AddVaultForm = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [checked, setChecked] = React.useState([])

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1)
      newChecked.push(value)
    else
      newChecked.splice(currentIndex, 1)
    setChecked(newChecked)
  }

  return (
    <div>
      <Dialog onClose={props.onClose} fullScreen={fullScreen} open={props.open} maxWidth="sm">
        <DialogTitle className={classes.dialogTitle}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <List>
            {props.items.map(value => (
              <ListItem
                key={value.id}
                role={undefined}
                button
                onClick={() => handleToggle(value.id)}
              >
                <ListItemText
                  className={classes.text}
                  primary={value[props.primaryKey]}
                  secondary={value[props.secondaryKey]}
                  secondaryTypographyProps={{ noWrap: true }}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={checked.indexOf(value.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    onChange={() => handleToggle(value.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onSave(checked)
              setChecked([])
            }}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={props.onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
