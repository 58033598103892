/* eslint-disable no-underscore-dangle */
import * as types from '../types'

import deleteItem from './commonFunctions'

const initialState = {
  projectList: [],
  projectsListWithKeys: [],
  currentProject: {
    name: 'Project_1',
    description: 'info',
    users: [],
    groups: [],
    owner: { email: 'test', last_name: 'test', first_name: 'test' }
  },
  logs: [],
  logsCount: 0,
  writableProjects: [],
  currentUserProjects: [],
  usersProjectList: [],
  groupsProjectList: [],
  availableUsersForProject: [],
  currentGroupProjects: [],
  availableGroupsForProject: [],
  userPermissions: [{ name: 'perm' }]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECTS_WITH_KEYS:
      return { ...state, projectsListWithKeys: [...state.projectsListWithKeys, action.data] }
    case types.LOGS_FETCHED:
      return { ...state, logsCount: action.data.count, logs: action.data.results }
    case types.PROJECT_FETCHED:
      return { ...state, currentProject: action.data.project, userPermissions: action.data.permissions }
    case types.PROJECT_ADDED:
      return { ...state, currentProject: action.data, projectList: [...state.projectList, action.data] }
    case types.PROJECTS_FETCHED:
      return { ...state, projectList: action.data }
    case types.PROJECT_UPDATED: {
      const updatedClientsList = state.projectList.slice()
      for (let i = 0; i < updatedClientsList.length; i += 1)
        if (updatedClientsList[i].id === action.data.id) {
          updatedClientsList[i] = action.data
          break
        }
      return { ...state, currentProject: action.data, projectList: updatedClientsList }
    }
    case types.PROJECT_DELETED:
      return { ...state, projectList: deleteItem(action.data, state.projectList) }
    case types.WRITABLE_PROJECTS_FETCHED:
      return { ...state, writableProjects: action.data }
    case types.GROUP_ADDED_TO_PROJECT:
      return {
        ...state,
        groupsProjectList: [...state.groupsProjectList, action.data],
        availableGroupsForProject: deleteItem(action.data, state.availableGroupsForProject)
      }
    case types.USER_PROJECTS_FETCHED:
      return { ...state, currentUserProjects: action.data }
    case types.USERS_OF_PROJECT_FETCHED:
      return { ...state, usersProjectList: action.data }
    case types.USER_ADDED_TO_PROJECT:
      return {
        ...state,
        usersProjectList: [...state.usersProjectList, action.data],
        availableUsersForProject: deleteItem(action.data, state.availableUsersForProject)
      }
    case types.USER_DELETED_FROM_PROJECT:
      return { ...state, usersProjectList: deleteItem(action.data, state.usersProjectList) }
    case types.AVAILABLE_USERS_FETCHED_FOR_PROJECT:
      return { ...state, availableUsersForProject: action.data }
    case types.PROJECT_ADDED_TO_GROUP:
      return { ...state, currentGroupProjects: [...state.currentGroupProjects, action.data] }
    case types.GROUPS_OF_PROJECT_FETCHED:
      return { ...state, groupsProjectList: action.data }
    case types.GROUP_DELETED_FROM_PROJECT:
      return { ...state, groupsProjectList: deleteItem(action.data, state.groupsProjectList) }
    case types.AVAILABLE_GROUPS_FETCHED_FOR_PROJECT:
      return { ...state, availableGroupsForProject: action.data }
    case types.STATE_IS_RESET:
      return { ...state, usersProjectList: [], groupsProjectList: [], currentProject: initialState.currentProject }
    default:
      return state
  }
}
