import React from 'react'

import { makeStyles } from '@mui/styles'
import { Paper, InputBase, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const useStyles = makeStyles({
  inputRoot: {
    padding: '2px 4px',
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: { padding: 10 }
})

export const SearchField = (props) => {
  const classes = useStyles()

  return (
    <Paper className={classes.inputRoot} elevation={1}>
      <InputBase
        value={props.value}
        onChange={props.onChange}
        className={classes.input}
        placeholder="Start typing password name..."
        autoFocus={false}
      />
      <IconButton className={classes.iconButton} aria-label="Search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
