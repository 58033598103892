import React from 'react'

import {
  Drawer, List, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, useTheme, useMediaQuery,
} from '@mui/material'
import {
  ChevronLeft, Person, Group, Work, Home, Delete, FormatListBulleted,
} from "@mui/icons-material"
import { makeStyles } from '@mui/styles'

import { useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px'
  },
  menuLink: { textDecoration: 'none' }
})

const menuItems = [
  {
    name: 'Home',
    link: '/home',
    icon: <Home />
  },
  {
    name: 'Users',
    link: '/users',
    icon: <Person />
  },
  {
    name: 'Projects',
    link: '/projects',
    icon: <Work />
  },
  {
    name: 'Groups',
    link: '/groups',
    icon: <Group />
  },
  {
    name: 'Log',
    link: '/log',
    icon: <FormatListBulleted />
  }
]

const useIsWidthUp = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const Sidebar = (props) => {
  const classes = useStyles()
  const isSmUp = useIsWidthUp("sm")

  const user = useSelector((state) => state.app.user)

  const hasPermission = (item) => {
    return !item.forSuperusersOnly || (user && user.is_superuser && item.forSuperusersOnly)
  }

  return (
    <Drawer
      variant={isSmUp ? 'persistent' : 'temporary'}
      sx={{
        '& .MuiDrawer-paper': { width: '180px', marginTop: isSmUp ? 8 : 6 },
        '& .MuiIconButton-root': { display: 'none' }
      }}
      ModalProps={{ keepMounted: true }}
      onClose={props.handleDrawerClose}
      open={!props.expanded}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map(item => hasPermission(item)
          && (
            <NavLink
              className={classes.menuLink}
              to={item.link} key={item.name}
              onClick={isSmUp ? () => { } : props.handleDrawerClose}
            >
              <ListItemButton >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </NavLink>
          ))}
        <Divider />
        <NavLink
          className={classes.menuLink}
          to="/basket" key="5"
          onClick={isSmUp ? () => { } : props.handleDrawerClose}
        >
          <ListItemButton>
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText primary="Trash" />
          </ListItemButton>
        </NavLink>
      </List>
    </Drawer>
  )
}
