import { authApi } from '../Auth/api'
import { setSnackbar } from 'Actions/app'
import { ACCOUNT_LOGOUT } from '../../../store/front/types'
import { usersApi } from './api'
import store from '../../../store'

export const userDelete = async (history) => {
  const deleteResult = await usersApi.deleteSelf()

  if (deleteResult.status === 204) {
    const logoutResult = await authApi.logout()
    if (logoutResult.status === 204 || logoutResult.status === 403) {
      store.dispatch({ type: ACCOUNT_LOGOUT })
      store.dispatch(setSnackbar('Account was successfully deleted'))
      history.push('/login')
      window.location.reload()
    }
  }
}
