import React from 'react'

import { Modal, Table, List } from 'semantic-ui-react'

export const UserDetailsDialog = (props) => {
  return (
    <Modal size="tiny" open={props.open} onClose={props.onClose}>
      <Modal.Header>{'User details'}</Modal.Header>
      <Modal.Content>
        {props.user !== null &&
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell>First name</Table.Cell>
                <Table.Cell>{props.user.first_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Last name</Table.Cell>
                <Table.Cell>{props.user.last_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>{props.user.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Groups</Table.Cell>
                <Table.Cell>
                  <List bulleted>
                    {props.user.groups && props.user.groups.map(item => (
                      <List.Item key={item.id}>
                        <List.Content>
                          {`${item.name} ${item.description}`}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Projects</Table.Cell>
                <Table.Cell>
                  <List bulleted>
                    {props.user.projects && props.user.projects.map(item => (
                      <List.Item key={item.id}>
                        <List.Content>
                          {`${item.name} ${item.description}`}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        }
      </Modal.Content>
    </Modal>
  )
}
