import React from 'react'

import { Modal, Table, List, Button } from 'semantic-ui-react'

export const GroupDetailsDialog = (props) => {
  return (
    <Modal size="tiny" open={props.open} onClose={props.onClose}>
      <Modal.Header>{'Group details'}</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{props.group.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{props.group.description}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Owner</Table.Cell>
              <Table.Cell>{`${props.group.owner.first_name} ${props.group.owner.last_name}`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Users</Table.Cell>
              <Table.Cell>
                <List>
                  {props.group.users && props.group.users.map(item => (
                    <List.Item key={item.id}>
                      <List.Content>
                        <Button onClick={() => props.onDeleteUser(item)} icon="delete" size="mini" />
                        {`${item.first_name} ${item.last_name} (${item.email})`}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Projects</Table.Cell>
              <Table.Cell>
                <List>
                  {props.group.projects && props.group.projects.map(item => (
                    <List.Item key={item.id}>
                      <List.Content>
                        {`${item.name} ${item.description}`}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )
}
