import React from 'react'

import { Paper, Typography } from '@mui/material'

import { useDispatch } from 'react-redux'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { CenterContentTemplate } from '@ui/CenterContentTemplate'
import { RegisterForm } from '../forms/RegisterForm'

export const RegisterPageView = ({ history }) => {
  const dispatch = useDispatch()

  const params = new URLSearchParams(window.location.search)

  const onAfterSubmit = () => {
    dispatch({
      type: 'SET_SNACKBAR',
      message: 'Registration was successful; notification letter was sent. Check your email.'
    })
    history.push('/login')
  }

  return (
    <CenterContentTemplate>
      <Helmet title="Register" />
      <Paper style={{ maxWidth: 480, padding: '50px 60px', textAlign: 'center' }} elevation={5}>
        <Typography variant="h5" style={{ marginBottom: '30px' }} >
          Create a new account
        </Typography>
        <RegisterForm params={params} history={history} afterSubmit={onAfterSubmit} />
        <Typography style={{ marginTop: '1rem', textAlign: 'center' }}>
          {'Already have an account? '}
          <Link to="/login">Login</Link>
        </Typography>
      </Paper>
    </CenterContentTemplate>
  )
}
