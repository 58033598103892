import AES from 'crypto-js/aes'
import NodeRSA from 'node-rsa'
import CryptoJS from 'crypto-js'

export default class cryptoHelper {
  safeFields = ['password'];

  constructor(secretKey) {
    this.secretKey = secretKey
  }

  decryptPassword = (encryptedPassword, secretKey = this.secretKey) => {
    const decryptedPassword = {}
    for (const field of this.safeFields)
      if (Object.prototype.hasOwnProperty.call(encryptedPassword, field)) {
        const bytes = AES.decrypt(encryptedPassword[field], secretKey)
        const password = bytes.toString(CryptoJS.enc.Utf8)
        decryptedPassword[field] = password
      }
      else {
        decryptedPassword[field] = ''
      }

    return decryptedPassword
  };

  encryptPassword = (password, secretKey = this.secretKey) => AES
    .encrypt(password, secretKey).toString();

  ecryptedSecretkey(publicKey, secretKey = this.secretKey) {
    const rsaKey = new NodeRSA(null)
    rsaKey.setOptions({ encryptionScheme: 'pkcs1' })
    rsaKey.importKey(publicKey)
    return rsaKey.encrypt(secretKey, 'base64').toString('utf8')
  }

  encryptFile(file) {
    return CryptoJS.AES.encrypt(file, this.secretKey)
  }

  decryptFile(data) {
    const decrypted = CryptoJS.AES.decrypt(data, this.secretKey)
      .toString(CryptoJS.enc.Latin1)
    return decrypted
  }
}
