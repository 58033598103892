import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { clearSnackbar } from 'Actions/app'

const useStyles = makeStyles({ snackbar: { zIndex: 9999 } })

export const UiSnackbar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { message, open } = useSelector((state) => state.app.snackbar)

  const handleRequestClose = () => dispatch(clearSnackbar())

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        classes={{ root: 'snackbar-root' }}
        className={classes.snackbar}
        message={message}
        autoHideDuration={3000}
        onClose={handleRequestClose}
      />
    </React.Fragment>
  )
}
