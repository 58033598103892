/* eslint-disable array-element-newline */
import React from 'react'

import { DataGrid } from '@mui/x-data-grid'

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'email', headerName: 'Email', width: 230 },
  { field: 'fullName', headerName: 'Full name', width: 230 },
  { field: 'lastLogin', headerName: 'Last login', width: 230 }
]

export const UsersTable = ({ onChangeRowsPerPage, onRowClick, users }) => {
  const getRows = () => {
    const data = []
    users.map((item) => {
      const lastLogin = item.last_login ?
        `${item.last_login.split('T')[0]} ${item.last_login.split('T')[1].split('.')[0]}` : 'never'
      data.push({ id: item.id, email: item.email, fullName: item.full_name, lastLogin: lastLogin })
    })

    return data
  }

  return (
    <div style={{ height: '70vh', width: '100%' }}>
      <DataGrid
        rows={getRows()}
        columns={columns}
        autoHeight={false}
        loading={getRows().length === 0 ? true : false}
        disableColumnFilter
        onRowClick={onRowClick}
        onPageSizeChange={onChangeRowsPerPage}
        disableSelectionOnClick={true}
      />
    </div>
  )
}
