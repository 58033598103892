import React from 'react'

import { Grid, Button, InputAdornment, IconButton } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'

import { withFormik } from 'formik'
import CryptoJS from 'crypto-js'

import { Input } from '@ui/Input'

import CryptoHelper from 'Utils/CryptoHelper'

const masterPasswordValidator = (values, props) => {
  const { oldMasterPassword } = values
  const { hash: oldHash } = props
  const hash = CryptoJS.SHA256(oldMasterPassword).toString(CryptoJS.enc.Hex)
  const errors = {}
  if (!values.newMasterPassword)
    errors.newMasterPassword = 'Required'
  else if (values.newMasterPassword.length < 8)
    errors.newMasterPassword = 'The password must be at least 8 characters long'

  if (!values.confirmNewMasterPassword)
    errors.confirmNewMasterPassword = 'Required'

  if (!values.oldMasterPassword)
    errors.oldMasterPassword = 'Required'
  else if (oldHash !== hash)
    errors.oldMasterPassword = 'Invalid old master password'

  if (values.newMasterPassword !== values.confirmNewMasterPassword)
    errors.confirmNewMasterPassword = 'Passwords don\'t match'

  return errors
}

let cryptoHelper = null

const handleSubmit = async (values, {
  setSubmitting,
  props: { cryptoWorker, changeMasterPassword, hash },
  resetForm
}) => {
  await cryptoWorker.dispatch('GENERATE_RSA_KEY', hash)
  cryptoHelper = new CryptoHelper()
  await changeMasterPassword(values.newMasterPassword, cryptoWorker, cryptoHelper, hash, setSubmitting)
  resetForm()
}

const formik = {
  enableReinitialize: true,
  validate: masterPasswordValidator,
  handleSubmit
}

export const MasterPasswordForm = withFormik(formik)(({
  handleChange, handleBlur, isSubmitting, errors, handleSubmit, values
}) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-start" justifyContent="space-around">
        <Grid item xs={12} lg={4}>
          <Input
            name="newMasterPassword"
            label="New master password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newMasterPassword || ''}
            error={errors.newMasterPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Input
            name="confirmNewMasterPassword"
            label="Confirm new master password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmNewMasterPassword || ''}
            error={errors.confirmNewMasterPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Input
            name="oldMasterPassword"
            label="Old master password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.oldMasterPassword || ''}
            error={errors.oldMasterPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2} style={{ margin: '10px 0' }}>
        <Grid item>
          <Button disabled={isSubmitting || !values.oldMasterPassword} type="submit" variant="contained" color="primary">
            Change master password
          </Button>
        </Grid>
      </Grid>
    </form>
  )
})
