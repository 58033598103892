import React from 'react'

import { Link } from 'react-router-dom'

export const NotFound404 = () => {
  return (
    <div className="not-found">
      <div className="not-found__content">
        <h3 className="not-found__info">Ooops! The page you are looking for could not be found :(</h3>
        <Link className="btn btn-primary" to="/">Back Home</Link>
      </div>
    </div>
  )
}
