import { SET_COMPANY_SETTINGS, SET_LDAP_SETTINGS } from '../types'

const initialState = {
  company_settings: {},
  ldap_settings: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_SETTINGS: {
      return { ...state, company_settings: action.data }
    }
    case SET_LDAP_SETTINGS:
      return { ...state, ldap_settings: action.data }
    default:
      return state
  }
}
