// app
export const SHOW_MESSAGE_ADMIN = 'SHOW_MESSAGE_ADMIN'
export const HIDE_MESSAGE_ADMIN = 'HIDE_MESSAGE_ADMIN'
export const USER_RESETED_ADMIN = 'USER_RESETED_ADMIN'

// categories
export const CATEGORIES_LIST_FETCHED_ADMIN = 'CATEGORIES_LIST_FETCHED_ADMIN'
export const CATEGORY_ADDED_ADMIN = 'CATEGORY_ADDED_ADMIN'
export const CATEGORY_DELETED_ADMIN = 'CATEGORY_DELETED_ADMIN'
export const CATEGORY_CHANGED_ADMIN = 'CATEGORY_CHANGED_ADMIN'
export const CATEGORIES_LIST_FETCHED_ERROR = 'CATEGORIES_LIST_FETCHED_ERROR'
export const CATEGORY_CHANGED_ERROR = 'CATEGORY_CHANGED_ERROR'