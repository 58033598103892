import React from 'react'

import { Accordion, AccordionSummary, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  column: {
    flex: '33.33%',
    width: 1
  },
  listItem: { width: '100%' }
})

export const InfoList = ({ headerTitle, items, nameKey, descriptionKey }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div style={{ zIndex: '10' }}>
        <Accordion expanded={false}>
          <AccordionSummary>
            <Typography variant="h5" color="primary" className={classes.column}>
              {headerTitle}
            </Typography>
          </AccordionSummary>
        </Accordion>
      </div>
      <div style={{ overflow: 'auto', height: '250px' }}>
        {items.map(item => (
          <Accordion expanded={false} key={item.id} style={{ zIndex: '-9' }}>
            <AccordionSummary classes={{ content: classes.listItem }}>
              <div className={classes.column}>
                <Typography>{item[nameKey]}</Typography>
              </div>
              <div className={classes.column}>
                <Typography noWrap>{item[descriptionKey]}</Typography>
              </div>
            </AccordionSummary>
          </Accordion>
        ))}
      </div>
    </React.Fragment>
  )
}
