/* eslint-disable react/no-array-index-key */
import React from 'react'

import {
  Dialog, DialogActions, DialogContent, Button, DialogTitle, Accordion, AccordionSummary, Typography, Grid,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { HistoryItem } from '../HistoryItem/index'

const useStyles = makeStyles({
  root: { width: '100%' },
  column: { flex: '33.33%' }
})

export const HistoryForm = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Dialog onClose={props.closeForm} open={props.open} fullWidth scroll="body">
        <DialogTitle>History</DialogTitle>
        <DialogContent>
          <Accordion expanded={false}>
            <AccordionSummary>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.column}>Changed</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.column}>Changed by</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
          </Accordion>
          {props.history.map((password, index) => (
            <HistoryItem key={index} password={props.decryptPassword(password)} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button title="close" color="primary" onClick={props.closeForm}>
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
