import React from 'react'

import { styled, useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import { authRoutes } from 'Containers/front/Auth/routes'
import { usersRoutes } from 'Containers/front/Users/routes'
import { NotFound404 } from 'Containers/front/NotFound'
import { SomethingWentWrong } from 'Containers/front/SomethingWentWrong'
import { Main } from 'Containers/front/Main'
import { ProjectDetail } from 'Containers/front/ProjectDetail'
import { Log } from 'Containers/front/Log'
import { Home } from 'Containers/front/Home'
import { ProjectSettings } from 'Containers/front/ProjectSettings'
import { ProjectsList } from 'Containers/front/ProjectsList'
import { GroupSettings } from 'Containers/front/GroupSettings'
import { GroupsList } from 'Containers/front/GroupsList'
import { Activate } from 'Containers/front/Activate'
import { Basket } from 'Containers/front/Basket'
import { CompanyActivate } from 'Containers/front/CompanyActivate'
import { CompanyRegisterPageView } from 'Containers/front/CompanyRegister/CompanyRegisterPageView'
import { PasswordAssignmentPageView } from 'Containers/front/PasswordAssignment/PasswordAssignmentPageView'
import { SettingsPage } from 'Containers/front/Settings/Settings'

import { AdminMain } from 'Layouts/admin/AdminMain'
import { AdminHome } from 'Containers/admin/Home'
import { AdminProjects } from 'Containers/admin/Projects'
import { AdminUsers } from 'Containers/admin/Users'
import { AdminGroups } from 'Containers/admin/Groups'
import { AdminOther } from 'Containers/admin/Other'

const PrivateRoute = ({ data, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (data.user
      ? <Component {...props} {...rest} key={rest.path} />
      : <Redirect to={{ pathname: '/login' }} />)}
  />
)

const MainWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: 80,
    marginLeft: '180px',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
)

const useIsWidthUp = (breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const Routes = ({ user, appReady, isDomainPublic, hash }) => {
  const isSmUp = useIsWidthUp("sm")

  const [expanded, setExpanded] = React.useState(isSmUp)

  const data = { user }
  const isSuperuser = user ? user.is_superuser : false
  const isAdminMode = useLocation().pathname.includes('admin')
  const isAdminSettings = useLocation().pathname.includes('admin-settings')
  return appReady ? (
    (!isDomainPublic && !isSuperuser && !hash && (
      <Switch>
        {authRoutes()}
        <Route path="/not-found" component={NotFound404} />
        <Route path="/something-went-wrong" component={SomethingWentWrong} />
        <Route path="/activate/:email?/:token?" component={Activate} />
        <PrivateRoute data={data} path="/assign-password" component={PasswordAssignmentPageView} />
        <Redirect to={{ pathname: '/assign-password' }} />
      </Switch>
    )) || (!isDomainPublic && (
      <Switch>
        {authRoutes()}
        <Route path="/not-found" component={NotFound404} />
        <Route path="/something-went-wrong" component={SomethingWentWrong} />
        <Route path="/activate/:email?/:token?" component={Activate} />
        {!isAdminMode ? (
          <Main expanded={expanded} setExpanded={setExpanded}>
            {CryptoWorker => (
              <MainWrapper open={expanded}>
                {usersRoutes(data, CryptoWorker)}
                <PrivateRoute data={data} exact path="/projects" component={ProjectsList} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/projects/:id" component={ProjectDetail} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/project_settings/:id" component={ProjectSettings} />
                <PrivateRoute data={data} exact path="/groups" component={GroupsList} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/groups/:id" component={GroupSettings} />
                <PrivateRoute data={data} path="/log" component={Log} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/home" component={Home} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/basket" component={Basket} />
                <PrivateRoute data={data} cryptoWorker={CryptoWorker} exact path="/" component={Home} />
              </MainWrapper>
            )}
          </Main>
        )
          : (
            <main style={{ display: isAdminSettings ? 'block' : 'flex', height: '100vh' }}>
              <AdminMain>
                {CryptoWorker => (
                  <div>
                    <Switch>
                      <Route path="/admin-home" component={AdminHome} />
                      <Route path="/admin-projects" component={AdminProjects} />
                      <PrivateRoute data={data} path="/admin-users" cryptoWorker={CryptoWorker} component={AdminUsers} />
                      <Route path="/admin-groups" component={AdminGroups} />
                      <Route path="/admin-other" component={AdminOther} />
                      <PrivateRoute data={data} cryptoWorker={CryptoWorker} path="/admin-settings" component={SettingsPage} />
                    </Switch>
                  </div>
                )}
              </AdminMain>
            </main>
          )}
      </Switch>
    )) || (isDomainPublic && (
      <Switch>
        <Route path="/company_register" component={CompanyRegisterPageView} />
        <Route path="/not-found" component={NotFound404} />
        <Route path="/something-went-wrong" component={SomethingWentWrong} />
        <Route path="/companyActivate/:email?/:token?" component={CompanyActivate} />
        <Redirect to={{ pathname: '/company_register' }} />
      </Switch>
    ))
  ) : (
      <Switch>
        <Route path="/something-went-wrong" component={SomethingWentWrong} />
      </Switch>
    )
}
