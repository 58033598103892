
export default async function decryptProjectSecretKeys(projectData, cryptoWorker, cryptoHelper) {
  // Adding encrypted with server public key new secret project keys
  const serverPublicKey = window.localStorage.getItem('server_public_key') || ''
  const newProjectSecretKeys = await Promise.all(projectData
    .map(async (secretKeyObject, index) => {
      const {
        encrypted_secret_key: { secret_key: newEncryptedProjectKey },
        id: projectId
      } = secretKeyObject
      const newDecryptedProjectKey = await cryptoWorker.dispatch('DECRYPT_KEY', newEncryptedProjectKey)
      const encryptedServerKey = cryptoHelper.ecryptedSecretkey(
        serverPublicKey, newDecryptedProjectKey
      )
      return { id: projectId, encrypted_secret_key: encryptedServerKey }
    })).catch((error) => {
    console.log('Something goes wrong with re-encription project secret keys')
    return null
  })
  return newProjectSecretKeys
}
