import React from 'react'

import {
  Button, IconButton, Card, CardActions, CardHeader, CardContent,
} from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'

import { Field, Form } from 'react-final-form'

import { FieldForm } from '../components/FieldForm'
import { validate } from './components/validation'

export const EditInfoCard = (props) => {
  const cardContent = (props.changeInfo
    ? (
      <div>
        <CardContent>
          <Form
            initialValues={{ name: props.info.name, description: props.info.description }}
            validate={values => validate(values)}
            onSubmit={props.onSubmit}
            render=
            {({ handleSubmit }) => (
              <form id="EditInfoCard" onSubmit={handleSubmit}>
                <Field
                  name="name"
                  label="name"
                  fullWidth
                  component={FieldForm}
                />
                <Field
                  label="description"
                  name="description"
                  multiline
                  fullWidth
                  rowsMax={4}
                  style={{ marginTop: 12 }}
                  component={FieldForm}
                />
              </form>)}
          />
        </CardContent>
      </div>
    )
    : (
      <div>
        <CardHeader
          title={props.info.name || 'Name'}
          action={(props.getPermissions(['admin']) ? (
            <IconButton onClick={props.onClickEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
          ) : null)}
          subheader={props.info.description || 'Description'}
        />
      </div>
    )
  )

  return (
    <div>
      <Card>
        {cardContent}
        {props.changeInfo
          && (
            <CardActions>
              <Button type="submit" form="EditInfoCard" size="small">Save</Button>
              <Button onClick={props.onClickCancel} size="small">Cancel</Button>
            </CardActions>
          )}
      </Card>
    </div>
  )
}
