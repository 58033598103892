import React from 'react'

import {
  Card, CardHeader, CardContent, Grid, Avatar, Typography,
} from '@mui/material'

import md5 from 'md5'

export const OwnerCard = (props) => {
  const avatarUrl = `https://www.gravatar.com/avatar/${md5(props.owner.email)}`

  return (
    <Card>
      <CardHeader title="Owner" />
      <CardContent>
        <Grid container spacing={8}>
          <Grid item>
            <Avatar alt="avatar" src={avatarUrl} />
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {props.owner.first_name}
            </Typography>
            <Typography variant="subtitle1">
              {props.owner.last_name}
            </Typography>
            <Typography variant="subtitle1">
              {props.owner.email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
