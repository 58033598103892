import React from 'react'

import {
  Modal, Button, Form, TextArea, Dimmer, Loader,
} from 'semantic-ui-react'

const formInitialState = { name: '', description: '', email: '', message: '' }

export const InviteUserDialog = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState(formInitialState)

  const handleSubmit = () => {
    if (props.initialValues) {
      setLoading(true)
      formData.id = props.initialValues
      props.onSubmit(formData)
      setLoading(false)
    }
    else {
      alert('List of users is undefined')
    }
  }

  const onInputChange = (event, data) => {
    const { value, name } = data
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <Modal size="mini" open={props.open} onClose={props.onClose}>
      <Modal.Header>{'Invite user'}</Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer active inverted>
            <Loader inverted>{'Loading'}</Loader>
          </Dimmer>
        )}
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="first_name"
              placeholder='First name'
              onChange={onInputChange}
            />
            <Form.Input
              fluid
              name="last_name"
              placeholder='Last name'
              onChange={onInputChange}
            />
          </Form.Group>
          <Form.Input
            fluid
            name="email"
            type="email"
            placeholder='Email'
            onChange={onInputChange}
          />
          <Form.Field
            rows={3}
            name="message"
            control={TextArea}
            placeholder='Message'
            onChange={onInputChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>{'Cancel'}</Button>
        <Button onClick={handleSubmit} positive>{'Submit'}</Button>
      </Modal.Actions>
    </Modal>
  )
}
