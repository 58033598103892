import { FRequest } from '@lib/frequest'

// eslint-disable-next-line max-len
const baseUrl = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT.toString().replace(/"/g, '')}${process.env.REACT_APP_API_PREFIX.toString().replace(/"/g, '').replace(/v0\//g, '')}`

const request = new FRequest({
  baseUrl,
  reqParams: { credentials: 'include' }
})

export const authApi = { companyRegister: registerData => request.post('signup/', registerData) }
