import { AccountCircleRounded } from '@mui/icons-material'
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded'
import AdbRoundedIcon from '@mui/icons-material/AdbRounded'
import AddToHomeScreenRoundedIcon from '@mui/icons-material/AddToHomeScreenRounded'
import AirplayRoundedIcon from '@mui/icons-material/AirplayRounded'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'

export const icons = [{
  Icon: AccountCircleRounded,
  id: 0
},
{
  Icon: AccessibilityNewRoundedIcon,
  id: 1
},
{
  Icon: AdbRoundedIcon,
  id: 2
},
{
  Icon: AddToHomeScreenRoundedIcon,
  id: 3
},
{
  Icon: AirplayRoundedIcon,
  id: 4
},
{
  Icon: BeachAccessIcon,
  id: 5
}
]
