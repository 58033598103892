import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Typography,
} from '@mui/material'


export const PasswordCopyForm = (props) => {
  return (
    <Dialog onClose={props.closeForm} aria-labelledby="form-dialog-title" open={props.open} fullWidth>
      <DialogTitle id="form-dialog-title">
        Copy/move item
      </DialogTitle>
      <DialogContent>
        {(props.projects.length !== 0 ? (
          <List>
            {props.projects.map(project => (
              <div key={project.id}>
                <ListItem>
                  <ListItemText primary={project.name} />
                  <ListItemSecondaryAction>
                    <Button size="small" type="button" onClick={() => props.copyPassword(props.password, project)}>
                      copy
                    </Button>
                    <Button size="small" onClick={() => props.movePassword(props.password, project)} type="button">
                      move
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider light />
              </div>
            ))}
          </List>
        ) : (
            <Typography variant="subtitle1">
              While you do not have projects in which you could copy or move the password
            </Typography>
          ))}
      </DialogContent>
      <DialogActions>
        <Button title="close" onClick={props.closeForm}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
