import React from 'react'

import { Grid, Button, Typography } from '@mui/material'

import { withFormik } from 'formik'

import { Input } from '@ui/Input'

import { usersApi } from '../../api'
import { icons } from '../../../../../constants/icons'

const formik = {
  enableReinitialize: true,
  mapPropsToValues: ({ user }) => ({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    id: user.id || '',
    icon_id: user.icon_id || 0
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    const updateResult = await usersApi.update(values)
    if (updateResult.status === 200) {
      const updateData = await updateResult.json()
      props.afterSubmit(updateData)
      setSubmitting(false)
    }
  }
}

export const UserSettingsForm = withFormik(formik)(({
  handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue, resetForm, values
}) => {
  const handleIconClick = id => setFieldValue('icon_id', id)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={6} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} xl={6}>
                <Input
                  name="first_name"
                  label="First name"
                  variant="outlined"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="last_name"
                  label="Last name"
                  variant="outlined"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="email"
                  label="E-mail"
                  type="email"
                  variant="outlined"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={5} spacing={2}>
            <Grid item container>
              <Typography color="primary" variant="h6" gutterBottom>
                Select your Icon
              </Typography>
            </Grid>
            <Grid container>
              {icons.map(icon => (
                <Grid key={icon.id} item sm={2}>
                  <icon.Icon
                    onClick={() => handleIconClick(icon.id)}
                    color={icon.id === values.icon_id ? 'secondary' : 'primary'}
                    style={{ fontSize: 50 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2} style={{ marginTop: '5px' }}>
            <Grid item>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isSubmitting}
                variant="contained"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
})
