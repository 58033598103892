import React from 'react'

import { useDispatch } from 'react-redux'

import { Paper, Typography } from '@mui/material'

import { Helmet } from 'react-helmet'

import { CenterContentTemplate } from '@ui/CenterContentTemplate'
import { AssignPasswordForm } from './AssignPasswordForm'

export const PasswordAssignmentPageView = ({ history }) => {
  const dispatch = useDispatch()

  const params = new URLSearchParams(window.location.search)

  const onAfterSubmit = () => {
    dispatch({
      type: 'SET_SNACKBAR',
      message: 'Password was successfully created!'
    })
    history.push('/login')
  }

  return (
    <CenterContentTemplate>
      <Helmet title="Company Register" />
      <Paper style={{ maxWidth: 520, padding: '50px 60px', textAlign: 'center' }} elevation={5}>
        <Typography variant="h5">
          Password Assignment
        </Typography>
        <AssignPasswordForm params={params} history={history} afterSubmit={onAfterSubmit} />
      </Paper>
    </CenterContentTemplate>
  )
}
