import React from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

const allowedImagePreview = ['image/png', 'image/jpeg', 'image/gif']

export const PreviewDialog = (props) => {
  const getPreviewComponent = () => {
    if (!props.data)
      return <p>Preview not alowed</p>

    const arr = props.data.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]

    if (allowedImagePreview.includes(mime))
      return <img style={{ width: '100%' }} src={props.data} />

    return <p>Preview not alowed</p>
  }

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent>
        {getPreviewComponent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained" size="small">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
