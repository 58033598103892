import React from 'react'

import { Grid, Typography, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'react-redux'

import { getLDAPSettings } from 'Actions/settings'
import { LDAPSettingsForm } from './LDAPSettingsForm'

export const useStyles = makeStyles(theme => ({
  form_wrap_settings: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: { maxWidth: '380px' }
  }
}))

export const LDAPSettings = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const ldap = useSelector((state) => state.settings.ldap_settings)

  const afterLDAPUpdate = (response, snackbarText) => {
    dispatch({ type: 'SET_LDAP_SETTINGS', data: { ...response.data } })
    dispatch({ type: 'SET_SNACKBAR', message: snackbarText })
  }

  React.useEffect(() => {
    dispatch(getLDAPSettings())
  }, [])

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item align="center" xs={12} sm={10} md={6}>
        <Paper className={classes.form_wrap_settings}>
          <Typography variant="h5" style={{ paddingBottom: '15px' }}>
            LDAP settings
          </Typography>
          <LDAPSettingsForm ldap={ldap} afterSubmit={afterLDAPUpdate} />
        </Paper>
      </Grid>
    </Grid>
  )
}
