import * as types from '../types'

import deleteItem from './commonFunctions'

const initialState = {
  passwordsList: [],
  passwordsListByIds: [],
  deletedPasswordsList: { passwords: [], dates: [] },
  currentPassword: null,
  categoriesList: [],
  users: [],
  tagsList: [],
  passwordLoading: false,
  historyForPassword: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.PASSWORDS_FETCHED:
      return { ...state, passwordsList: [...state.passwordsList, ...action.data] }
    case types.PASSWORDS_FETCHED_FOR_PROJECT_DETAILS:
      return { ...state, passwordsListByIds: action.data }
    case types.PASSWORD_LOADING:
      return { ...state, passwordLoading: action.data }
    case types.DELETED_PASSWORDS_LIST_FETCHED:
      return { ...state, deletedPasswordsList: action.data }
    case types.CURRENT_PASSWORD_RESET:
      return { ...state, currentPassword: null }
    case types.PASSWORD_RESTORED:
      return {
        ...state,
        deletedPasswordsList: {
          ...state.deletedPasswordsList,
          passwords: deleteItem(action.data, state.deletedPasswordsList.passwords)
        }
      }
    case types.PASSWORD_FETCHED: {
      return { ...state, currentPassword: action.data }
    }
    case types.PASSWORD_ADDED:
      return {
        ...state,
        currentPassword: action.data.password,
        tagsList: action.data.tags,
        passwordsList: [...state.passwordsList, action.data]
      }
    case types.PASSWORD_DELETED: {
      const newPasswordsList = state.passwordsList.slice()
      for (let i = 0; i < newPasswordsList.length; i += 1)
        if (newPasswordsList[i].id === action.data.id) {
          newPasswordsList.splice(i, 1)
          break
        }
      return { ...state, passwordsList: newPasswordsList, currentPassword: null }
    }
    case types.USERS_FETCHED:
      return { ...state, users: action.data }
    case types.CATEGORIES_FETCHED:
      return { ...state, categoriesList: action.data }
    case types.TAGS_FETCHED:
      return { ...state, tagsList: action.data }
    case types.HISTORY_FETCHED:
      return { ...state, historyForPassword: action.data }
    default:
      return state
  }
}
