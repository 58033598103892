/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { Autorenew } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import zxcvbn from 'zxcvbn'
import randomatic from 'randomatic'

const useStyles = makeStyles({
  strength: {
    marginTop: -4,
    height: 3,
    transition: 'all .2s ease'
  },
  level0: { width: 0 },
  level1: {
    width: '25%',
    background: '#f44336'
  },
  level2: {
    width: '50%',
    background: '#ffc107'
  },
  level3: {
    width: '75%',
    background: '#2196f3'
  },
  level4: {
    width: '100%',
    background: '#4caf50'
  }
})

export const PasswordField = (props) => {
  const classes = useStyles()

  const [passwordLevel, setPasswordLevel] = React.useState('level0')

  const onFieldChange = (event) => {
    const { onChange } = props.inputProps
    const { value } = event.target
    onChange(value)
    setPasswordLevel(`level${zxcvbn(value).score}`)
  }

  const generatePassword = () => {
    const { onChange } = props.inputProps
    const value = randomatic('Aa0', 12)
    onChange(value)
    setPasswordLevel(`level${zxcvbn(value).score}`)
  }

  return (
    <React.Fragment>
      <TextField
        type="text"
        InputLabelProps={{ shrink: Boolean(props.inputProps.value) }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={generatePassword}>
                <Autorenew />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props.inputProps}
        onChange={onFieldChange}
      />
      <div className={[classes.strength, classes[passwordLevel]].join(' ')} />
    </React.Fragment>
  )
}
