import React from 'react'

import { makeStyles } from '@mui/styles'
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material'

import { ConfirmDialog } from 'Components/front/ConfirmDialog'

const useStyles = makeStyles({
  card: {
    width: '100%',
    cursor: 'pointer'
  },
  title: { fontSize: 14 },
  tags: { height: 21 }
})

export const PasswordCard = (props) => {
  const classes = useStyles()

  const tags = props.password.tags.map(tag => tag.name).join(', ')

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const [actionToConfirm, setActionToConfirm] = React.useState('')

  const onClick = () => props.onClick(props.password)

  const performAction = (e) => {
    e.stopPropagation()
    props.performAction(e.currentTarget.id ? e.currentTarget.id : actionToConfirm, props.password)
  }

  const handleOpenConfirmDialog = (e) => {
    e.stopPropagation()
    setOpenConfirmDialog(true)
    setActionToConfirm(e.currentTarget.id)
  }

  const closeConfirmDialog = () => setOpenConfirmDialog(false)

  return (
    <React.Fragment>
      <ConfirmDialog onCancel={closeConfirmDialog} onConfirm={performAction} open={openConfirmDialog} />
      <Card onClick={onClick} className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {props.password.project_detail.name}
          </Typography>
          <Typography variant="h5" component="h2">
            {props.password.name}
          </Typography>
          <Typography className={classes.tags} component="div">
            {tags.length ? `Теги: ${tags}` : ''}
          </Typography>
        </CardContent>
        <CardActions>
          {props.actions.map(action => (
            <Button
              color={action.color ? action.color : 'primary'}
              size="small"
              id={action.key}
              key={action.key}
              onClick={action.confirm ? handleOpenConfirmDialog : performAction}
            >
              {action.label}
            </Button>
          ))}
        </CardActions>
      </Card>
    </React.Fragment>
  )
}
