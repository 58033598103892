const validationRequire = val => (!val ? 'This field is required' : undefined)
const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/im

export const validation = {
  project: validationRequire,
  category: validationRequire,
  name: validationRequire,
  login: validationRequire,
  password: (val) => {
    const isEmpty = validationRequire(val)
    if (isEmpty)
      return isEmpty
    if (val.length > 53)
      return 'This password is too long'
    return null
  },
  url: (val) => {
    const isEmpty = validationRequire(val)
    if (isEmpty)
      return isEmpty
    if (!urlPattern.test(val))
      return 'Invalid url'
    return null
  }
}