import React from 'react'

import { Modal, Button, Form, Message } from 'semantic-ui-react'

export const OneFieldDialog = (props) => {
  const [formData, setFormData] = React.useState({ name: null })

  const handleSubmit = () => {
    formData.id = props.initialValues.id
    props.onSubmit(formData)
  }

  React.useEffect(() => {
    if (props.initialValues)
      setFormData(props.initialValues)
  }, [props.initialValues])

  const onInputChange = (event, data) => setFormData((prevState) => ({ ...prevState, name: data.value }))

  return (
    <Modal size="mini" open={props.open} onClose={props.onClose}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        {props.error && (
          <Message
            error
            header={props.error.name && `Field: ${props.error.name}`}
            content={Array.isArray(props.error.message) ? props.error.message.join(' ') : props.error.message}
          />
        )}
        <Form.Input
          fluid
          value={formData[props.name] || ''}
          placeholder={props.placeholder}
          name={props.name}
          onChange={onInputChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} negative>Cancel</Button>
        <Button onClick={handleSubmit} positive>Submit</Button>
      </Modal.Actions>
    </Modal>
  )
}
