import { FILES_FETCHED, FILE_UPLOADED } from '../types'

const initialState = { list: [] }

export default function (state = initialState, action) {
  switch (action.type) {
    case FILES_FETCHED:
      return { ...state, list: action.data }
    case FILE_UPLOADED:
      return { ...state, list: [action.data, ...state.list] }
    default:
      return state
  }
}
