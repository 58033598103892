import React from 'react'

import { Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'

import SwipeableViews from 'react-swipeable-views'

const useStyles = makeStyles({
  container: {
    '& .react-swipeable-view-container > div > div': {
      width: '100%',
      margin: 0
    }
  },
  tabbar: { marginTop: 2 },
  avatar: {
    width: 60,
    height: 60
  }
})

export const TabBar = ({ hideBar, tabs, children }) => {
  const classes = useStyles()

  const [tabValue, setTabValue] = React.useState(0)
  const onTabChange = (_, value) => setTabValue(value)

  return (
    <React.Fragment>
      {!hideBar && tabs && (
        <Tabs
          className={classes.tabbar}
          value={tabValue}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}
        >
          {tabs.map(tab => <Tab label={tab} key={tab} />)}
        </Tabs>
      )}
      <SwipeableViews
        axis="x"
        index={tabValue}
        onChangeIndex={setTabValue}
        className={classes.container}
      >
        {children}
      </SwipeableViews>
    </React.Fragment>
  )
}
