import React from 'react'

import { Grid, Typography, Button, Divider } from '@mui/material'


import { useDispatch } from 'react-redux'
import { changeMasterPassword } from 'Actions/passwords'

import { ConfirmDialog } from 'Components/front/ConfirmDialog'
import { UserSettingsForm } from './UserSettingsForm'
import { MasterPasswordForm } from './MasterPasswordForm'
import { userDelete } from '../../effects'

export const UserSettingsView = ({ user, history, cryptoWorker, passwords, hash }) => {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  const onDeleteUser = () => userDelete(history)

  const afterUserUpdate = (response) => {
    dispatch({ type: 'UPDATE_ACCOUNT_INFO_SUCCESS', data: response.user })
    dispatch({
      type: 'SET_SNACKBAR',
      message: !response.user.is_confirm
        ? 'User was updated. Check your email. We have sent an email to your address.'
        : 'User was updated.'
    })
  }

  return (
    <Grid container spacing={2}>
      <ConfirmDialog
        onCancel={closeDialog}
        onConfirm={onDeleteUser}
        open={open}
      />
      <Grid item xs={12}>
        <Divider />
        <Typography color="primary" variant="h5" gutterBottom>
          Main info
        </Typography>
        <UserSettingsForm user={user} afterSubmit={afterUserUpdate} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Typography color="primary" variant="h5" gutterBottom>
          Master password
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MasterPasswordForm
          cryptoWorker={cryptoWorker}
          passwords={passwords}
          hash={hash}
          changeMasterPassword={changeMasterPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Typography color="secondary" variant="h5" gutterBottom>
          Danger zone
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={openDialog} color="secondary">
          Delete account
        </Button>
      </Grid>
    </Grid>
  )
}