import { authApi } from './api'

export const userLogin = async (data, { props, setErrors, setSubmitting }) => {
  const loginResult = await authApi.login(data)

  if (loginResult.status === 200) {
    const loginData = await loginResult.json()
    if (loginData.success) {
      localStorage.setItem('server_public_key', loginData.user.server_public_key)
      props.afterSubmit(loginData)
    }
    else {
      for (const name of Object.keys(loginData.errors))
        loginData.errors[name] = loginData.errors[name].join(' ')

      setErrors(loginData.errors)
      setSubmitting(false)
    }
  }
  else if (loginResult.status === 205) {
    props.history.push(`/activate?email=${data.username}`)
  }
  else {
    setErrors({ non_field_errors: 'Server error' })
    setSubmitting(false)
  }
}

export const userRegister = async (data, { props, setErrors, setSubmitting }) => {
  const registerResult = await authApi.register(data)

  if (registerResult.status === 200) {
    const registerData = await registerResult.json()
    if (registerData.success) {
      props.afterSubmit()
    }
    else {
      if (registerData.error)
        for (const name of Object.keys(registerData.error))
          registerData.error[name] = registerData.error[name].join(' ')

      setErrors(registerData.error)
      setSubmitting(false)
    }
  }
}
