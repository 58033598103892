import React from 'react'

export const SomethingWentWrong = () => {
  return (
    <div className="not-found">
      <div className="not-found__content">
        <h3 className="not-found__info">Ooops! Something went wrong :(</h3>
      </div>
    </div>
  )
}
