/* eslint-disable array-element-newline */
import React from 'react'

import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { useDispatch, useSelector } from 'react-redux'

import { Helmet } from 'react-helmet'

import { filterLogs } from 'Actions/projects'
import { changeLocalSettings } from 'Actions/app'
import { ContentWrap } from 'Layouts/front/ContentWrap'

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'actor', headerName: 'Actor', width: 230 },
  { field: 'time', headerName: 'Time', width: 230 },
  { field: 'action', headerName: 'Action', width: 500 }
]

const useStyles = makeStyles({ container: { width: '100%', maxWidth: 'none' } })

export const Log = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const logs = useSelector((state) => state.projects.logs)

  const loadData = async (page = 1, pageSize = 10, sort = -1, type = '') => {
    const filters = new URLSearchParams(props.location.search)
    const objectModel = filters.get('object_model')
    const objectId = filters.get('object_id')
    const data = { filters: { actor: filters.get('actor') } }
    const params = { page, page_size: pageSize }

    if (sort !== -1)
      data.filters.sort = (type === 'asc' ? sort : `-${sort}`)
    if (objectModel)
      data.filters.object = { model: objectModel, id: objectId }

    await dispatch(filterLogs(data, params))
  }

  React.useEffect(() => {
    const rows = localStorage.getItem('local_settings')
    loadData(1, JSON.parse(rows) !== null ? JSON.parse(rows).logsPerPage : undefined)
  }, [])


  const onChangeRowsPerPage = (count) => dispatch(changeLocalSettings({ logsPerPage: count }))

  const getRows = (logs) => {
    const data = []
    logs.map((item, index) => data.push(
      {
        id: index,
        actor: item.actor,
        time: new Date(Date.parse(item.time)).toLocaleString(),
        action: item.additional_info
      }
    ))
    return data
  }

  return (
    <ContentWrap className={classes.container}>
      <Helmet title="Log" />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div style={{ height: '82vh', width: '100%' }}>
            <DataGrid
              rows={getRows(logs)}
              columns={columns}
              loading={getRows(logs).length === 0 ? true : false}
              onPageSizeChange={onChangeRowsPerPage}
              disableSelectionOnClick={true}
            />
          </div>
        </Grid>
      </Grid>
    </ContentWrap>
  )
}
