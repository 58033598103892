import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { loadLocalSettings } from 'Actions/app'

import { Layout } from 'Layouts/front/Main'

import WebWorker from 'Utils/WebWorker'
import CryptoWorker from 'Utils/crypto.worker.js'

let worker

export const Main = (props) => {
  const dispatch = useDispatch()

  const { appReady, user } = useSelector((state) => state.app)

  React.useEffect(() => {
    worker = new WebWorker(CryptoWorker)
    dispatch(loadLocalSettings())
  }, [])

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {appReady && user && <Layout {...props} />}
      {props.children(worker)}
    </div>
  )
}
