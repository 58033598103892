import React from 'react'

import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { PasswordsGroupGrid } from 'Components/front/PasswordsGroupGrid'
import { PasswordsGrid } from 'Components/front/PasswordsGrid'
import { PasswordDetailDialog } from 'Components/front/PasswordDetailDialog'
import { SearchField } from 'Components/front/SearchField'

import CryptoHelper from 'Utils/CryptoHelper'

const useStyles = makeStyles({
  container: { width: '100%' },
  input: {
    marginLeft: 8,
    flex: 1
  }
})

let cryptoHelper = null

export const PasswordsList = (props) => {
  const classes = useStyles()

  const [showPasswordDetailDialog, setShowPasswordDetailDialog] = React.useState(false)
  const [workingPassword, setWorkingPassword] = React.useState({})
  const [searchValue, setSearchValue] = React.useState('')

  const initCryptoHelper = async () => {
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', props.hash)
    cryptoHelper = new CryptoHelper()
  }

  React.useEffect(async () => {
    if (props.cryptoWorker)
      await initCryptoHelper()
  }, [props.cryptoWorker])

  const onSearchInputChange = (event) => setSearchValue(event.target.value)
  const closePasswordDetailDialog = () => setShowPasswordDetailDialog(false)

  const openPasswordDetailDialog = (password) => {
    setShowPasswordDetailDialog(true)
    setWorkingPassword(password)
  }

  const decryptPassword = async (password) => {
    const result = { ...password }
    const encryptedInfo = { password: password.encrypted_password }
    const secretKey = await props.cryptoWorker.dispatch('DECRYPT_KEY', password.project_detail.encrypted_secret_key.secret_key)
    const decryptedPassword = cryptoHelper.decryptPassword(encryptedInfo, secretKey)
    for (const field in decryptedPassword)
      if (field)
        result[field] = decryptedPassword[field]
    return result
  }

  return (
    <div className={classes.container}>
      <PasswordDetailDialog
        open={showPasswordDetailDialog}
        onClose={closePasswordDetailDialog}
        password={workingPassword}
        getLink={props.getLink}
        preview={props.preview}
        decryptPassword={decryptPassword}
      />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <SearchField
            value={searchValue}
            onChange={onSearchInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          {props.group ? (
            <PasswordsGroupGrid
              rawPasswords={props.rawPasswords}
              searchValue={searchValue}
              performAction={props.performAction}
              onClick={openPasswordDetailDialog}
              actions={props.actions}
            />
          ) : (
              <PasswordsGrid
                rawPasswords={props.rawPasswords}
                searchValue={searchValue}
                performAction={props.performAction}
                onClick={openPasswordDetailDialog}
                actions={props.actions}
              />
            )}
        </Grid>
      </Grid>
    </div>
  )
}
