import { create } from 'axios'

// eslint-disable-next-line max-len
const API_BASE = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT.toString().replace(/"/g, '')}${process.env.REACT_APP_API_PREFIX.toString().replace(/"/g, '')}`

export const axios = create({
  baseURL: API_BASE,
  timeout: 20000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
})

export const authApi = {
  updateLDAP: (ldapData, ldapId) => axios.patch(`directory/${ldapId}/`, ldapData),
  createLDAP: ldapData => axios.post('directory/', ldapData),
  deleteLDAP: ldapId => axios.delete(`directory/${ldapId}/`)
}
