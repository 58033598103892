import React from 'react'

import {
  Grid, Button, InputAdornment, Typography, Fade, IconButton,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { makeStyles } from '@mui/styles'

import { withFormik, FieldArray } from 'formik'

import { Input } from '@ui/Input'

import {
  getImmutableDomain, getMutableDomains, getDomainsForSubmit, updateDomains,
  getDomainsToDelete, eliminateDuplicates, geDomainLabel,
} from './utils'

export const useStyles = makeStyles(theme => ({
  form_settings: {
    maxWidth: '350px',
    padding: '0 7px'
  },
  input_adornment: {
    backgroundColor: theme.palette.grey['200'],
    height: 'fit-content',
    padding: '18.5px 8px',
    borderRadius: '0 4px 4px 0',
    maxHeight: 'none',
    boxSizing: 'border-box',
    maxWidth: '150px',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  input_subdomain: { paddingRight: '0' },
  message: {
    color: theme.palette.primary.main,
    paddingLeft: '5px'
  },
  input_subdomain_primary: { paddingRight: '0' },
  input_subdomain_focused: {
    '& > div': {
      padding: '16.5px 8px',
      margin: '2px 0'
    }
  }
}))

const validate = (values) => {
  const errors = {}

  if (!values.company_name)
    errors.company_name = 'This field is required'
  else if (/\w\s+\w/i.test(values.company_name))
    errors.company_name = 'Company name should\'nt have spaces'

  const duplicates = eliminateDuplicates(values.domains.map(domain => domain.domain))
  if (duplicates.length) {
    errors.domains = []
    duplicates.forEach((duplicateIndexes) => {
      duplicateIndexes.forEach((index) => {
        errors.domains[index] = 'Duplicate!'
      })
    })
  }
  values.domains.forEach((domain, index) => {
    if (/\./i.test(domain.domain)) {
      if (!errors.domains)
        errors.domains = []
      errors.domains[index] = 'Using dots in subdomain name isn\'t allowed!'
    }
  })
  return errors
}

const formik = {
  enableReinitialize: true,
  mapPropsToValues: ({ company }) => {
    const immutableDomain = (company.domains && getImmutableDomain(company.domains)) || ''
    const domains = getMutableDomains(company.domains, immutableDomain) || ['']
    return {
      company_name: company.name || '',
      domains,
      id: company.id || '',
      immutableDomain
    }
  },
  validate,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const domainsForSubmit = getDomainsForSubmit(values.domains, values.immutableDomain)
    const domainsToDelete = getDomainsToDelete(props.company.domains, domainsForSubmit)
    const { company_name: name, id } = values
    await updateDomains(domainsForSubmit, domainsToDelete,
      name, id, { props, setSubmitting, setErrors })
  }
}

export const CompanySettingsForm = withFormik(formik)(({
  handleSubmit, handleChange, setFieldTouched, handleBlur, isSubmitting, resetForm, values, errors,
  touched, company
}) => {
  const classes = useStyles()

  const [showDomain, setShowDomain] = React.useState([false])

  React.useEffect(() => {
    const { domains } = company
    domains && setShowDomain(domains.map(domain => !!domain.domain))
  }, [company])

  return (
    <form onSubmit={handleSubmit} className={classes.form_settings}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12} style={{ margin: '8px 0 8px' }}>
          <Input
            name="company_name"
            label="Company name"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company_name}
            error={touched.company_name && errors.company_name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary" align="left">
            Company subdomains:
          </Typography>
        </Grid>
        <FieldArray name="domains">
          {({ push, remove }) => (
            <React.Fragment>
              {values.domains.map((domain, index) => (
                <Grid key={index} item container xs={12}>
                  <Grid item xs={11} style={{ ['margin']: '0px 0px', ['padding']: '5px 0px' }}>
                    <Input
                      name={`domains[${index}].domain`}
                      label={`Company subdomain №${index + 1}`}
                      variant="outlined"
                      disabled={isSubmitting || domain.is_primary}
                      onChange={(props) => handleChange(props)}
                      onBlur={() => {
                        handleBlur()
                        if (!domain.domain) {
                          const newShowDomain = showDomain.map((item, i) => (i === index ? false : item))
                          setShowDomain(newShowDomain)
                        }
                      }}
                      value={domain.domain || ''}
                      fullWidth
                      InputLabelProps={domain.is_primary && { shrink: true }}
                      error={touched.domains && touched.domains[index]
                        && errors.domains ? errors.domains[index] : false}
                      InputProps={{
                        endAdornment: !domain.is_primary && showDomain[index]
                          ? (
                            <Fade in={showDomain[index]} style={{ transitionDelay: '10ms' }}>
                              <InputAdornment position="end" className={classes.input_adornment}>
                                {`.${values.immutableDomain}`}
                              </InputAdornment>
                            </Fade>
                          ) : null
                          || (domain.is_primary && (
                            <InputAdornment position="end" className={classes.input_adornment}>
                              {`.${values.immutableDomain}`}
                            </InputAdornment>
                          )),
                        onFocus() {
                          const newShowDomain = showDomain.map((item, i) => (i === index ? true : item))
                          setShowDomain(newShowDomain)
                          setFieldTouched(`domains[${index}]`, true)
                        },
                        className: domain.is_primary ? classes.input_subdomain_primary
                          : classes.input_subdomain,
                        classes: { focused: classes.input_subdomain_focused }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ paddingTop: '9.5px', height: 'min-content' }}>
                    <IconButton
                      color="primary"
                      disabled={domain.is_primary}
                      onClick={() => {
                        remove(index)
                        const newShowDomain = showDomain.filter((item, i) => index !== i)
                        setShowDomain(newShowDomain)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {
                    domain.is_primary && (
                      <Grid item xs={12} alignItems="center" style={{ display: 'flex' }}>
                        <div>
                          <Typography variant="caption" align="left" className={classes.message}>
                            * is primary
                            </Typography>
                        </div>
                      </Grid>
                    )
                  }
                </Grid>
              ))}
              <Grid item xs={12} style={{ margin: '0 0 15px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    push({ domain: '' })
                    setShowDomain([...showDomain, false])
                  }}
                >
                  <AddIcon style={{ marginRight: '7px' }} />
                  Add subdomain
                </Button>
              </Grid>
            </React.Fragment>
          )
          }
        </FieldArray>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={8}>
            <Grid item>
              <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">Save</Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isSubmitting}
                variant="contained"
                onClick={() => {
                  resetForm()
                  const { domains } = company
                  domains && setShowDomain(domains.map(domain => !!domain.domain))
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
})
