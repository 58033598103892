import React from 'react'
import { Helmet } from 'react-helmet'
import { TabBar } from 'Components/front/TabBar'
import { CompanySettings } from './Company/CompanySettings'
import { LDAPSettings } from './LDAP/LDAPSettings'

export const SettingsPage = () => (
  <React.Fragment>
    <Helmet title="Admin Settings" />
    <TabBar tabs={['LDAP', 'Company']}>
      <LDAPSettings />
      <CompanySettings />
    </TabBar>
  </React.Fragment>
)
