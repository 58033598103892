import { init, withScope, captureException, showReportDialog } from '@sentry/browser'
import React, { Component } from 'react'
import { Button } from '@mui/material'


if (process.env.NODE_ENV === 'production')
  init({
    dsn: 'https://0e5cd694c24b47d89fa8770c0871c03d@sentry.io/1376929',
    ignoreErrors: ['HTTP 403 Forbidden', '403 Forbidden', '403']
  })


class SentryWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      captureException(error)
    })
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error)
      return (
        <div>
          <h3>Ooops! The page you are looking for could not be found :(</h3>
          <div>
            <Button variant="contained" size="small" href="/">Back Home</Button>
            <Button variant="contained" size="small" style={{ marginLeft: 10 }} onClick={() => showReportDialog()}>
              Report feedback
            </Button>
          </div>
        </div>
      )

    return children
  }
}

export default SentryWrapper
