import React from 'react'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  contentWrapper: {
    maxWidth: '1140px',
    width: '100%'
  }
})

export const ContentWrap = ({ children, style, className }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.contentWrapper} ${className}`} style={style}>
      {children}
    </div>
  )
}
