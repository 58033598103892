import React from 'react'

import { Grid, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Input } from '@ui/Input'
import { withFormik } from 'formik'

import { updateLDAP, createLDAP, isEmpty } from './utils'

export const useStyles = makeStyles(theme => ({
  form_settings: {
    maxWidth: '350px',
    padding: '0 7px'
  }
}))

const validate = (values) => {
  const errors = {}
  for (const fieldName in values)
    if (!values[fieldName])
      errors[fieldName] = 'This field is required'
  return errors
}

const formik = {
  enableReinitialize: true,
  mapPropsToValues: ({ ldap }) => ({
    name: ldap.name || '',
    host: ldap.host || '',
    login: ldap.login || '',
    password: ldap.password || '',
    base_dn: ldap.base_dn || '',
    ldap_user_filter: ldap.ldap_user_filter || '',
    domain: ldap.domain || ''
  }),
  validate,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const ldapData = { auto_sync: true, type: 'openLDAP', ...props.ldap, ...values }
    const ldapId = props.ldap && props.ldap.id
    if (isEmpty(props.ldap))
      await createLDAP(ldapData, { props, setSubmitting, setErrors })
    else
      // await deleteLDAP(ldapId, { props, setSubmitting, setErrors });
      await updateLDAP(ldapData, ldapId, { props, setSubmitting, setErrors })
  }
}

export const LDAPSettingsForm = withFormik(formik)(({
  handleSubmit, handleChange, handleBlur, isSubmitting, resetForm, values, errors, touched
}) => {
  const classes = useStyles()

  return (
    <form onSubmit={handleSubmit} className={classes.form_settings}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Input
            name="name"
            label="Name"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            fullWidth
            error={touched.name && errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="host"
            label="Host"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.host}
            fullWidth
            error={touched.host && errors.host}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="login"
            label="Login"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.login}
            fullWidth
            error={touched.login && errors.login}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="password"
            label="Password"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            fullWidth
            error={touched.password && errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="base_dn"
            label="Base dn"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.base_dn}
            fullWidth
            error={touched.base_dn && errors.base_dn}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="ldap_user_filter"
            label="LDAP user filter"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.ldap_user_filter}
            fullWidth
            error={touched.ldap_user_filter && errors.ldap_user_filter}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="domain"
            label="Domain"
            variant="outlined"
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.domain}
            fullWidth
            error={touched.domain && errors.domain}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">Save</Button>
            </Grid>
            <Grid item>
              <Button disabled={isSubmitting} variant="contained" onClick={() => resetForm()}>Reset</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
})
