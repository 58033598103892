/* eslint-disable class-methods-use-this */
import React from 'react'

import {
  Input, InputAdornment, IconButton, Typography, Button, Chip, CircularProgress, Grid,
  List, ListItem, ListItemText, Divider, ListItemSecondaryAction, Avatar,
} from '@mui/material'
import {
  Visibility, VisibilityOff, GetApp, Audiotrack, Videocam, Photo, FormatSize, PictureAsPdf, Archive, Folder,
} from '@mui/icons-material'

import FileType from '@lib/file-type'

const typeToIconMapping = {
  audio: Audiotrack,
  video: Videocam,
  image: Photo,
  text: FormatSize,
  pdf: PictureAsPdf,
  archive: Archive,
  unknown: Folder
}

export const PasswordDetail = (props) => {
  const [passwordVisibility, setPasswordVisibility] = React.useState(false)
  const [decryptedPassword, setDecryptedPassword] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const decrypted = props.decryptPassword(props.password)
    if (decrypted instanceof Promise) {
      decrypted.then((pass) => {
        setDecryptedPassword(pass)
        setLoading(false)
      })
    }
    else {
      setDecryptedPassword(decrypted)
      setLoading(false)
    }
  }, [])

  const onCopy = (text) => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const onAttachmentClick = (id, name) => props.getLink(id, name, props.password)
  const onPreviewClick = (id, name) => props.preview(id, name, props.password)
  const changePasswordVisibility = () => setPasswordVisibility(!passwordVisibility)

  const getFileIcon = (fileName) => {
    const mime = FileType(fileName, true)
    const Component = typeToIconMapping[mime] ? typeToIconMapping[mime] : typeToIconMapping.unknown
    return <Component />
  }

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
          <Grid container spacing={8}>
            <Grid item xs={12} md={props.responsive ? 6 : 12}>
              <table>
                <tbody>
                  {props.password.type === 1 && (
                    <React.Fragment>
                      <tr>
                        <td>
                          <Typography variant="body1">
                            Login:
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1">
                            {props.password.login}
                          </Typography>
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => onCopy(props.password.login)}
                          >
                            Copy
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1">
                            Password:
                          </Typography>
                        </td>
                        <td>
                          <Input
                            type={passwordVisibility ? 'text' : 'password'}
                            value={decryptedPassword.password}
                            readOnly
                            disableUnderline
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={changePasswordVisibility}
                                >
                                  {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )}
                          />
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => onCopy(decryptedPassword.password)}
                          >
                            Copy
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1">
                            URL:
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1">
                            {props.password.url}
                          </Typography>
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => onCopy(props.password.url)}
                          >
                            Copy
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                  <tr>
                    <td>
                      <Typography variant="body1">
                        Description:
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body1">
                        {props.password.description}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body1">
                        Tags:
                      </Typography>
                    </td>
                    <td>
                      {props.password.tags.map(tag => (
                        <Chip key={tag.id} label={tag.name} />
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {props.password.type === 1 && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => onCopy(
                    // eslint-disable-next-line max-len
                    `Login: ${props.password.login}\nPassword: ${props.password.password}\nURL: ${props.password.url}\nDescription: ${props.password.description}`.trim()
                  )}
                >
                  Copy all
                </Button>
              )}
            </Grid>
            {props.password.attachments && props.password.attachments.length > 0 && (
              <Grid item xs={12} md={props.responsive ? 6 : 12}>
                <Typography variant="subtitle1">
                  Attachments:
                  </Typography>
                <Divider />
                <List>
                  {props.password.attachments.map(file => (
                    <ListItem
                      button
                      key={file.id}
                      onClick={() => onPreviewClick(file.id, file.name)}
                    >
                      <Avatar>
                        {getFileIcon(file.name)}
                      </Avatar>
                      <ListItemText
                        primaryTypographyProps={{ noWrap: true, variant: 'body1' }}
                        primary={file.name}
                        secondary={FileType(file.name)}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => onAttachmentClick(file.id, file.name)}>
                          <GetApp />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        )}
    </React.Fragment>
  )
}
