export const validate = (values) => {
  const errors = {}
  if (!values.password)
    errors.password = 'This field is required'
  else if (values.password.length < 8)
    errors.paswword = 'Invalid password'

  if (!values.confirm_password)
    errors.confirm_password = 'This field is required'

  if (values.password !== values.confirm_password)
    errors.confirm_password = 'Passwords are not equal'

  return errors
}