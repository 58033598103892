import React from 'react'

import { Message, Transition } from 'semantic-ui-react'

import { useDispatch, useSelector } from 'react-redux'

import { hideMessage } from 'ActionsAdmin/app'

export const GlobalMessage = (props) => {
  const dispatch = useDispatch()

  const globalMessage = useSelector((state) => state.app.globalMessage)

  React.useEffect(() => {
    setTimeout(() => dispatch(hideMessage()), 2000)
  }, [])

  return (
    <Transition.Group animation="fade down">
      {globalMessage && globalMessage.open && (
        <Message floating styles={{
          'globalMessage': {
            position: 'absolute !important',
            zIndex: 1100
          }
        }}>
          {globalMessage.message}
        </Message>
      )}
    </Transition.Group>
  )
}