import React from 'react'

import { Grid, Divider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import dayjs from 'dayjs'

import { PasswordCard } from 'Components/front/PasswordCard'

const useStyles = makeStyles({
  dv: {
    marginTop: 10,
    marginLeft: 10
  },
  dv1: { marginBottom: 10 },
  containerHeight: {
    maxHeight: '65vh',
    overflow: 'auto'
  }
})

export const PasswordsGroupGrid = (props) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" spacing={8}>
      {props.rawPasswords.dates.map(date => (
        <React.Fragment key={date.deleted_at}>
          <Typography className={classes.dv} variant="h6" color="textSecondary" gutterBottom>
            {new Date(date.deleted_at).toDateString()}
          </Typography>
          <Divider className={classes.dv1} variant="middle" />
          <Grid container item direction="row" spacing={2} className={classes.containerHeight}>
            {props.rawPasswords.passwords
              .filter(password => dayjs(password.deleted_at).format('YYYY-MM-DD')
                === dayjs(date.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD'))
              .filter(password => password.name.toLowerCase()
                .indexOf(props.searchValue.toLowerCase()) > -1)
              .map(password => (
                <Grid key={password.id} item xs={12} sm={6} md={4} lg={3}>
                  <PasswordCard
                    password={password}
                    onClick={props.onClick}
                    actions={props.actions}
                    performAction={props.performAction}
                  />
                </Grid>
              ))
            }
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}
