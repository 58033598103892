import React from 'react'

import { Grid, Paper } from '@mui/material'

export const StartPage = (props) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Grid item>
        <Paper style={{ maxWidth: 520, padding: '50px 60px' }} elevation={5}>
          {props.children}
        </Paper>
      </Grid>
    </Grid>
  )
}
