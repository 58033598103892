import React from 'react'

import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { PasswordCard } from '../PasswordCard'

const useStyles = makeStyles(
  {
    containerHeight: {
      maxHeight: '65vh',
      overflow: 'auto'
    }
  }
)

export const PasswordsGrid = (props) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.containerHeight}>
      {props.rawPasswords.length > 0 && props.rawPasswords.filter(password => password.name.toLowerCase()
        .indexOf(props.searchValue.toLowerCase()) > -1).map(password => (
          <Grid key={password.id} item xs={12} sm={6} md={4} lg={3}>
            <PasswordCard
              performAction={props.performAction}
              password={password}
              onClick={props.onClick}
              actions={props.actions}
            />
          </Grid>
        ))}
    </Grid>
  )
}
