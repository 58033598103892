import { axios } from './axiosConfig'

import * as types from '../types'

export const loadFilesList = () => {
  return dispatch => axios({ method: 'get', url: 'files/' })
    .then((response) => {
      dispatch({ type: types.FILES_FETCHED, data: response.data.files })
    })
}

export const uploadFile = (file, filename = null) => {
  return (dispatch) => {
    const formData = new FormData()
    formData.append('file', file, filename)

    return axios({
      method: 'post',
      url: 'files/upload/',
      timeout: 120000,
      data: formData,
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
      .then((response) => {
        dispatch({ type: types.FILE_UPLOADED, data: response.data.file })
        return response.data
      })
  }
}

export const getLink = (id) => {
  return () => axios({ method: 'get', url: `files/${id}/get_link` })
    .then(response => response.data)
}
