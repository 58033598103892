import React from 'react'

import { Topbar } from './topbar/Topbar'
import { Sidebar } from './sidebar/Sidebar'

export const Layout = (props) => {

  const expandMenu = () => {
    props.setExpanded(!props.expanded)
  }

  return (
    <div>
      <Topbar onDrawerClick={expandMenu} />
      <Sidebar
        expanded={props.expanded}
        handleDrawerClose={expandMenu}
      />
    </div>
  )
}
