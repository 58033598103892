import * as types from '../types'

const initialState = {
  user: null,
  globalMessage: { message: '', open: false }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SHOW_MESSAGE_ADMIN:
      return { ...state, globalMessage: { message: action.data, open: true } }
    case types.HIDE_MESSAGE_ADMIN:
      return { ...state, globalMessage: { message: '', open: false } }
    case types.USER_RESETED_ADMIN:
      return { ...state, user: null }
    default:
      return state
  }
}
