// app
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const APP_READY = 'APP_READY'
export const SWITCH_AUTH_ERROR = 'SWITCH_AUTH_ERROR'
export const SWITCH_REG_ERROR = 'SWITCH_REG_ERROR'
export const SET_LOADING = 'SET_LOADING'
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'
export const SET_SNACKBAR = 'SET_SNACKBAR'
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR'
export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS'
export const ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED'
export const ACCOUNT_NOT_ACTIVATED = 'ACCOUNT_NOT_ACTIVATED'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const LOCAL_SETTINGS_LOADED = 'LOCAL_SETTINGS_LOADED'
export const USER_RESETED = 'USER_RESETED'
export const IS_DOMAIN_PUBLIC = 'IS_DOMAIN_PUBLIC'
export const COMPANY_ACCOUNT_ACTIVATED = 'COMPANY_ACCOUNT_ACTIVATED'
export const COMPANY_ACCOUNT_NOT_ACTIVATED = 'COMPANY_ACCOUNT_NOT_ACTIVATED'
export const MASTER_PASSWORD_CHANGED = 'MASTER_PASSWORD_CHANGED'
export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const USER_PROJECTS_BY_ID = "USER_PROJECTS_BY_ID"
export const USER_GROUPS_BY_ID = "USER_GROUPS_BY_ID"
export const RESPONSE_INFO = 'RESPONSE_INFO'

// attachments
export const FILES_FETCHED = 'FILES_FETCHED'
export const FILE_UPLOADED = 'FILE_UPLOADED'

// groups
export const GROUP_FETCHED = 'GROUP_FETCHED'
export const GROUP_ADDED = 'GROUP_ADDED'
export const GROUP_UPDATED = 'GROUP_UPDATED'
export const GROUPS_FETCHED = 'GROUPS_FETCHED'
export const GROUP_DELETED = 'GROUP_DELETED'
export const GROUP_USERS_FETCHED = 'GROUP_USERS_FETCHED'
export const USER_ADDED_TO_GROUP = 'USER_ADDED_TO_PROJECT'
export const PROJECTS_OF_GROUP_FETCHED = 'PROJECTS_OF_GROUP_FETCHED'
export const GROUP_USER_DELETED = 'GROUP_USER_DELETED'
export const AVAILABLE_USERS_FETCHED_FOR_GROUP = 'AVAILABLE_USERS_FETCHED_FOR_GROUP'
export const USER_TO_GROUP_ADDED = 'USER_TO_GROUP_ADDED'
export const SET_USER_TO_GROUP_PENDING = 'SET_USER_TO_GROUP_PENDING'
export const GROUPS_FETCHED_WITH_PERMISSIONS = 'GROUPS_FETCHED_WITH_PERMISSIONS'

// passwords
export const PASSWORDS_FETCHED = 'PASSWORDS_FETCHED'
export const PASSWORD_FETCHED = 'PASSWORD_FETCHED'
export const PASSWORD_ADDED = 'PASSWORD_ADDED'
export const PASSWORD_DELETED = 'PASSWORD_DELETED'
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED'
export const CURRENT_PASSWORD_RESET = 'CURRENT_PASSWORD_RESET'
export const TAGS_FETCHED = 'TAGS_FETCHED'
export const HISTORY_FETCHED = 'HISTORY_FETCHED'
export const DELETED_PASSWORDS_LIST_FETCHED = 'DELETED_PASSWORDS_LIST_FETCHED'
export const PASSWORD_RESTORED = 'PASSWORD_RESTORED'
export const SET_BLOCK_COPYING = 'SET_BLOCK_COPYING'
export const PASSWORD_LOADING = 'PASSWORD_LOADING'
export const PASSWORDS_FETCHED_FOR_PROJECT_DETAILS = 'PASSWORDS_FETCHED_FOR_PROJECT_DETAILS'

// projects
export const PROJECT_FETCHED = 'PROJECT_FETCHED'
export const PROJECT_ADDED = 'PROJECT_ADDED'
export const PROJECT_UPDATED = 'PROJECT_UPDATED'
export const PROJECTS_FETCHED = 'PROJECTS_FETCHED'
export const PROJECT_DELETED = 'PROJECT_DELETED'
export const LOGS_FETCHED = 'LOGS_FETCHED'
export const WRITABLE_PROJECTS_FETCHED = 'WRITABLE_PROJECTS_FETCHED'
export const USERS_OF_PROJECT_FETCHED = 'USERS_OF_PROJECT_FETCHED'
export const USER_ADDED_TO_PROJECT = 'USER_ADDED_TO_PROJECT'
export const USER_DELETED_FROM_PROJECT = 'USER_DELETED_FROM_PROJECT'
export const AVAILABLE_USERS_FETCHED_FOR_PROJECT = 'AVAILABLE_USERS_FETCHED'
export const PROJECT_ADDED_TO_GROUP = 'PROJECT_ADDED_TO_GROUP'
export const GROUP_ADDED_TO_PROJECT = 'GROUP_ADDED_TO_PROJECT'
export const GROUPS_OF_PROJECT_FETCHED = 'GROUPS_OF_PROJECT_FETCHED'
export const GROUP_DELETED_FROM_PROJECT = 'GROUP_DELETED_FROM_PROJECT'
export const AVAILABLE_GROUPS_FETCHED_FOR_PROJECT = 'AVAILABLE_GROUPS_FETCHED_FOR_PROJECT'
export const STATE_IS_RESET = 'STATE_IS_RESET'
export const GET_PROJECTS_WITH_KEYS = 'GET_PROJECTS_WITH_KEYS'

// settings
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS'
export const SET_LDAP_SETTINGS = 'SET_LDAP_SETTINGS'

// users
export const USER_FETCHED = 'USER_FETCHED'
export const USER_UPDATED = 'USER_UPDATED'
export const USERS_FETCHED = 'USERS_FETCHED'
export const USER_GROUPS_FETCHED = 'USER_GROUPS_FETCHED'
export const USER_PROJECTS_FETCHED = 'USER_PROJECTS_FETCHED'
export const SET_IS_SUPERUSER = 'SET_IS_SUPERUSER'

// categories
export const CATEGORIES_LIST_FETCHED_ADMIN = 'CATEGORIES_LIST_FETCHED_ADMIN'
export const CATEGORY_ADDED_ADMIN = 'CATEGORY_ADDED_ADMIN'
export const CATEGORY_DELETED_ADMIN = 'CATEGORY_DELETED_ADMIN'
export const CATEGORY_CHANGED_ADMIN = 'CATEGORY_CHANGED_ADMIN'
export const CATEGORIES_LIST_FETCHED_ERROR = 'CATEGORIES_LIST_FETCHED_ERROR'
export const CATEGORY_CHANGED_ERROR = 'CATEGORY_CHANGED_ERROR'