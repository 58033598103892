import React from 'react'

import { Grid, Typography } from '@mui/material'

export const TwoColumnSettings = (props) => {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        {props.leftColumn}
      </Grid>
      <Grid item xs={12} md={9}>
        {props.rightColumn}
      </Grid>
    </Grid>
  )
}
