import React from 'react'

import { Table, Pagination } from 'semantic-ui-react'

export const DataTable = (props) => {
  const [column, setColumn] = React.useState(null)
  const [direction, setDirection] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(1)

  const handlePaginationChange = (e, { activePage }) => setCurrentPage(activePage)

  const handleSort = (clickedColumn) => {
    return () => {
      if (column !== clickedColumn) {
        setColumn(clickedColumn)
        setDirection('ascending')
      }
      else {
        setDirection(direction === 'ascending' ? 'descending' : 'ascending')
      }
    }
  }

  let showingData = props.data
  if (props.pagination && showingData)
    showingData = props.data.slice(
      (currentPage - 1) * props.rowsPerPage,
      currentPage * props.rowsPerPage
    )

  return (
    <Table compact celled selectable={!!props.onRowClick}>
      <Table.Header>
        <Table.Row>
          {props.header && props.header.map(item => (
            <Table.HeaderCell
              key={item.name}
              sorted={column === item.name ? direction : null}
              onClick={() => handleSort(item.name)}
              width={item.width}
              collapsing={item.collapsing}
              colSpan={item.colspan}
            >
              {item.label}
            </Table.HeaderCell>
          ))
          }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {showingData && showingData.map(rowData => (
          <Table.Row
            onClick={props.onRowClick ? (event => props.onRowClick(rowData, event)) : null}
            key={`row_${Math.random() * 1000}`}
          >
            {rowData.map((item, index) => (
              <Table.Cell
                styles={{ 'tableCell': { wordBreak: 'break-word' } }}
                singleLine={props.header[index].singleLine}
                key={`cell_${Math.random() * 1000}`}
              >
                {item}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
      {props.pagination && showingData && (
        <Table.Footer>
          <Table.Row textAlign="right">
            <Table.HeaderCell colSpan={props.columnsCount || props.header.length}>
              <Pagination
                onPageChange={() => handlePaginationChange}
                defaultActivePage={1}
                totalPages={Math.ceil(props.data.length / props.rowsPerPage)}
                siblingRange={1}
                boundaryRange={1}
                size="mini"
                firstItem={null}
                lastItem={null}
                pointing
                secondary
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  )
}
