/* eslint-disable array-element-newline */
import React from 'react'

import { Grid, Button, Header, Icon } from 'semantic-ui-react'

import { useDispatch, useSelector } from 'react-redux'
import { loadCategoriesList, createCategory, deleteCategory, editCategory } from 'Actions/categories'

import { Helmet } from 'react-helmet'

import { DataTable } from 'Components/admin/DataTable'
import { OneFieldDialog } from 'Components/admin/OneFieldDialog'

const categoriesHeaders = [
  { name: 'id', label: 'ID', collapsing: true },
  { name: 'name', label: 'Name' },
  { name: 'actions', label: 'Actions', collapsing: true }
]

export const AdminOther = (props) => {
  const dispatch = useDispatch()

  const { list, fetchError, editError } = useSelector((state) => state.categories)

  const [showCreateCategoryDialog, setShowCreateCategoryDialog] = React.useState(false)
  const [showEditCategoryDialog, setShowEditCategoryDialog] = React.useState(false)
  const [createDialogError, setCreateDialogError] = React.useState(null)
  const [editDialogError, setEditDialogError] = React.useState(null)
  const [workedCategory, setWorkedCategory] = React.useState(null)

  React.useEffect(() => {
    if (list.length === 0)
      dispatch(loadCategoriesList())
  }, [])

  const onCreateCategory = (data) => {
    dispatch(createCategory(data.name))
    setShowCreateCategoryDialog(false)
    setCreateDialogError(null)
    const key = Object.keys(data)[0]

    if (fetchError !== null)
      setCreateDialogError({
        name: fetchError?.status === 400 ? key : null,
        message: fetchError?.status === 400 ? data[key] : 'Server error'
      })
  }

  const onDeleteCategory = (id) => dispatch(deleteCategory(id))

  const onEditCategory = (category) => {
    dispatch(editCategory(category))
    setShowEditCategoryDialog(false)
    const key = Object.keys(category)[0]

    if (editError !== null)
      setEditDialogError({
        name: editError?.status === 400 ? key : null,
        message: editError?.status === 400 ? category[key] : 'Server error'
      })
  }

  const showCategoryDialog = (category, isShow) => {
    setShowEditCategoryDialog(isShow)
    setWorkedCategory(category)
  }

  const tableData = list.length !== 0 && list.map(item =>
    [item.id, item.name,
    (
      <div key={item.id} style={{ width: 66 }}>
        <Button size="mini" default icon onClick={() => showCategoryDialog(item, true)}>
          <Icon name="edit" />
        </Button>
        <Button size="mini" color="red" icon onClick={() => onDeleteCategory(item.id)}>
          <Icon name="delete" />
        </Button>
      </div>
    )])

  return (
    <React.Fragment>
      <Helmet title="Admin Other" />
      <OneFieldDialog
        error={createDialogError}
        open={showCreateCategoryDialog}
        onClose={() => setShowCreateCategoryDialog(false)}
        onSubmit={onCreateCategory}
        title="Create category"
        placeholder="Category name"
        name="name"
      />
      <OneFieldDialog
        error={editDialogError}
        open={showEditCategoryDialog}
        onClose={() => showCategoryDialog(null, false)}
        onSubmit={onEditCategory}
        initialValues={workedCategory}
        title="Edit category"
        placeholder="Category name"
        name="name"
      />
      <Grid container>
        <Grid.Row>
          <Header as="h1">Categories</Header>
        </Grid.Row>
        <Grid.Row>
          <Button onClick={() => setShowCreateCategoryDialog(true)} basic compact>Add category</Button>
        </Grid.Row>
        <Grid.Row>
          <DataTable header={categoriesHeaders} data={tableData} />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}
