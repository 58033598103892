import React from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography, Input } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment'
import IconButton from '@mui/material/IconButton/IconButton'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({ column: { flex: '33.33%' } })

export const HistoryItem = (props) => {
  const classes = useStyles()

  const [passwordVisibility, setPasswordVisibility] = React.useState(false)

  const togglePassword = () => setPasswordVisibility(!passwordVisibility)

  let changed = 'actual'
  if (props.password.changed) {
    const tempTime = new Date(props.password.changed)
    changed = tempTime.toLocaleString('en-US', { hour12: false })
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.column}>
          <Typography>{changed}</Typography>
        </div>
        <div className={classes.column}>
          <Typography>{props.password.changed_by_detail ? props.password.changed_by_detail.email : ''}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography variant="body1">
                  Login:
                  </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  {props.password.login}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body1">
                  Password:
                  </Typography>
              </td>
              <td>
                <Input
                  type={passwordVisibility ? 'text' : 'password'}
                  value={props.password.password}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={togglePassword}
                      >
                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body1">
                  URL:
                  </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  {props.password.url}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body1">
                  Description:
                  </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  {props.password.description}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  )
}
