import { authApi } from './api'
import constants from './constants'

export const updateLDAP = async (ldapData, ldapId, { props, setErrors, setSubmitting }) => {
  try {
    const result = await authApi.updateLDAP(ldapData, ldapId)
    setSubmitting(false)
    props.afterSubmit(result, constants.LDAP_SETTINGS_WERE_UPDATED)
  }
 catch (error) {
    const msg = error.response && error.response.statusText
    setErrors({ non_field_errors: msg })
    setSubmitting(false)
  }
}

export const createLDAP = async (ldapData, { props, setErrors, setSubmitting }) => {
  try {
    const result = await authApi.createLDAP(ldapData)
    setSubmitting(false)
    props.afterSubmit(result, constants.LDAP_DIRECTORY_WAS_CREATED)
  }
 catch (error) {
    const msg = error.response && error.response.statusText
    setErrors({ non_field_errors: msg })
    setSubmitting(false)
  }
}

export const deleteLDAP = async (ldapId, { props, setErrors, setSubmitting }) => {
  try {
    const result = await authApi.deleteLDAP(ldapId)
    setSubmitting(false)
    props.afterSubmit(result, constants.LDAP_DIRECTORY_WAS_DELETED)
  }
 catch (error) {
    const msg = error.response && error.response.statusText
    setErrors({ non_field_errors: msg })
    setSubmitting(false)
  }
}

export const isEmpty = obj => (
  obj && Object.keys(obj).length === 0 && obj.constructor === Object
)
