import React from 'react'

import { Grid, Button, Dimmer, Loader, Icon } from 'semantic-ui-react'

import { useDispatch, useSelector } from 'react-redux'
import { loadProjectList } from 'Actions/projects'
import { loadUserList, updateUser, inviteUser, deleteUser } from 'Actions/users'
import { getUserById } from 'Actions/app'

import { Helmet } from 'react-helmet'

import { DataTable } from 'Components/admin/DataTable'
import { InviteUserDialog } from './InviteUserDialog'
import { EditUserDialog } from './EditUserDialog'
import { UserDetailsDialog } from './UserDetailsDialog'

import decryptProjectSecretKeys from 'Utils/CryptoUtils'
import CryptoHelper from 'Utils/CryptoHelper'

const tableHeader = [
  {
    name: 'id',
    label: 'ID',
    collapsing: true,
    singleLine: true
  },
  { name: 'name', label: 'Full name' },
  { name: 'email', label: 'Email' },
  {
    name: 'actions',
    label: 'Actions',
    collapsing: true,
    singleLine: true
  }
]

export const AdminUsers = (props) => {
  const dispatch = useDispatch()

  const { userList } = useSelector((state) => state.users)
  const userById = useSelector((state) => state.app.userById)

  const [loading, setLoading] = React.useState(false)
  const [showUserEditDialog, setShowUserEditDialog] = React.useState(false)
  const [showInviteUserDialog, setShowInviteUserDialog] = React.useState(false)
  const [showUserDetailsDialog, setShowUserDetailsDialog] = React.useState(false)
  const [workedUser, setWorkedUser] = React.useState(null)

  let cryptoHelper = null

  const initCryptoHelper = async () => {
    await props.cryptoWorker.dispatch('GENERATE_RSA_KEY', props.hash)
    cryptoHelper = new CryptoHelper()
  }

  React.useEffect(async () => {
    if (userList.length === 0) {
      setLoading(true)
      await dispatch(loadUserList())
      setLoading(false)
    }
    props.cryptoWorker && await initCryptoHelper()
  }, [])

  const hideEditUserDialog = () => {
    setShowUserEditDialog(false)
    setWorkedUser(null)
  }

  const onEditUser = (user) => {
    dispatch(updateUser(user))
    hideEditUserDialog()
  }

  const onInviteUser = (data) => {
    dispatch(inviteUser(data))
    setShowInviteUserDialog(false)
  }

  const onDeleteUser = async (item, event) => {
    event.stopPropagation()
    setLoading(true)
    await dispatch(deleteUser(item.id))
    setLoading(false)
  }

  const showEditUserDialog = (user, event) => {
    event.stopPropagation()
    setShowUserEditDialog(true)
    setWorkedUser(user)
  }

  const showDetailsDialog = async (data) => {
    setLoading(true)
    await dispatch(getUserById(data[0]))
    setLoading(false)
    setShowUserDetailsDialog(true)
  }

  const data = userList.length !== 0 && userList.map(item => [
    item.id,
    `${item.first_name} ${item.last_name}`,
    item.email,
    (
      <React.Fragment key={item.id}>
        <Button size="mini" default icon onClick={event => showEditUserDialog(item, event)}>
          <Icon name="edit" />
        </Button>
        <Button size="mini" color="red" icon onClick={event => onDeleteUser(item, event)}>
          <Icon name="delete" />
        </Button>
      </React.Fragment>
    )
  ])

  return (
    <React.Fragment>
      <Helmet title="Admin Users" />
      <UserDetailsDialog
        open={showUserDetailsDialog}
        user={userById}
        onClose={() => setShowUserDetailsDialog(false)}
      />
      <InviteUserDialog
        open={showInviteUserDialog}
        onSubmit={onInviteUser}
        initialValues={userList.length !== 0 && userList[userList.length - 1].id + 1}
        onClose={() => setShowInviteUserDialog(false)}
      />
      <EditUserDialog
        open={showUserEditDialog}
        onSubmit={onEditUser}
        onClose={hideEditUserDialog}
        initialValues={workedUser}
        loadProjectsList={loadProjectList}
        decryptProjectSecretKeys={async data => (
          decryptProjectSecretKeys(data, props.cryptoWorker, cryptoHelper)
        )}
      />
      <Grid container>
        <Grid.Row columns={1}>
          <Button onClick={() => setShowInviteUserDialog(true)} compact>Invite user</Button>
        </Grid.Row>
        <Grid.Row>
          {loading && (
            <Dimmer active inverted>
              <Loader inverted>{"loading"}</Loader>
            </Dimmer>
          )}
          <DataTable onRowClick={showDetailsDialog} header={tableHeader} data={data} />
        </Grid.Row>
      </Grid>
    </React.Fragment>
  )
}
