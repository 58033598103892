import { setLoading, setSnackbar, logout } from './app'
import { axios } from './axiosConfig'

import * as types from '../types'

export const setIsSuperuser = (isSuperuser, id) => {
  return async (dispatch) => {
    const data = { is_superuser: isSuperuser }
    await axios({ method: 'post', url: `users/${id}/make_superuser/`, data })
      .then(dispatch({ type: types.SET_IS_SUPERUSER, data: isSuperuser }))
  }
}

export const updateUser = (user) => {
  return async (dispatch, setState) => {
    const { users } = setState()
    const data = { ...user, id: users.currentUser.id }
    dispatch(setLoading(true))
    const response = await axios({ method: 'put', url: `users/${data.id}/update_user/`, data })
    dispatch(setLoading(false))
    dispatch({ type: types.USER_UPDATED, data: response.data.user })
    dispatch({ type: types.UPDATE_ACCOUNT_INFO_SUCCESS, data: response.data.user })
    if (!response.data.user.is_confirm)
      dispatch(setSnackbar('User was updated. Check your email. We have sent an email to your address.'))
    else
      dispatch(setSnackbar('User was updated.'))
  }
}

export const deleteUser = () => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'delete', url: 'users/delete_self/' })
      .then(() => {
        dispatch(setLoading(false))
        dispatch(logout())
      })
  }
}

export const loadUserList = (params) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'users/simplelist/', params })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.USERS_FETCHED, data: response.data.users })
      })
  }
}

export const loadUserProjects = (userId) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `users/${userId}/projects` })
      .then((response) => {
        dispatch({ type: types.USER_PROJECTS_FETCHED, data: response.data.projects })
        dispatch(setLoading(false))
      })
  }
}

export const inviteUser = (params) => {
  const data = { ...params }
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'put', url: '/users/invite_user/', data })
      .then(() => {
        dispatch(setLoading(false))
        dispatch(setSnackbar('Invitation sent!'))
      })
  }
}
