import { push } from 'connected-react-router'
import { setLoading, setSnackbar } from './app'
import { axios } from './axiosConfig'

import * as types from '../types'

export const updateGroup = (group) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'post', url: `groups/${group.id}/update_group/`, data: group })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.GROUP_UPDATED, data: response.data.group })
        dispatch(setSnackbar('Group was updated'))
      })
  }
}


export const addGroup = (group) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({
      method: group.id ? 'put' : 'post',
      url: group.id ? `groups/${group.id}/` : '/groups/',
      data: group
    })
      .then((response) => {
        if (group.id)
          dispatch({ type: types.GROUP_UPDATED, data: response.data.group })
        else
          dispatch({ type: types.GROUP_ADDED, data: response.data.group })
        dispatch(setLoading(false))
        dispatch(setSnackbar(group.id ? 'Group was updated' : 'Group was added'))
      })
  }
}

export const setPending = (flag) => {
  return (dispatch) => {
    if (flag)
      dispatch(setSnackbar('User will be added. This operation may take some amount of time. '
        + 'Please do not close or reload the page.'))
    else
      dispatch(setSnackbar('User added.'))

    dispatch({ type: types.SET_USER_TO_GROUP_PENDING, data: flag })
  }
}

export const addUserToGroup = (id, encryptedSecretKey) => {
  return (dispatch, setState) => {
    const { groups } = setState()
    return axios({
      method: 'put',
      url: `/groups/${groups.currentGroup.id}/add_user/`,
      data: { id, encrypted_secret_key: encryptedSecretKey }
    })
      .then((response) => {
        dispatch({ type: types.USER_ADDED_TO_GROUP, data: response.data.user })
      })
  }
}

export const addSuperuserToGroup = (id, goupId, encryptedSecretKey) => {
  return (dispatch) => {
    return axios({
      method: 'put',
      url: `/groups/${goupId}/add_user/`,
      data: { id, encrypted_secret_key: encryptedSecretKey }
    })
      .then((response) => {
        response.data && dispatch({ type: types.USER_ADDED_TO_GROUP, data: response.data.user })
      })
  }
}

export const deleteGroup = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'delete', url: `groups/${id}` })
      .then(() => {
        dispatch(setLoading(false))
        dispatch(push('/groups'))
        dispatch({ type: types.GROUP_DELETED, data: { id } })
      })
  }
}

export const loadGroupList = (params) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: 'groups', params })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.GROUPS_FETCHED, data: response.data.groups })
      })
  }
}

export const loadGroup = (groupId, redirect) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const response = await axios({ method: 'get', url: `groups/${groupId}/` })
    const responseWithPermissions = await axios({ method: 'get', url: `/groups/${groupId}/users/` })
    dispatch(setLoading(false))
    dispatch({ type: types.GROUP_FETCHED, groups: response.data, permissions: responseWithPermissions.data.users })
    redirect && dispatch(push(`/groups/${response.data.group.id}/`))
  }
}

export const loadGroupUsers = (groupId) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `/groups/${groupId}/users` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.GROUP_USERS_FETCHED, data: response.data.users })
      })
  }
}

export const loadGroupProjects = (groupId) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `/groups/${groupId}/projects` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.PROJECTS_OF_GROUP_FETCHED, data: response.data.projects })
        return response.data.projects
      })
  }
}

export const changeUserPermissionsGroups = (permission, idUser) => {
  return (dispatch, getState) => {
    const { groups } = getState()
    return axios({
      method: 'put',
      url: `/groups/${groups.currentGroup.id}/set_user_permissions/`,
      data: { permission, idUser }
    })
      .then((response) => {
        dispatch({ type: types.GROUP_USER_DELETED, data: { id: idUser } })
        dispatch({ type: types.USER_ADDED_TO_GROUP, data: response.data.user })
      })
  }
}

export const loadAvailableUsersForGroup = (idGroup) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return axios({ method: 'get', url: `groups/${idGroup}/available_users/` })
      .then((response) => {
        dispatch(setLoading(false))
        dispatch({ type: types.AVAILABLE_USERS_FETCHED_FOR_GROUP, data: response.data.users })
      })
  }
}

export const deleteUser = (user) => {
  return (dispatch, getState) => {
    const { groups } = getState()
    return axios({
      method: 'delete',
      url: `/groups/${groups.currentGroup.id}/delete_user/`,
      data: { id: user.id }
    })
      .then((response) => {
        dispatch({ type: types.GROUP_USER_DELETED, data: user })
      })
  }
}
